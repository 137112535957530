import axios, { AxiosError, AxiosResponse } from "axios";
import { endSession } from "modules/userSession/actions";
import { Store } from "redux";
import Auth from "./auth/Auth";

// list of urls that do not require access token
// const PUBLIC_API_URLS = [
// 	"",
// 	"/jwt/refresh",
// 	"/jwt/websocket",
// 	"/config/env-public/",
// 	"/version",
// 	"/gui-version",
// 	"/query" // influxdb query
// ];
//
// let tokenRefreshInProgress = false;
// let tokenRefreshPromise: Promise<{}>;
// let history: History;

export const initializeInterceptors = (store: Store): void => {
	// history = h;
	// request interceptors
	// IMPORTANT: the interception order is the opposite of initialization order
	// (the last initialized interceptor will be the first one to handle the request)
	// initializeAuthenticationHeaderHandler();
	// initializeClientSideAccessTokenVerification(store);

	// response interceptors
	initialize401ResponseHandler(store);
};

// const initializeAuthenticationHeaderHandler = (): void => {
// 	axios.interceptors.request.use(
// 		async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
// 			if (config.url && isAccessTokenRequired(config.url)) {
// 				config.headers["Authorization"] = `Bearer ${Auth.getHttpAccessToken()}`;
// 			}
// 			return config;
// 		}
// 	);
// };

// const initializeClientSideAccessTokenVerification = (store: Store) => {
// 	axios.interceptors.request.use(
// 		async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
// 			if (config.url && isAccessTokenRequired(config.url)) {
// 				const userSession = store.getState().userSession as UserSessionState;
//
// 				if (!userSession.isActive) {
// 					handleUnauthorized(store);
// 					throw Error("User session is not active.");
// 				}
//
// 				// if access token is expired check refresh token
// 				if (
// 					userSession.session &&
// 					userSession.session.accessExpirationTimestamp < moment().unix()
// 				) {
// 					// if refresh token expired raise error
// 					if (
// 						userSession.session &&
// 						userSession.session.refreshExpirationTimestamp < moment().unix()
// 					) {
// 						handleUnauthorized(store);
// 						throw Error("User session expired.");
// 					} else {
// 						// if refresh token not expired, refresh token
// 						if (!tokenRefreshInProgress) {
// 							tokenRefreshPromise = refreshToken(store);
// 						}
//
// 						// if token refresh is in progress, pause the original requests until the refresh is done
// 						if (tokenRefreshInProgress) {
// 							await tokenRefreshPromise;
// 						}
// 					}
// 				}
// 			}
//
// 			return config;
// 		}
// 	);
// };

const initialize401ResponseHandler = (store: Store) => {
	axios.interceptors.response.use(
		(response: AxiosResponse): AxiosResponse => response,
		async (error: AxiosError): Promise<any> => {
			// console.error("error response", error, error.response);
			if (
				error.response &&
				error.response.status === 401 &&
				!error.config.url?.includes("/login")
				// isAccessTokenRequired(error.config.url)
			) {
				handleUnauthorized(store);
				throw error;
				// const userSession = store.getState().userSession as UserSessionState;
				//
				// console.log("store", store.getState());
				// console.log("userSession", userSession);
				//
				// if (
				// 	userSession &&
				// 	userSession.session &&
				// 	userSession.session.refreshExpirationTimestamp < moment().unix()
				// ) {
				// 	handleUnauthorized(store);
				// 	throw Error("User session expired.");
				// } else {
				// 	// if refresh token is not expired, refresh access token
				// 	if (!tokenRefreshInProgress) {
				// 		tokenRefreshPromise = refreshToken(store);
				// 	}
				//
				// 	// if token refresh is in progress, retry failed request after the refresh is done
				// 	if (tokenRefreshInProgress) {
				// 		await tokenRefreshPromise;
				// 		// retry failed request
				// 		return axios(error.config);
				// 	}
				// }
				// throw error;
			} else {
				throw error;
			}
		}
	);
};

// const isAccessTokenRequired = (url: string): boolean => {
// 	try {
// 		const { pathname, origin } = new URL(url);
// 		// if target url is api address and it's not public url
// 		return (
// 			origin === AppEnvironment.getApiAddress() &&
// 			!PUBLIC_API_URLS.some((publicUrl: string): boolean =>
// 				pathname.includes(publicUrl)
// 			)
// 		);
// 	} catch (e) {
// 		// console.warn(
// 		// 	"Interceptor is unable to determine if path should be protected or not.",
// 		// 	url,
// 		// 	e
// 		// );
// 		return false;
// 	}
// };

const handleUnauthorized = (store: Store) => {
	store.dispatch(endSession());
	Auth.clearJWTTokens();
	window.location.href = `${process.env.REACT_APP_BASE_HREF}/login`;
};

// const refreshToken = async (store: Store): Promise<any> => {
// 	console.log("Refreshing token...");
// 	tokenRefreshInProgress = true;
// 	let newAccessTokenData;
// 	try {
// 		// console.log("Trying to fetch new access token...");
// 		newAccessTokenData = await Auth.refreshHttpAccessToken();
// 		// console.log("New access token fetched!");
// 	} catch (e) {
// 		handleUnauthorized(store);
// 		throw e;
// 	}
//
// 	// console.log("Dispatching session extend action...");
// 	store.dispatch(extendSessionDuration(newAccessTokenData.exp));
// 	console.log("Token refreshed!");
// 	tokenRefreshInProgress = false;
// };
