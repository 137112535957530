import {
	Cluster,
	ClusterAction,
	CLUSTER_ACTION
} from "components/management/cluster/types";

const initialState: Cluster[] = [];

export function clusterListReducer(
	state: Cluster[] = initialState,
	action: ClusterAction
): Cluster[] {
	switch (action.type) {
		case CLUSTER_ACTION.LIST_FETCH_SUCCEDED:
			console.log("reducer action", action);
			return action.payload.clusterList;

		// case CLUSTER_ACTION.CREATE_SUCCEEDED:
		// 	return [...state, action.payload.cluster];

		case CLUSTER_ACTION.DELETE_SUCCEDED:
			return state.filter(
				(cluster: Cluster) => cluster.name !== action.payload.clusterName
			);

		default:
			return state;
	}
}
