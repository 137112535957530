import React from "react";
import { AppState } from "AppState";
import { connect } from "react-redux";
import { hideSnackbar } from "components/sharedComponents/snackbar/actionCreators";
import { Snackbar } from "@material-ui/core";
import { SnackbarState } from "./types";

// PROPS
interface StateProps {
	snackbar: SnackbarState;
}

interface DispatchProps {
	hideSnackbar: () => void;
}

interface OwnProps {}

type ComponentProps = OwnProps & StateProps & DispatchProps;

class GMSnackbar extends React.Component<ComponentProps> {
	render() {
		return (
			<Snackbar
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left"
				}}
				ClickAwayListenerProps={{ mouseEvent: false }}
				open={this.props.snackbar.open}
				autoHideDuration={6000}
				onClose={this.props.hideSnackbar}
				ContentProps={{
					"aria-describedby": "message-id"
				}}
				message={<span id="message-id">{this.props.snackbar.msg}</span>}
			/>
		);
	}
}

const mapStateToProps = (state: AppState) => {
	return {
		snackbar: state.snackbar
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		hideSnackbar: () => {
			dispatch(hideSnackbar());
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GMSnackbar);
