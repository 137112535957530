import {
	Node,
	NodeAction,
	NODE_ACTION
} from "components/management/node/types";

const initialState: Map<number, Node[]> = new Map<number, Node[]>();

export function nodeListReducer(
	state: Map<number, Node[]> = initialState,
	action: NodeAction
): Map<number, Node[]> {
	switch (action.type) {
		case NODE_ACTION.LIST_FETCH_SUCCEEDED:
			console.log("listFetchSucceeded", action.payload);
			const { clusterID, nodeList } = action.payload;
			return new Map<number, Node[]>(state).set(clusterID, nodeList);
		default:
			return state;
	}
}
