export const LOG_FETCH_PAGE_SIZE = 500;
export const INFLUX_DATABASE_NAME = "gmd";
export const DEFAULT_COLORS = [
	// plotly default color list
	"#1f77b4", // muted blue
	"#ff7f0e", // safety orange
	"#2ca02c", // cooked asparagus green
	"#d62728", // brick red
	"#9467bd", // muted purple
	"#8c564b", // chestnut brown
	"#e377c2", // raspberry yogurt pink
	"#7f7f7f", // middle gray
	"#bcbd22", // curry yellow-green
	"#17becf" // blue-teal
];
