import { Job, JOB_STATUS } from "modules/jobs/types";
import { appStore } from "../../index";
import { AppState } from "../../AppState";
import moment from "moment";

const GRACE_PERIOD = 20000; // ms to wait before reporting missing jobs

class JobsService {
	public static getJob(jobId: number): Job | undefined {
		const state = appStore.getState() as AppState;

		const job = state.jobList.find((job: Job) => job.id === jobId);

		if (job) {
			return job;
		} else {
			return undefined;
		}
	}

	public static async monitorJob(jobId: number): Promise<Job> {
		const monitorStartTimestamp = moment();

		// create new promise and return it
		return new Promise<Job>((resolve, reject) => {
			const job = this.getJob(jobId);
			// console.log("monitoring job", jobId, job);

			processJobStatus(job);

			const unsubscribe = appStore.subscribe(() => {
				const job = this.getJob(jobId);
				// console.log("checking job status", job);
				processJobStatus(job);
			});

			function processJobStatus(job: Job | undefined) {
				// console.log("processJobStatus", job?.executionInfo.status);

				if (
					!job &&
					moment().subtract(GRACE_PERIOD, "ms").isBefore(monitorStartTimestamp)
				) {
					// console.info(
					// 	`Couldn't find job ${jobId}, but it's still too early to stop monitoring...`
					// );
				} else if (!job) {
					console.error(`Could not find job ${jobId}!`);
					reject({
						id: -1,
						executionInfo: {
							status: JOB_STATUS.MISSING
						}
					});
				} else {
					if (job.executionInfo.status === JOB_STATUS.SUCCESS) {
						unsubscribe && unsubscribe();
						resolve(job);
					} else if (
						job.executionInfo.status === JOB_STATUS.ABORTED ||
						job.executionInfo.status === JOB_STATUS.FAILURE ||
						job.executionInfo.status === JOB_STATUS.MISSING ||
						job.executionInfo.status === JOB_STATUS.NONE
					) {
						console.warn(
							`Job ${jobId} failed with status ${job.executionInfo.status}`
						);
						unsubscribe();
						reject(job);
					}
				}
			}
		});
	}
}

export default JobsService;
