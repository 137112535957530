import {
	FormControl,
	IconButton,
	MenuItem,
	Select,
	Tooltip,
	withStyles,
	WithStyles
} from "@material-ui/core";
import { styles } from "./styles";
import * as React from "react";
import { Close, Grain } from "@material-ui/icons";
import { ResolutionInput } from "components/monitoring/dashboard/ChartActions/ResolutionInput";
import { DASHBOARD_RESOLUTION_SELECT_OPTIONS } from "components/monitoring/dashboard/const";

interface LocalState {
	isResolutionEnabled: boolean;
}

interface LocalProps {
	resolution: string;
	onResolutionChange: (newResolution: string) => void;
	onRemoveClick?: () => void;
}

type Props = LocalProps & WithStyles<typeof styles>;

class ChartActionsComponent extends React.Component<Props, LocalState> {
	constructor(props: Props) {
		super(props);

		this.state = {
			isResolutionEnabled: false // used in the time of Prometheus, but not supported anymore
		};
	}

	render():
		| React.ReactElement<any, string | React.JSXElementConstructor<any>>
		| string
		| number
		| {}
		| React.ReactNodeArray
		| React.ReactPortal
		| boolean
		| null
		| undefined {
		const { isResolutionEnabled } = this.state;

		const {
			resolution,
			onResolutionChange,
			classes,
			onRemoveClick
		} = this.props;

		return (
			<div className={classes.root}>
				{isResolutionEnabled && (
					<Tooltip
						enterDelay={500}
						placement={"left"}
						title={"Chart resolution"}
					>
						<FormControl className={classes.formControl}>
							<Select
								input={<ResolutionInput />}
								IconComponent={Grain}
								value={resolution}
								onChange={(event) => {
									const value = event.target.value as string;
									onResolutionChange && onResolutionChange(value);
								}}
							>
								{DASHBOARD_RESOLUTION_SELECT_OPTIONS.map((option: string) => (
									<MenuItem
										key={option}
										className={classes.selectOption}
										value={option}
									>
										{option}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Tooltip>
				)}
				<Tooltip enterDelay={500} placement={"top"} title={"Remove chart"}>
					<IconButton
						size="small"
						onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
							console.log("onRemoveClick");
							onRemoveClick && onRemoveClick();
						}}
					>
						<Close style={{ fontSize: "1em" }} />
					</IconButton>
				</Tooltip>
			</div>
		);
	}
}

export default withStyles(styles, { withTheme: true })(ChartActionsComponent);
