export enum JOB_STATUS {
	NONE = -2,
	MISSING = -1,
	NEW = "new",
	RUNNING = "running",
	SUCCESS = "success",
	FAILURE = "failure",
	ABORTED = "aborted"
}

export interface Job {
	id: number;
	description: string;
	parentId: number;
	executionInfo: {
		status: JOB_STATUS;
		details: string;
	};
	createdAt: string;
	stoppedAt: string;
	meta: {
		cluster_id?: number;
		host_id?: number;
		node_id?: number;
	};
}

export interface SerializedJob {
	attributes: Job;
	type: "jobs";
	id?: string;
}

export enum JOB_ACTION {
	CREATED = "JOB/CREATED",
	LIST_FETCH_REQUESTED = "JOB/LIST_FETCH_REQUESTED",
	LIST_FETCH_SUCCEEDED = "JOB/LIST_FETCH_SUCCEEDED",
	FETCH_REQUESTED = "JOB/FETCH_REQUESTED",
	FETCH_SUCCEEDED = "JOB/FETCH_SUCCEEDED"
}

export interface JobCreatedAction {
	type: JOB_ACTION.CREATED;
	payload: {
		job: Job;
	};
}

export interface JobListFetchRequestedAction {
	type: JOB_ACTION.LIST_FETCH_REQUESTED;
}

export interface JobListFetchSucceededAction {
	type: JOB_ACTION.LIST_FETCH_SUCCEEDED;
	payload: {
		jobList: Job[];
	};
}

export interface JobFetchRequestedAction {
	type: JOB_ACTION.FETCH_REQUESTED;
	payload: {
		jobId: number;
	};
}

export interface JobFetchSucceededAction {
	type: JOB_ACTION.FETCH_SUCCEEDED;
	payload: {
		job: Job;
	};
}

export type JobAction =
	| JobCreatedAction
	| JobListFetchRequestedAction
	| JobListFetchSucceededAction
	| JobFetchRequestedAction
	| JobFetchSucceededAction;
