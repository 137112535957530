import Axios, { AxiosResponse } from "axios";
import AppEnvironment from "modules/appEnvironment";
import { deattribute } from "kitsu-core";

const PATH = "supportMatrixItems";

class SupportMatrixApi {
	static async fetchSupportMatrix(): Promise<AxiosResponse> {
		try {
			const response = await Axios.get(
				`${AppEnvironment.getApiAddress()}/${AppEnvironment.getApiVersion()}/${PATH}`
			);

			const deattributedData = response.data.data.map((item: any) =>
				deattribute(item)
			);

			let supportMatrix: any = {};
			deattributedData.forEach((item: any) => {
				const system = item.id;

				// fill system matrix
				supportMatrix[system] = item.dbEngines;

				// fill dbEngine matrix
				item.dbEngines.forEach((dbEngine: string) => {
					supportMatrix[dbEngine]
						? supportMatrix[dbEngine].push(system)
						: (supportMatrix[dbEngine] = [system]);
				});
			});

			console.log("supportMatrix", supportMatrix);
			Object.keys(supportMatrix).forEach((key: string) => {
				const list = supportMatrix[key];
				list.sort((a: string, b: string) => a.localeCompare(b));
			});
			return supportMatrix;
		} catch (e) {
			throw e;
		}
	}
}

export default SupportMatrixApi;
