import * as React from "react";
import GridLayout, { Layout } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import {
	FormControl,
	Grid,
	IconButton,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Select,
	Tooltip,
	WithStyles,
	withStyles,
	WithTheme
} from "@material-ui/core";
import elementResizeDetectorMaker from "element-resize-detector";
import AddElement from "components/monitoring/dashboard/AddElement/AddElement";
import { v4 as uuid } from "uuid";

import "./styles.css";
import { Cluster } from "components/management/cluster/types";
import { Node } from "components/management/node/types";
import { styles } from "./styles";
import { Chart, Dashboard } from "components/monitoring/dashboard/types";
import ChartActionsComponent from "components/monitoring/dashboard/ChartActions/ChartActionsComponent";
import Utils from "components/monitoring/dashboard/utils";
import {
	CalendarClock,
	CameraTimer,
	ChartLine,
	Download
} from "mdi-material-ui";
import { MoreVert } from "@material-ui/icons";
import Menu from "@material-ui/core/Menu";
import {
	DASHBOARD_PERIOD_SELECT_OPTIONS,
	DASHBOARD_REFRESH_RATE_SELECT_OPTIONS
} from "components/monitoring/dashboard/const";
import TimeSeriesChartComponent from "components/monitoring/charts/timeSeries/TimeSeriesChartComponent";

interface LocalState {
	dashboard: Dashboard;
	dragInProgress: boolean;
	isAddElementDialogOpen: boolean;
	width: number;
	menuTarget?: any;
}

interface LocalProps {
	dashId: string;
	dashboard: Dashboard;
	onConfigChanged?: (dashboard: Dashboard) => void;
	cluster: Cluster;
	node?: Node;
}

type Props = LocalProps & WithTheme & WithStyles<typeof styles>;

class DashboardComponent extends React.Component<Props, LocalState> {
	_isMounted: boolean = false;

	constructor(props: Props) {
		super(props);

		this.state = {
			dashboard: props.dashboard,
			width: 1000,
			dragInProgress: false,
			isAddElementDialogOpen: false
		};
	}

	static getDerivedStateFromProps(nextProps: LocalProps) {
		return {
			dashboard: nextProps.dashboard
		};
	}

	componentDidMount(): void {
		this._isMounted = true;

		const erd = elementResizeDetectorMaker({
			strategy: "scroll" //<- For ultra performance.
		});

		const el = document.getElementById("MonitoringPageContainer");

		if (el) {
			// listener for handling dashboard width change
			erd.listenTo(el, (element: HTMLElement) => {
				const width = element.offsetWidth;
				if (this.state.width !== width) {
					// console.log("Size:" + width);
					this.setState(
						(state: LocalState): LocalState => ({
							...state,
							width
						})
					);
				}
			});
		}
	}

	componentWillUnmount(): void {
		this._isMounted = false;
	}

	onTileSizeChange(tileSize: string) {
		this.setState((state: LocalState) => ({
			dashboard: {
				...state.dashboard,
				config: {
					...state.dashboard.config,
					tileSize
				}
			}
		}));

		this.props.onConfigChanged &&
			this.props.onConfigChanged({
				...this.props.dashboard,
				config: {
					...this.props.dashboard.config,
					tileSize
				}
			});
	}

	onRefreshIntervalChange(refreshInterval: number) {
		console.log("onRefreshIntervalChange", refreshInterval);
		this.setState((state: LocalState) => ({
			dashboard: {
				...state.dashboard,
				config: {
					...state.dashboard.config,
					refreshInterval
				}
			}
		}));

		this.props.onConfigChanged &&
			this.props.onConfigChanged({
				...this.state.dashboard,
				config: {
					...this.state.dashboard.config,
					refreshInterval
				}
			});
	}

	onPeriodChange(period: string) {
		console.log("onPeriodChange", period);
		this.setState((state: LocalState) => ({
			dashboard: {
				...state.dashboard,
				config: {
					...state.dashboard.config,
					period
				}
			}
		}));

		this.props.onConfigChanged &&
			this.props.onConfigChanged({
				...this.state.dashboard,
				config: {
					...this.state.dashboard.config,
					period
				}
			});
	}

	onResolutionChange(newResolution: string, chart: Chart) {
		console.log("onResolutionChange", newResolution);

		const updatedChart = { ...chart, resolution: newResolution };

		const charts = this.state.dashboard.config.charts.map((chart: Chart) => {
			if (chart.id === updatedChart.id) return updatedChart;
			else return chart;
		});

		this.setState((state: LocalState) => ({
			dashboard: {
				...state.dashboard,
				config: {
					...state.dashboard.config,
					charts
				}
			}
		}));

		this.props.onConfigChanged &&
			this.props.onConfigChanged({
				...this.state.dashboard,
				config: {
					...this.state.dashboard.config,
					charts
				}
			});
	}

	render() {
		const {
			dragInProgress,
			isAddElementDialogOpen,
			dashboard: { config },
			width,
			menuTarget
		} = this.state;

		const { classes, cluster, node } = this.props;

		const { columns, rowHeight } = Utils.calculateSize(config.tileSize);

		const menu = (
			<>
				<IconButton
					onClick={(event: any) => {
						this.setState({ menuTarget: event.target });
					}}
				>
					<MoreVert />
				</IconButton>
				<Menu
					id="long-menu"
					anchorEl={menuTarget}
					open={Boolean(menuTarget)}
					onClose={() => {
						this.setState({ menuTarget: null });
					}}
				>
					<MenuItem
						onClick={() => {
							this.setState({
								isAddElementDialogOpen: true,
								menuTarget: null
							});
						}}
					>
						<ListItemIcon>
							<ChartLine />
						</ListItemIcon>
						<ListItemText color={"primary"} primary="Add chart" />
					</MenuItem>
					<MenuItem
						onClick={() => {
							const file = new Blob([JSON.stringify(this.state.dashboard)], {
								type: "application/text"
							});
							let a = document.createElement("a");
							a.href = URL.createObjectURL(file);
							a.download = `${this.state.dashboard.name}.json`;
							a.click();

							a.remove();

							this.setState({
								menuTarget: null
							});
						}}
						color={"secondary"}
					>
						<ListItemIcon>
							<Download />
						</ListItemIcon>
						<ListItemText primary="Download dashboard configuration" />
					</MenuItem>
				</Menu>
			</>
		);

		return (
			<>
				<AddElement
					cluster={cluster}
					node={node}
					isOpen={isAddElementDialogOpen}
					onCancelClick={() => {
						this.setState(
							(state: LocalState): LocalState => ({
								...state,
								isAddElementDialogOpen: false
							})
						);
					}}
					onAddClick={(chart: Chart) => {
						console.log("onAddClick", chart);

						const newChart: Chart = {
							...chart,
							title: chart.title || chart.metric.name,
							id: uuid(),
							position: config.charts.length,
							resolution: "5s"
						};

						console.log("newChart", newChart);

						const charts = [...config.charts, newChart];

						this.setState((state: LocalState) => ({
							isAddElementDialogOpen: false,
							dashboard: {
								...state.dashboard,
								config: {
									...state.dashboard.config,
									charts
								}
							}
						}));

						this.props.onConfigChanged &&
							this.props.onConfigChanged({
								...this.state.dashboard,
								config: {
									...this.state.dashboard.config,
									charts
								}
							});
					}}
				/>
				<Grid
					id="MonitoringPageContainer"
					container
					direction="column"
					component="div"
				>
					<Grid
						component="div"
						item
						container
						direction="row"
						className={classes.toolbar}
					>
						<Grid item sm>
							{/*<Typography>*/}
							{/*	This is a toolbar with some really important information*/}
							{/*</Typography>*/}
						</Grid>
						<Grid item>
							<form>
								<Tooltip enterDelay={500} placement={"left"} title={"Period"}>
									<FormControl className={classes.formControl}>
										{/*<InputLabel>Period</InputLabel>*/}
										<Select
											classes={{
												selectMenu: classes.selectMenu
											}}
											IconComponent={CalendarClock}
											disableUnderline={true}
											value={config.period}
											onChange={(event) => {
												const value = event.target.value as string;
												this.onPeriodChange(value);
											}}
										>
											{DASHBOARD_PERIOD_SELECT_OPTIONS.map((option: string) => (
												<MenuItem
													key={option}
													className={classes.selectOption}
													value={option}
												>
													{`Last ${option}`}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Tooltip>
								<Tooltip
									enterDelay={500}
									placement={"left"}
									title={"Refresh interval"}
								>
									<FormControl className={classes.formControl}>
										{/*<InputLabel>Refresh rate</InputLabel>*/}
										<Select
											classes={{
												selectMenu: classes.selectMenu
											}}
											IconComponent={CameraTimer}
											disableUnderline={true}
											value={config.refreshInterval}
											onChange={(event) => {
												const value = event.target.value as number;
												this.onRefreshIntervalChange(value);
											}}
										>
											{DASHBOARD_REFRESH_RATE_SELECT_OPTIONS.map(
												(option: any) => (
													<MenuItem
														key={option.value}
														className={classes.selectOption}
														value={option.value}
													>
														{`Every ${option.label}`}
													</MenuItem>
												)
											)}
										</Select>
									</FormControl>
								</Tooltip>
								<Tooltip
									enterDelay={500}
									placement={"left"}
									title={"Chart size"}
								>
									<FormControl className={classes.formControl}>
										{/*<InputLabel>Chart size</InputLabel>*/}
										<Select
											classes={{
												selectMenu: classes.selectMenu
											}}
											IconComponent={ChartLine}
											disableUnderline={true}
											value={config.tileSize}
											onChange={(event) => {
												const value = event.target.value as string;
												this.onTileSizeChange(value);
											}}
										>
											<MenuItem className={classes.selectOption} value={"sm"}>
												Small
											</MenuItem>
											<MenuItem className={classes.selectOption} value={"md"}>
												Medium
											</MenuItem>
											<MenuItem className={classes.selectOption} value={"lg"}>
												Large
											</MenuItem>
										</Select>
									</FormControl>
								</Tooltip>
							</form>
						</Grid>
						<Grid item>
							{menu}
							{/*<Button*/}
							{/*	onClick={() => {*/}
							{/*		this.setState(*/}
							{/*			(state: LocalState): LocalState => ({*/}
							{/*				...state,*/}
							{/*				isAddElementDialogOpen: true*/}
							{/*			})*/}
							{/*		);*/}
							{/*	}}*/}
							{/*>*/}
							{/*	Add*/}
							{/*</Button>*/}
							{/*<Button*/}
							{/*	onClick={() => {*/}
							{/*		console.log("getDashboardConfig", this.state.dashboard);*/}
							{/*		const file = new Blob([JSON.stringify(this.state.dashboard)], {*/}
							{/*			type: "application/text"*/}
							{/*		});*/}
							{/*		let a = document.createElement("a");*/}
							{/*		a.href = URL.createObjectURL(file);*/}
							{/*		a.download = `${this.state.dashboard.name}.json`;*/}
							{/*		a.click();*/}
							{/*	}}*/}
							{/*>*/}
							{/*	Download config*/}
							{/*</Button>*/}
						</Grid>
					</Grid>
					<Grid component="div" item>
						<GridLayout
							className={this._isMounted ? "animated" : ""}
							verticalCompact={true}
							compactType="vertical"
							isResizable={false}
							// className="layout"
							layout={config.charts.map(
								(chart: Chart): Layout => {
									const layout = Utils.getChartLayout(
										config.tileSize,
										chart.position
									);

									// console.log("chart layout", chart.position, layout);
									return {
										...layout,
										i: chart.id
									};
								}
							)}
							cols={columns}
							rowHeight={rowHeight}
							width={width}
							margin={[0, 0]}
							onLayoutChange={(layout: Layout[]) => {
								if (config.charts.length !== layout.length) {
									return;
								}

								let changed = false;
								const getUpdatedLayout = (id: string): Layout | undefined => {
									return layout.find(
										(layoutItem: Layout) => layoutItem.i === id
									);
								};
								const charts = config.charts.map(
									(chart: Chart): Chart => {
										const updatedLayout = getUpdatedLayout(chart.id || "");

										if (
											updatedLayout &&
											chart.position !==
												Utils.getChartPosition(
													updatedLayout,
													this.state.dashboard.config.tileSize
												)
										) {
											changed = true;
											return {
												...chart,
												position: Utils.getChartPosition(
													updatedLayout,
													this.state.dashboard.config.tileSize
												)
											};
										} else {
											return chart;
										}
									}
								);

								// console.log("onLayoutChange", changed, layout, charts);

								if (changed) {
									this.setState((state: LocalState) => ({
										dashboard: {
											...state.dashboard,
											config: {
												...state.dashboard.config,
												charts
											}
										}
									}));

									this.props.onConfigChanged &&
										this.props.onConfigChanged({
											...this.state.dashboard,
											config: {
												...this.state.dashboard.config,
												charts
											}
										});
								}
							}}
							onDragStart={() => {
								this.setState(
									(state: LocalState): LocalState => ({
										...state,
										dragInProgress: true
									})
								);
							}}
							onDragStop={() => {
								this.setState(
									(state: LocalState): LocalState => ({
										...state,
										dragInProgress: false
									})
								);
							}}
						>
							{config.charts.map((chart: Chart) => {
								return (
									<div key={chart.id}>
										{/*<GridItemWrapper style={{}} key={chart.id}>*/}
										<ChartActionsComponent
											resolution={chart.resolution}
											onResolutionChange={(newResolution: string) => {
												this.onResolutionChange(newResolution, chart);
											}}
											onRemoveClick={() => {
												console.log("on remove click");

												const charts = config.charts.filter(
													(c: Chart) => c.id !== chart.id
												);

												this.setState((state: LocalState) => ({
													dashboard: {
														...state.dashboard,
														config: {
															...state.dashboard.config,
															charts
														}
													}
												}));

												this.props.onConfigChanged &&
													this.props.onConfigChanged({
														...this.state.dashboard,
														config: {
															...this.state.dashboard.config,
															charts
														}
													});
											}}
										/>
										<TimeSeriesChartComponent
											refreshInterval={config.refreshInterval}
											period={config.period}
											cluster={cluster}
											node={this.props.node}
											metric={chart.metric}
											title={chart.title}
											resolution={chart.resolution}
											disableRender={dragInProgress}
											aggregation={chart.aggregation}
											unit={chart.unit}
											dataScaling={chart.dataScaling}
											yAxisMax={chart.yAxisMax}
										/>
										{/*</GridItemWrapper>*/}
									</div>
								);
							})}
						</GridLayout>
					</Grid>
				</Grid>
			</>
		);
	}
}

export default withStyles(styles, { withTheme: true })(DashboardComponent);
