import MetricsApi from "modules/api/MetricsApi";
import {
	lastCommittedMetricsFetchSucceeded,
	wsrepLocalStateMetricsFetchSucceeded
} from "modules/metricsStore/actions";
import {
	METRIC,
	WsrepLastCommittedMetric,
	WsrepLocalStateMetric
} from "modules/metricsStore/types";
import moment from "moment";
import { call, delay, put } from "typed-redux-saga";
import { takeLatest } from "redux-saga/effects";
import { SECURE_PRELOADER_ACTION } from "modules/preloader/types";

const POLLING_INTERVAL = 3000;

function* monitorLastCommitted() {
	// console.log("Last committed metrics monitor started");

	const query = `SELECT LAST(wsrep_last_committed), cluster FROM mysql GROUP BY "cluster"`;

	try {
		while (true) {
			const response = yield* call(MetricsApi.fetch, query);
			// console.log(
			// 	"metrics fetch response",
			// 	response,
			// 	response.data.results[0].series
			// );

			const data = response.data.results[0].series;

			const metrics =
				data?.map(
					(res: any, index: number): WsrepLastCommittedMetric => ({
						type: METRIC.WSREP_LAST_COMMITTED,
						time: new Date(res.values[0][data[index].columns.indexOf("time")]),
						value: res.values[0][data[index].columns.indexOf("last")],
						cluster: res.values[0][data[index].columns.indexOf("cluster")]
					})
				) || [];

			// console.log("last committed metrics", metrics);

			yield put(lastCommittedMetricsFetchSucceeded(metrics));
			yield delay(POLLING_INTERVAL);
		}
	} catch (e) {
		console.error("Last committed monitor error:", e);
	}
}

function* monitorLocalState() {
	// console.log("Local state metrics monitor started");

	// console.log("query", query);

	try {
		while (true) {
			const query = `SELECT LAST(wsrep_local_state), node, cluster FROM mysql WHERE time > ${moment()
				.subtract(15, "second")
				.valueOf()}000000 GROUP BY "node"`;

			const response = yield* call(MetricsApi.fetch, query);
			// console.log(
			// 	"metrics fetch response",
			// 	response,
			// 	response.data.results[0].series
			// );

			const data = response.data.results[0].series;

			const metrics =
				data?.map(
					(res: any, index: number): WsrepLocalStateMetric => ({
						type: METRIC.WSREP_LOCAL_STATE,
						time: new Date(res.values[0][data[index].columns.indexOf("time")]),
						value: res.values[0][1],
						node: res.values[0][data[index].columns.indexOf("node")],
						cluster: res.values[0][data[index].columns.indexOf("cluster")]
					})
				) || [];

			// console.log("local state metrics", metrics);

			yield put(wsrepLocalStateMetricsFetchSucceeded(metrics));
			yield delay(POLLING_INTERVAL);
		}
	} catch (e) {
		console.error("Local state monitor error:", e);
		yield delay(POLLING_INTERVAL);
	}
}

function* MetricsStoreSideEffects() {
	yield takeLatest(
		SECURE_PRELOADER_ACTION.PRELOAD_SUCCEEDED,
		monitorLastCommitted
	);
	yield takeLatest(
		SECURE_PRELOADER_ACTION.PRELOAD_SUCCEEDED,
		monitorLocalState
	);
}

export default MetricsStoreSideEffects;
