import {
	clusterDeleteSucceded,
	clusterListFetchSucceeded
} from "components/management/cluster/actions";
import {
	CLUSTER_ACTION,
	ClusterDeleteRequestedAction
} from "components/management/cluster/types";
import ClustersAPI from "modules/api/ClustersAPI";
import { call, put } from "typed-redux-saga";
import { takeEvery, takeLatest } from "redux-saga/effects";

function* fetchClusterList() {
	// console.log("fetchClusterList saga");

	try {
		const clusterList = yield* call(ClustersAPI.fetchList);
		// console.log("saga cluster list fetched", clusterList);

		yield put(clusterListFetchSucceeded(clusterList));
	} catch (e) {
		console.error(e);
	}
}

// function* createCluster(action: ClusterCreateRequestedAction) {
// 	yield call(ClustersAPI.create, action.payload.cluster);
// 	yield put(clusterCreateSucceded(action.payload.cluster));
// }

function* deleteCluster(action: ClusterDeleteRequestedAction) {
	yield call(ClustersAPI.delete, action.payload.cluster);
	yield put(clusterDeleteSucceded(action.payload.cluster.name));
}

function* ClusterSideEffects() {
	yield takeLatest(CLUSTER_ACTION.LIST_FETCH_REQUESTED, fetchClusterList);
	// yield takeEvery(CLUSTER_ACTION.CREATE_REQUESTED, createCluster);
	yield takeEvery(CLUSTER_ACTION.DELETE_REQUESTED, deleteCluster);
}

export default ClusterSideEffects;
