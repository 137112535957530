import cloneDeep from "lodash/cloneDeep";
import { Layout } from "react-grid-layout";
import {
	DASHBOARD_CHARTS_TOP_OFFSET,
	DASHBOARD_NO_OF_COLUMNS,
	DEFAULT_DASHBOARD_CONFIG,
	DASHBOARD_NO_OF_ROWS
} from "components/monitoring/dashboard/const";

export default class Utils {
	static getDefaultConfiguration(name: string) {
		let defaultConfig = cloneDeep(DEFAULT_DASHBOARD_CONFIG);
		defaultConfig.name = name;
		return defaultConfig;
	}

	static calculateSize(
		tileSize: string
	): { columns: number; rowHeight: number } {
		const visibleInitialDashHeight =
			window.innerHeight - DASHBOARD_CHARTS_TOP_OFFSET;

		switch (tileSize) {
			case "sm":
				return {
					columns: DASHBOARD_NO_OF_COLUMNS.SM,
					rowHeight: visibleInitialDashHeight / DASHBOARD_NO_OF_ROWS.SM
				};
			case "md":
				return {
					columns: DASHBOARD_NO_OF_COLUMNS.MD,
					rowHeight: visibleInitialDashHeight / DASHBOARD_NO_OF_ROWS.MD
				};
			case "lg":
			default:
				return {
					columns: DASHBOARD_NO_OF_COLUMNS.LG,
					rowHeight: visibleInitialDashHeight / DASHBOARD_NO_OF_ROWS.LG
				};
		}
	}

	static getChartLayout(tileSize: string, position: number): Layout {
		const { columns } = Utils.calculateSize(tileSize);

		const x = position % columns;
		const y = parseInt(`${position / columns}`, 10);

		return {
			i: "",
			x: x,
			y: y,
			w: 1,
			h: 1
		};
	}

	static getChartPosition(layout: Layout, tileSize: string): number {
		const { columns } = Utils.calculateSize(tileSize);
		return layout.y * columns + layout.x;
	}
}
