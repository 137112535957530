import Axios from "axios";
import axios from "axios";
import { Host, SerializedHost } from "components/management/host/types";
import APIUtils from "modules/api/utils";
import { deattribute, serialise } from "kitsu-core";
import { Job } from "modules/jobs/types";
import { CLUSTERS_ENDPOINT, HOSTS_ENDPOINT } from "modules/api/const";

class HostsAPI {
	static async fetchList(clusterID: number): Promise<Host[]> {
		try {
			const hostsResponse = await Axios.get(
				`${APIUtils.getBaseURL()}/${CLUSTERS_ENDPOINT}/${clusterID}/${HOSTS_ENDPOINT}`
			);

			return hostsResponse.data.data.map((item: SerializedHost) =>
				deattribute({ ...item })
			);
		} catch (e) {
			throw new Error(APIUtils.APIErrorResponseHandler(e));
		}
	}

	static async create(host: Host): Promise<{ job: Job; host: Host }> {
		try {
			console.log("create host API", host);
			const data = serialise("hosts", host, "POST");

			// need to copy host type because serializer ignores it
			data.data.attributes.type = host.type;

			console.log("serialized host data", data);
			const response = await axios.post(
				`${APIUtils.getBaseURL()}/${CLUSTERS_ENDPOINT}/${
					host.clusterID
				}/${HOSTS_ENDPOINT}`,
				data
			);

			console.log("response", response);

			const job = response.data.meta.jobInfo as Job;
			const hostData = deattribute(response.data.data);

			// appStore.dispatch(jobCreated(job));

			return { job: { ...job }, host: { ...hostData } };
		} catch (e) {
			throw new Error(APIUtils.APIErrorResponseHandler(e));
		}
	}

	static async delete(host: Host, force: boolean = false): Promise<Job> {
		try {
			console.log("delete host", host);
			// need to copy host type because serializer ignores it

			const response = await axios.delete(
				`${APIUtils.getBaseURL()}/${CLUSTERS_ENDPOINT}/${
					host.clusterID
				}/${HOSTS_ENDPOINT}/${host.id}?withNode=true${
					force ? "&force=true" : ""
				}`
			);

			console.log("response", response);

			const job = response.data.meta.jobInfo as Job;

			// appStore.dispatch(jobCreated(job));

			return { ...job };
		} catch (e) {
			throw new Error(APIUtils.APIErrorResponseHandler(e));
		}

		// return await Axios.delete(
		// 	`${AppEnvironment.getApiAddress()}/${AppEnvironment.getApiVersion()}/hosts/${
		// 		host.name
		// 	}?with_node=1${force ? "&force=1" : ""}`
		// );
	}
}

export default HostsAPI;
