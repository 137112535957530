import {
	SYSTEM_STATUS_ACTION,
	SystemStatusAction,
	SystemStatusState
} from "modules/systemStatus/types";

const initialState: SystemStatusState = {
	prometheus: {
		isOnline: false
	}
};

export function systemStatusReducer(
	state: SystemStatusState = initialState,
	action: SystemStatusAction
): SystemStatusState {
	switch (action.type) {
		case SYSTEM_STATUS_ACTION.PROMETHEUS_SET_ONLINE:
			return {
				...state,
				prometheus: {
					...state.prometheus,
					isOnline: true
				}
			};

		case SYSTEM_STATUS_ACTION.PROMETHEUS_SET_OFFLINE:
			return {
				...state,
				prometheus: {
					...state.prometheus,
					isOnline: false
				}
			};

		default:
			return state;
	}
}
