import { ChartMetric } from "components/monitoring/charts/const";

export interface Chart {
	id: string;
	title: string;
	metric: ChartMetric;
	position: number;
	resolution: string;
	aggregation: AGGREGATION;
	unit?: string;
	dataScaling?: number;
	yAxisMax?: number;
}

export interface Dashboard {
	name: string;
	config: {
		charts: Chart[];
		tileSize: string;
		refreshInterval: number;
		period: string;
	};
}

export enum AGGREGATION {
	RAW = "raw",
	DIFFERENTIAL = "differential"
}
