import {
	PUBLIC_PRELOADER_ACTION,
	PublicPreloadFailedAction,
	PublicPreloadRequestedAction,
	PublicPreloadSucceededAction,
	SECURE_PRELOADER_ACTION,
	SecurePreloadFailedAction,
	SecurePreloadRequestedAction,
	SecurePreloadSucceededAction
} from "modules/preloader/types";

export const securePreloadRequested = (): SecurePreloadRequestedAction => ({
	type: SECURE_PRELOADER_ACTION.PRELOAD_REQUESTED
});

export const securePreloadSucceeded = (): SecurePreloadSucceededAction => ({
	type: SECURE_PRELOADER_ACTION.PRELOAD_SUCCEEDED
});

export const securePreloadFailed = (
	reason: string
): SecurePreloadFailedAction => ({
	type: SECURE_PRELOADER_ACTION.PRELOAD_FAILED,
	payload: {
		reason
	}
});

export const publicPreloadRequested = (): PublicPreloadRequestedAction => ({
	type: PUBLIC_PRELOADER_ACTION.PRELOAD_REQUESTED
});

export const publicPreloadSucceeded = (): PublicPreloadSucceededAction => ({
	type: PUBLIC_PRELOADER_ACTION.PRELOAD_SUCCEEDED
});

export const publicPreloadFailed = (
	reason: string
): PublicPreloadFailedAction => ({
	type: PUBLIC_PRELOADER_ACTION.PRELOAD_FAILED,
	payload: {
		reason
	}
});
