import "date-fns";
import React from "react";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import { styles } from "./styles";
import {
	Checkbox,
	CircularProgress,
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	FormControlLabel,
	Grid,
	Typography
} from "@material-ui/core";
import { LOG_FETCH_PAGE_SIZE } from "components/sharedComponents/logViewer/const";
import LogMessage from "components/sharedComponents/logViewer/LogMessageComponent";
import LogViewerService from "components/sharedComponents/logViewer/LogViewerService";
import { LogLine } from "components/sharedComponents/logViewer/types";
import moment from "moment";
import { Utils } from "modules/utils";
import { Node } from "components/management/node/types";
import { ExpandMore } from "@material-ui/icons";
import { Cluster } from "components/management/cluster/types";
import { Host } from "components/management/host/types";

interface LocalState {
	logs: any[];
	isLoadingMessagesBefore: boolean;
	isLoadingMessagesAfter: boolean;
	isLoadingLogPaths: boolean;
	hasMessagesBefore: boolean;
	hasMessagesAfter: boolean;
	date: Date;
	availableLogs: string[];
	selectedLogs: string[];
}

interface LocalProps {
	cluster: Cluster;
	node?: Node;
	host?: Host;
}

type Props = LocalProps & WithStyles<typeof styles>;

interface Snapshot {
	hasNodeChanged: boolean;
	hasDateChanged: boolean;
	hasSelectedLogsChanged: boolean;
}

class LogViewer extends React.Component<Props, LocalState> {
	autoRefreshTimeout?: any;
	_isMounted: boolean = false;

	DEFAULT_STATE = {
		logs: [],
		hasMessagesBefore: true,
		hasMessagesAfter: false,
		isLoadingMessagesBefore: false,
		isLoadingMessagesAfter: false,
		isLoadingLogPaths: true,
		date: new Date(),
		availableLogs: [],
		selectedLogs: []
	};

	constructor(props: Props) {
		super(props);

		this.state = { ...this.DEFAULT_STATE };
	}

	shouldComponentUpdate(
		nextProps: Readonly<Props>,
		nextState: Readonly<LocalState>,
		nextContext: any
	): boolean {
		const hasClusterChanged = this.props.cluster !== nextProps.cluster;
		const hasNodeChanged = this.props.node !== nextProps.node;
		const hasHostChanged = this.props.host !== nextProps.host;
		// 	this.areNodesDifferent(
		// 	this.props.node,
		// 	nextProps.node
		// );

		const hasStateChanged =
			this.state.hasMessagesAfter !== nextState.hasMessagesAfter ||
			this.state.hasMessagesBefore !== nextState.hasMessagesBefore ||
			this.state.isLoadingMessagesBefore !==
				nextState.isLoadingMessagesBefore ||
			this.state.isLoadingMessagesAfter !== nextState.isLoadingMessagesAfter ||
			this.state.isLoadingMessagesAfter !== nextState.isLoadingMessagesAfter ||
			this.state.isLoadingLogPaths !== nextState.isLoadingLogPaths ||
			this.state.selectedLogs !== nextState.selectedLogs ||
			this.state.date !== nextState.date;

		// console.log("shouldComponentUpdate", hasNodesChanged, hasStateChanged);

		return (
			hasClusterChanged || hasNodeChanged || hasHostChanged || hasStateChanged
		);
	}

	componentDidMount(): void {
		// load initial logs

		this.loadLogPaths();
		this._isMounted = true;
	}

	componentWillUnmount(): void {
		this.stopAutoRefresh();
		this._isMounted = false;
	}

	getSnapshotBeforeUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<LocalState>
	): Snapshot {
		// console.log(
		// 	"getSnapshot",
		// 	prevProps.nodes,
		// 	this.props.nodes,
		// 	prevState.date,
		// 	this.state.date
		// );
		return {
			hasNodeChanged:
				((this.props.node && this.props.node.name) || "") !==
				((prevProps.node && prevProps.node.name) || ""),
			hasDateChanged: prevState.date !== this.state.date,
			hasSelectedLogsChanged: prevState.selectedLogs !== this.state.selectedLogs
		};
	}

	componentDidUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<LocalState>,
		snapshot: Snapshot
	): void {
		// console.log("componentDidUpdate", snapshot);
		if (snapshot.hasDateChanged || snapshot.hasSelectedLogsChanged) {
			this.setState({ logs: [], hasMessagesBefore: true });
			// console.log("componentDidUpdate", this.state.selectedLogs);
			this.loadLogMessages(
				"before",
				this.state.selectedLogs,
				moment().toISOString()
			);

			// this.loadLogMessages(
			// 	"around",
			// 	this.state.selectedLogs,
			// 	moment().subtract(3, "minutes").toISOString()
			// );
		} else if (snapshot.hasNodeChanged) {
			this.setState({ logs: [], availableLogs: [], selectedLogs: [] });
			this.loadLogPaths();
			// this.loadLogPaths(this.props.hosts);
		}
	}

	onScroll = (e: any) => {
		// console.log("onScroll", e.target.scrollTop);
		// e.persist();
		const { scrollTop, clientHeight, scrollHeight } = e.target;
		const {
			isLoadingMessagesBefore,
			hasMessagesBefore,
			isLoadingMessagesAfter,
			hasMessagesAfter,
			logs
		} = this.state;

		// temporarily disable scroll while more messages are loading
		(isLoadingMessagesBefore || isLoadingMessagesAfter) && e.preventDefault();

		// when scrolled to the top fetch more messages if there are any
		// also do not fetch if already loading
		//     - this prevents multiple loads if scroll event is triggered multiple times
		if (
			logs[0] &&
			scrollTop === 0 &&
			!isLoadingMessagesBefore &&
			hasMessagesBefore
		) {
			this.loadLogMessages(
				"before",
				this.state.selectedLogs,
				logs[0].time.toNanoISOString()
			);
		}

		// whenever scroll is changed, disable delayed refresh
		if (this.autoRefreshTimeout) {
			this.stopAutoRefresh();
		}

		// when scrolled to the bottom fetch more messages if there are any
		// also do not fetch if already loading
		//     - this prevents multiple loads if scroll event is triggered multiple times
		const isScrolledToBottom =
			Math.abs(scrollTop + clientHeight - scrollHeight) < 1;

		if (
			logs[0] &&
			isScrolledToBottom &&
			hasMessagesAfter &&
			!isLoadingMessagesAfter
		) {
			this.loadLogMessages(
				"after",
				this.state.selectedLogs,
				logs[logs.length - 1].time.toNanoISOString()
			);
		} else if (isScrolledToBottom && !hasMessagesAfter) {
			!this.autoRefreshTimeout && this.startAutoRefresh();
		}
	};

	startAutoRefresh() {
		// console.log("start auto refresh");
		this.autoRefreshTimeout = setTimeout(() => {
			const { logs } = this.state;
			// console.log("auto refresh executed", logs, logs[logs.length - 1]);

			this.loadLogMessages(
				"after",
				this.state.selectedLogs,
				logs[logs.length - 1].time.toNanoISOString(),
				true
			);
			this._isMounted && this.startAutoRefresh();
		}, 3000);
	}

	stopAutoRefresh() {
		// console.log("stop auto refresh");
		clearTimeout(this.autoRefreshTimeout);
		delete this.autoRefreshTimeout;
	}

	loadLogPaths() {
		// loadLogPaths(hosts: Host[]) {
		this.setState({
			isLoadingLogPaths: true
		});
		// console.time("loadLogPaths");
		const { node, cluster, host } = this.props;
		console.log("loadLogPaths", cluster, node, host);
		// if (node) {
		LogViewerService.fetchPaths(cluster, node, host).then((response: any) => {
			const availableLogs = response.map((res: any) => res.distinct);
			console.log("node availableLogs", availableLogs);
			this.setState({
				availableLogs,
				selectedLogs: [...availableLogs],
				isLoadingLogPaths: false
			});
		});
		// } else {
		// 	LogViewerService.fetchPaths(cluster, node).then((response: any) => {
		// 		const availableLogs = response.map((res: any) => res.distinct);
		// 		console.log("cluster availableLogs", availableLogs);
		// 		this.setState({
		// 			availableLogs,
		// 			selectedLogs: [...availableLogs],
		// 			isLoadingLogPaths: false
		// 		});
		// 	});
		// }
		// LogViewerService.fetchPaths(hosts).then((response: any) => {
		// 	const availableLogs = response.map((res: any) => res.distinct);
		// 	this.setState({
		// 		availableLogs,
		// 		selectedLogs: [...availableLogs],
		// 		isLoadingLogPaths: false
		// 	});
		// 	// console.timeEnd("loadLogPaths");
		// });
	}

	loadLogMessages(
		direction: "before" | "after" | "around" = "before",
		selectedLogs: string[],
		dateISOString: string,
		autoRefresh?: boolean
	) {
		// console.log("loadLogMessages", direction, selectedLogs, dateISOString);
		// console.time("loadLogMessages");
		if (selectedLogs.length === 0) {
			this.setState({
				logs: [],
				isLoadingMessagesAfter: false,
				isLoadingMessagesBefore: false,
				hasMessagesBefore: false,
				hasMessagesAfter: false
			});
			return;
		}

		const { cluster, node } = this.props;

		const logContentElement = document.querySelector("#log-content");

		// console.log("firstLogMessage", firstLogMessage, firstElementInitialOffset);

		switch (direction) {
			case "before":
				this.setState({ isLoadingMessagesBefore: true });

				const firstLogMessage =
					logContentElement && logContentElement.querySelectorAll("p")[0];

				// console.log("infoLogMessage", infoLogMessage);

				const firstElementInitialOffset = firstLogMessage
					? firstLogMessage.offsetTop
					: 0;

				// console.log("firstElementInitialOffset", firstElementInitialOffset);

				LogViewerService.fetchBefore(
					// this.props.hosts,
					selectedLogs,
					dateISOString,
					cluster,
					node
				).then((response: LogLine[]) => {
					// console.log("influx query response", response);

					const wasLogEmpty = this.state.logs.length === 0;

					this.setState((state: LocalState) => ({
						logs: [...response.reverse(), ...state.logs],
						isLoadingMessagesBefore: false,
						hasMessagesBefore: response.length === LOG_FETCH_PAGE_SIZE
					}));

					// if log was empty before, scroll to bottom
					if (wasLogEmpty) {
						const logContent = document.getElementById("log-content");
						if (logContent) {
							logContent.scrollTop = logContent.scrollHeight;
						}

						// if log was not empty, scroll to previously first log message
					} else if (firstLogMessage && logContentElement) {
						// console.log(
						// 	"scroll to",
						// 	firstLogMessage.offsetTop - firstElementInitialOffset
						// );
						logContentElement.scrollTo({
							top: firstLogMessage.offsetTop - firstElementInitialOffset
						});
					}

					// console.timeEnd("loadLogMessages");
				});
				break;

			case "after":
				this.setState({ isLoadingMessagesAfter: true });

				LogViewerService.fetchAfter(
					// this.props.hosts,
					selectedLogs,
					dateISOString,
					cluster,
					node
				).then((response: LogLine[]) => {
					// console.log("influx query response", response);
					// console.timeEnd("loadLogMessages");

					this.setState((state: LocalState) => ({
						logs: [...state.logs, ...response],
						isLoadingMessagesAfter: false,
						hasMessagesAfter: response.length === LOG_FETCH_PAGE_SIZE
					}));

					if (autoRefresh) {
						const logContent = document.getElementById("log-content");
						if (logContent) {
							logContent.scrollTop = logContent.scrollHeight;
						}
					}
				});
				break;

			case "around":
				this.setState({
					logs: [
						{
							message: "[Info] Target date",
							time: Utils.toNanoDate(moment(dateISOString).toDate()),
							_md5hash: dateISOString
						}
					],
					isLoadingMessagesBefore: true,
					isLoadingMessagesAfter: true
				});

				LogViewerService.fetchBefore(
					// this.props.hosts,
					selectedLogs,
					dateISOString,
					cluster,
					node
				).then((response: LogLine[]) => {
					// console.log("influx query response before", response);

					const infoLogMessage =
						logContentElement && logContentElement.querySelectorAll("p")[0];

					const infoElementInitialOffset = infoLogMessage
						? infoLogMessage.offsetTop
						: 0;

					this.setState((state: LocalState) => ({
						logs: [...response.reverse(), ...state.logs],
						isLoadingMessagesBefore: false,
						hasMessagesBefore: response.length === LOG_FETCH_PAGE_SIZE
					}));

					if (infoLogMessage && logContentElement) {
						// console.log(
						// 	"scroll to",
						// 	infoLogMessage.offsetTop - infoElementInitialOffset
						// );
						logContentElement.scrollTo({
							top: infoLogMessage.offsetTop - infoElementInitialOffset
						});
					}
				});

				LogViewerService.fetchAfter(
					// this.props.hosts,
					selectedLogs,
					dateISOString,
					cluster,
					node
				).then((response: LogLine[]) => {
					// console.log("influx query response after", response);

					this.setState((state: LocalState) => ({
						logs: [...state.logs, ...response],
						isLoadingMessagesAfter: false,
						hasMessagesAfter: response.length === LOG_FETCH_PAGE_SIZE
					}));

					// console.timeEnd("loadLogMessages");
				});
				break;
		}
	}

	render() {
		const { classes, node } = this.props;
		const {
			logs,
			isLoadingMessagesBefore,
			isLoadingMessagesAfter,
			isLoadingLogPaths,
			hasMessagesBefore,
			hasMessagesAfter,
			selectedLogs,
			availableLogs
		} = this.state;

		const isLoading =
			isLoadingMessagesBefore || isLoadingMessagesAfter || isLoadingLogPaths;
		const isEmpty = logs.length === 0;

		const logContent = (
			<>
				{hasMessagesBefore ? (
					<CircularProgress size="0.9em" color="primary" />
				) : (
					<Typography color="primary" className={classes.logInfoTypography}>
						=== Start of log ===
					</Typography>
				)}

				{logs.map((log: LogLine) => (
					<LogMessage
						key={log._logLineHash}
						logLine={log}
						showNodeName={!node}
					/>
				))}

				<Grid container direction="row">
					<CircularProgress
						style={{ marginTop: 3 }}
						size="0.9em"
						color="primary"
					/>
					{!hasMessagesAfter && (
						<>
							<Typography
								style={{ paddingLeft: 7 }}
								color="primary"
								className={classes.logInfoTypography}
							>
								Live...
							</Typography>
						</>
					)}
				</Grid>
			</>
		);

		return (
			<>
				<Grid container item sm direction="column">
					<ExpansionPanel
						classes={{
							expanded: classes.expansionPanelExpanded
						}}
					>
						<ExpansionPanelSummary
							expandIcon={<ExpandMore />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<Typography>
								Showing {selectedLogs.length} logs:{" "}
								{selectedLogs
									.map((path: string) => path.split("/").slice(-1))
									.join(", ")}
							</Typography>
						</ExpansionPanelSummary>
						<ExpansionPanelDetails>
							<form>
								{availableLogs
									.sort((a: string, b: string) => a.localeCompare(b))
									.map((logPath: string) => (
										<FormControlLabel
											key={logPath}
											control={
												<Checkbox
													checked={selectedLogs.includes(logPath)}
													onChange={(
														e: React.ChangeEvent<HTMLInputElement>
													) => {
														const { checked } = e.target;

														checked
															? this.setState((state: LocalState) => ({
																	selectedLogs: [...state.selectedLogs, logPath]
															  }))
															: this.setState((state: LocalState) => ({
																	selectedLogs: state.selectedLogs.filter(
																		(selectedLogPath: String) =>
																			selectedLogPath !== logPath
																	)
															  }));
													}}
													name={logPath}
													color="primary"
												/>
											}
											label={logPath}
										/>
									))}
							</form>
						</ExpansionPanelDetails>
					</ExpansionPanel>

					{/*<Grid*/}
					{/*	component="div"*/}
					{/*	item*/}
					{/*	container*/}
					{/*	direction="row"*/}
					{/*	className={classes.toolbar}*/}
					{/*	alignItems="center"*/}
					{/*>*/}
					{/*	<Grid item>*/}
					{/*		<form>*/}
					{/*			{availableLogs*/}
					{/*				.sort((a: string, b: string) => a.localeCompare(b))*/}
					{/*				.map((logPath: string) => (*/}
					{/*					<FormControlLabel*/}
					{/*						key={logPath}*/}
					{/*						control={*/}
					{/*							<Checkbox*/}
					{/*								checked={selectedLogs.includes(logPath)}*/}
					{/*								onChange={(*/}
					{/*									e: React.ChangeEvent<HTMLInputElement>*/}
					{/*								) => {*/}
					{/*									const { checked } = e.target;*/}

					{/*									checked*/}
					{/*										? this.setState((state: LocalState) => ({*/}
					{/*												selectedLogs: [...state.selectedLogs, logPath]*/}
					{/*										  }))*/}
					{/*										: this.setState((state: LocalState) => ({*/}
					{/*												selectedLogs: state.selectedLogs.filter(*/}
					{/*													(selectedLogPath: String) =>*/}
					{/*														selectedLogPath !== logPath*/}
					{/*												)*/}
					{/*										  }));*/}
					{/*								}}*/}
					{/*								name={logPath}*/}
					{/*								color="primary"*/}
					{/*							/>*/}
					{/*						}*/}
					{/*						label={logPath}*/}
					{/*					/>*/}
					{/*				))}*/}
					{/*		</form>*/}
					{/*	</Grid>*/}
					{/*	<Grid item sm />*/}
					{/*</Grid>*/}

					<Grid
						item
						sm
						className={classes.logContent}
						onScroll={this.onScroll}
						id="log-content"
					>
						{isEmpty ? (
							isLoading ? (
								<Typography color="primary">Loading messages...</Typography>
							) : (
								<Typography color="primary">Log is empty.</Typography>
							)
						) : (
							logContent
						)}
					</Grid>
				</Grid>
			</>
		);
	}
}

export default withStyles(styles)(LogViewer);
