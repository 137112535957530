export interface PreloaderState {
	publicPreloader: PublicPreloaderState;
	securePreloader: SecurePreloaderState;
}

export interface SecurePreloaderState {
	isPreloadDone: boolean;
	errorMsg?: string;
}

export enum SECURE_PRELOADER_ACTION {
	PRELOAD_REQUESTED = "SECURE_PRELOADER/PRELOAD_REQUESTED",
	PRELOAD_SUCCEEDED = "SECURE_PRELOADER/PRELOAD_SUCCEEDED",
	PRELOAD_FAILED = "SECURE_PRELOADER/PRELOAD_FAILED"
}

export interface SecurePreloadRequestedAction {
	type: SECURE_PRELOADER_ACTION.PRELOAD_REQUESTED;
}

export interface SecurePreloadSucceededAction {
	type: SECURE_PRELOADER_ACTION.PRELOAD_SUCCEEDED;
}

export interface SecurePreloadFailedAction {
	type: SECURE_PRELOADER_ACTION.PRELOAD_FAILED;
	payload: {
		reason: string;
	};
}

export type SecurePreloaderAction =
	| SecurePreloadRequestedAction
	| SecurePreloadSucceededAction
	| SecurePreloadFailedAction;

// public preloader types
export interface PublicPreloaderState {
	isPreloadDone: boolean;
	errorMsg?: string;
}

export enum PUBLIC_PRELOADER_ACTION {
	PRELOAD_REQUESTED = "PUBLIC_PRELOADER/PRELOAD_REQUESTED",
	PRELOAD_SUCCEEDED = "PUBLIC_PRELOADER/PRELOAD_SUCCEEDED",
	PRELOAD_FAILED = "PUBLIC_PRELOADER/PRELOAD_FAILED"
}

export interface PublicPreloadRequestedAction {
	type: PUBLIC_PRELOADER_ACTION.PRELOAD_REQUESTED;
}

export interface PublicPreloadSucceededAction {
	type: PUBLIC_PRELOADER_ACTION.PRELOAD_SUCCEEDED;
}

export interface PublicPreloadFailedAction {
	type: PUBLIC_PRELOADER_ACTION.PRELOAD_FAILED;
	payload: {
		reason: string;
	};
}

export type PublicPreloaderAction =
	| PublicPreloadRequestedAction
	| PublicPreloadSucceededAction
	| PublicPreloadFailedAction;
