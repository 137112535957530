import { DEPLOYMENT_STATUS } from "components/management/cluster/types";

export interface HostSharedConfig {
	type: HOST_TYPE;
	system: HOST_SYSTEM;
	hostTypeSpecific?: EC2SpecificSettings;
	authorizedKeys?: string[];
	privateKey?: string;
	firewallRules?: FirewallRule[];
}

export interface Host {
	id?: number;
	name: string;
	clusterID: number;
	nodeID?: number;
	segment: number;
	type: HOST_TYPE;
	system: HOST_SYSTEM;
	deploymentStatus?: string;
	privateKey: string;
	privateKeyPassword?: string;
	ssh?: {
		address: string;
		port: string;
	};
	db?: {
		address: string;
		port: string;
	};
	authorizedKeys: string[];
	hostTypeSpecific?: EC2SpecificSettings;
	// firewallRules: FirewallRule[];
}

export interface EC2SpecificSettings {
	accessKeyID?: string;
	secretAccessKey?: string;
	region?: string;
	instanceType?: string;
}

export interface FirewallRule {
	description: string;
	port: number;
	protocol: "tcp" | "udp";
	ranges: string[];
}

export interface SerializedHost {
	attributes: Host;
	id?: number;
	type: "hosts";
}

export enum HOST_TYPE {
	EC2 = "ec2",
	// LOCAL_LXD = "locallxd",
	UNMANAGED = "unmanaged"
}

export enum HOST_SYSTEM {
	CENTOS_7 = "centos:7",
	CENTOS_8 = "centos:8",
	DEBIAN_10 = "debian:10",
	REDHAT_8 = "redhat:8",
	UBUNTU_18 = "ubuntu:18.04",
	UBUNTU_20 = "ubuntu:20.04"
}

export enum HOST_STATUS {
	UNKNOWN = -1
}

export type HOST_STATE = DEPLOYMENT_STATUS | HOST_STATUS;

// redux actions
export enum HOST_ACTION {
	LIST_FETCH_REQUESTED = "HOST/LIST_FETCH_REQUESTED",
	LIST_FETCH_SUCCEEDED = "HOST/LIST_FETCH_SUCCEEDED"
}

export interface HostListFetchRequestedAction {
	type: HOST_ACTION.LIST_FETCH_REQUESTED;
	payload: {
		clusterID: number;
	};
}

export interface HostListFetchSucceededAction {
	type: HOST_ACTION.LIST_FETCH_SUCCEEDED;
	payload: {
		clusterID: number;
		hostList: Host[];
	};
}

export type HostAction =
	| HostListFetchRequestedAction
	| HostListFetchSucceededAction;
