import Axios, { AxiosResponse } from "axios";
import AppEnvironment from "modules/appEnvironment";
import APIUtils from "modules/api/utils";
import { deattribute } from "kitsu-core";
import { Job, SerializedJob } from "modules/jobs/types";

const ENDPOINT = "jobs";

class JobsApi {
	static async fetchList(): Promise<Job[]> {
		try {
			const jobsResponse = await Axios.get(APIUtils.getUrl(ENDPOINT));

			return jobsResponse.data.data.map((item: SerializedJob) =>
				deattribute({ ...item })
			);
		} catch (e) {
			throw new Error(APIUtils.APIErrorResponseHandler(e));
		}
	}

	static async fetch(id: number): Promise<AxiosResponse> {
		return await Axios.get(
			`${AppEnvironment.getApiAddress()}/${AppEnvironment.getApiVersion()}/jobs/${id}`
		);
	}
}

export default JobsApi;
