import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Login from "components/login/LoginComponent";
import { createBrowserHistory, History } from "history";
import RootComponent from "components/root/RootComponent";
import { AppState } from "../AppState";
import { connect } from "react-redux";
import { PublicPreloaderState } from "modules/preloader/types";
import Box from "@material-ui/core/Box";
import PublicPreloader from "modules/preloader/PublicPreloaderComponent";
import AppInfo from "modules/appInfo/AppInfoComponent";

interface ReduxStateProps {
	publicPreloader: PublicPreloaderState;
}

type Props = ReduxStateProps;

export const history: History = createBrowserHistory({ basename: "/app" });

class GMRouter extends React.Component<Props> {
	render() {
		const { publicPreloader } = this.props;
		return (
			<>
				{!publicPreloader.isPreloadDone ? (
					<Box height="100%">
						<PublicPreloader />
					</Box>
				) : (
					<>
						<BrowserRouter basename="/app">
							<Switch>
								<Route path="/login" component={Login} />
								<Route path="/" component={RootComponent} />
								<Redirect from="*" to="/login" />
							</Switch>
						</BrowserRouter>
						<AppInfo />
					</>
				)}
			</>
		);
	}
}

const mapGlobalStateToProps = (state: AppState) => {
	return {
		publicPreloader: state.preloader.publicPreloader
	};
};

export default connect(mapGlobalStateToProps, {})(GMRouter);
