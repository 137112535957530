export interface WebSocketHandlerState {
	isConnectionOpen: boolean;
	connectionOpenErrorMessage: string;
}

export enum WEB_SOCKET_HANDLER_ACTION {
	CONNECTION_OPEN_REQUESTED = "WEB_SOCKET_HANDLER/CONNECTION_OPEN_REQUESTED",
	CONNECTION_OPEN_SUCCEDED = "WEB_SOCKET_HANDLER/CONNECTION_OPEN_SUCCEDED",
	CONNECTION_OPEN_FAILED = "WEB_SOCKET_HANDLER/CONNECTION_OPEN_FAILED",
	CONNECTION_AUTHENTICATION_REQUESTED = "WEB_SOCKET_HANDLER/CONNECTION_AUTHENTICATION_REQUESTED",
	CONNECTION_CLOSE_REQUESTED = "WEB_SOCKET_HANDLER/CONNECTION_CLOSE_REQUESTED",
	CONNECTION_CLOSE_SUCCEDED = "WEB_SOCKET_HANDLER/CONNECTION_CLOSE_SUCCEDED",
	MESSAGE_SEND_REQUESTED = "WEB_SOCKET_HANDLER/MESSAGE_SEND_REQUESTED",
	LOG_MESSAGE_RECEIVED = "WEB_SOCKET_HANDLER/LOG_MESSAGE_RECEIVED"
}

export interface WebSocketHandlerConnectionOpenRequestedAction {
	type: WEB_SOCKET_HANDLER_ACTION.CONNECTION_OPEN_REQUESTED;
}

export interface WebSocketHandlerConnectionOpenSuccededAction {
	type: WEB_SOCKET_HANDLER_ACTION.CONNECTION_OPEN_SUCCEDED;
}

export interface WebSocketHandlerConnectionOpenFailedAction {
	type: WEB_SOCKET_HANDLER_ACTION.CONNECTION_OPEN_FAILED;
	payload: {
		reason: string;
	};
}

export interface WebSocketHandlerConnectionAuthenticationRequestedAction {
	type: WEB_SOCKET_HANDLER_ACTION.CONNECTION_AUTHENTICATION_REQUESTED;
}

export interface WebSocketHandlerConnectionCloseRequestedAction {
	type: WEB_SOCKET_HANDLER_ACTION.CONNECTION_CLOSE_REQUESTED;
}

export interface WebSocketHandlerConnectionCloseSuccededAction {
	type: WEB_SOCKET_HANDLER_ACTION.CONNECTION_CLOSE_SUCCEDED;
}

export interface WebSocketHandlerMessageSendRequestedAction {
	type: WEB_SOCKET_HANDLER_ACTION.MESSAGE_SEND_REQUESTED;
	payload: {
		message: any;
	};
}

export interface WebSocketHandlerLogMessageReceivedAction {
	type: WEB_SOCKET_HANDLER_ACTION.LOG_MESSAGE_RECEIVED;
	payload: {
		message: any;
	};
}

export type WebSocketHandlerAction =
	| WebSocketHandlerConnectionOpenRequestedAction
	| WebSocketHandlerConnectionOpenSuccededAction
	| WebSocketHandlerConnectionOpenFailedAction
	| WebSocketHandlerConnectionAuthenticationRequestedAction
	| WebSocketHandlerConnectionCloseRequestedAction
	| WebSocketHandlerConnectionCloseSuccededAction
	| WebSocketHandlerMessageSendRequestedAction
	| WebSocketHandlerLogMessageReceivedAction;
