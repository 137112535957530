import { Theme, createStyles } from "@material-ui/core";

export const styles = (theme: Theme) =>
	createStyles({
		card: {
			width: "100%"
			// [theme.breakpoints.up("sm")]: {
			// 	width: 700
			// }
		},
		cardContentRoot: {
			paddingTop: theme.spacing(1)
		},
		tabs: {
			minHeight: 42
		},
		tab: {
			minHeight: 42
		}
	});
