import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
	createStyles({
		root: {
			width: "100%",
			[theme.breakpoints.up("sm")]: {
				width: 340
			},
			backgroundColor: theme.palette.background.paper
		}
	});
