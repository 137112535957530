import React from "react";
import { withStyles, WithStyles, WithTheme } from "@material-ui/core/styles";
import { styles } from "./styles";
import Config from "modules/config/Config";
import Axios, { AxiosError, AxiosResponse } from "axios";
import { ChartMetric } from "components/monitoring/charts/const";
import moment from "moment";
import { Cluster } from "components/management/cluster/types";
import { Node } from "components/management/node/types";
import { IResponseSeries, IResultEntry } from "influx/lib/src/results";

interface LocalState {
	rawValues: Map<string, number>;
}

interface LocalProps {
	traces: any[];
	metric: ChartMetric;
	refreshInterval: number;
	differential: boolean;
	cluster: Cluster;
	node?: Node;
}

type Props = LocalProps & WithStyles<typeof styles> & WithTheme;

interface Snapshot {
	shouldFetchAsyncData: boolean;
	shouldClearExistingData: boolean;
}

class LiveValueComponent extends React.PureComponent<Props, LocalState> {
	_isMounted = false;
	_scheduledFetch: any;
	_cancelRequest: any;

	constructor(props: Props) {
		super(props);

		this.state = { rawValues: new Map<string, number>() };
	}

	componentDidMount(): void {
		this._isMounted = true;
		// this.props.differential && this.fetchData();
	}

	componentWillUnmount(): void {
		this._isMounted = false;
	}

	getSnapshotBeforeUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<LocalState>
	): any | null {
		const prevHostNames = prevProps.traces.map((trace: any) => trace.name);
		const hostNames = this.props.traces.map((trace: any) => trace.name);

		return {
			shouldFetchAsyncData:
				this.props.differential &&
				(prevHostNames.join("") !== hostNames.join("") ||
					prevProps.metric !== this.props.metric ||
					prevProps.refreshInterval !== this.props.refreshInterval),
			shouldClearExistingData:
				prevProps.metric !== this.props.metric ||
				prevHostNames.join("") !== hostNames.join("")
		};
	}

	componentDidUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<LocalState>,
		snapshot?: Snapshot
	): void {
		// if metric, cluster or node changed - reload async data
		if (this._isMounted && snapshot) {
			if (snapshot.shouldFetchAsyncData) {
				this.clearAsyncRequests();
				this.fetchData();
			}

			if (snapshot.shouldClearExistingData) {
				this.setState({ rawValues: new Map<string, number>() });
			}
		}
	}

	clearAsyncRequests(): void {
		if (this._cancelRequest) this._cancelRequest();
		if (this._scheduledFetch) clearTimeout(this._scheduledFetch);
	}

	fetchData() {
		const { metric } = this.props;
		const { refreshInterval, cluster, node } = this.props;

		// const hostNames = traces.map((trace: any) => trace.name);

		const {
			influx_host: host,
			influx_port: port,
			influx_password: password,
			influx_protocol: protocol,
			influx_username: username,
			influx_db: db
		} = Config.getInstance();

		const url = `${protocol}://${host}:${port}/query`;

		const query = `SELECT LAST(${metric.name}), node, host, cluster FROM ${
			metric.table
		} WHERE cluster = '${cluster.name}'${
			(node && " AND node = '" + node.name + "'") || ""
		} AND  time > ${moment()
			.subtract(30, "second")
			.valueOf()}000000 GROUP BY "node"`;

		// console.log("query", query);

		const params = {
			q: query,
			db
		};

		Axios.get(url, {
			auth: {
				username,
				password
			},
			params,
			cancelToken: new Axios.CancelToken((c: any) => {
				this._cancelRequest = c;
			})
		})
			.then((response: AxiosResponse) => {
				// console.log("raw value response.data", response.data);

				if (!this._isMounted) return;

				let rawValues = new Map<string, number>();

				response.data.results.forEach((result: IResultEntry) => {
					// console.log("result entries", result);

					result.series?.forEach((series: IResponseSeries) => {
						// console.log("result values", series);

						const node = series.tags ? series.tags["node"] : "unknown";

						// console.log(
						// 	"node name",
						// 	node,
						// 	series.values,
						// 	series.values ? [0] : undefined,
						// 	series.values ? [0][1] : undefined
						// );
						// add value to map if exists
						series.values && rawValues.set(node, series.values[0][1]);
					});
				});

				// console.log("rawValues", rawValues);

				this.setState({ rawValues });
			})
			.catch((err: AxiosError) => {
				if (!Axios.isCancel(err)) {
					console.error(
						"Metrics error response:",
						err.message,
						err.response?.data
					);

					if (!this._isMounted) return;
					this.setState({
						rawValues: new Map<string, number>()
					});
				}
			})
			.finally(() => {
				this._cancelRequest = undefined;
				if (this._isMounted) {
					this._scheduledFetch = setTimeout(() => {
						this.fetchData();
					}, refreshInterval);
				}
			});
	}

	render():
		| React.ReactElement
		| string
		| number
		| {}
		| React.ReactNodeArray
		| React.ReactPortal
		| boolean
		| null
		| undefined {
		const { classes, traces, differential } = this.props;

		const { rawValues } = this.state;

		return (
			<div className={classes.root}>
				{traces.map((trace: any) => {
					// console.log("trace", trace, rawValues, rawValues.get(trace.name));

					const liveValue = isNaN(trace.y[trace.y.length - 1])
						? "-"
						: Math.round((trace.y[trace.y.length - 1] + Number.EPSILON) * 100) /
						  100;

					const rawValueRaw = rawValues.get(trace.name);
					const rawValue =
						rawValueRaw &&
						Math.round((rawValueRaw + Number.EPSILON) * 100) / 100;

					return (
						<div key={trace.name} style={{ color: trace.line.color }}>
							{liveValue}
							{differential &&
								` (${
									rawValue || "-"
									// rawValues?.find(
									// 	(result: any) => result.metric.gmd_host === trace.name
									// )?.value[1] || "-"
								})`}
						</div>
					);
				})}
			</div>
		);
	}
}

export default withStyles(styles, { withTheme: true })(LiveValueComponent);
