import * as React from "react";
import { User, USER_ROLE } from "components/users/types";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Step,
	StepLabel,
	Stepper,
	Typography
} from "@material-ui/core";
import HiddenJs from "@material-ui/core/Hidden/HiddenJs";
import { AppState } from "../../../AppState";
import { connect } from "react-redux";
import { NewUser } from "components/users/userCreateWizard/types";
import { userCreateWizardHide } from "components/users/userCreateWizard/actions";
import UserFormComponent from "components/users/UserFormComponent";
import UsersApi from "modules/api/UsersApi";
import { AxiosError } from "axios";
import { userListFetchRequested } from "components/users/actions";

interface LocalState {
	newUser: NewUser;
	activeStep: number;
	error?: string;
	isCreating: boolean;
}

interface LocalProps {}

interface ReduxStateProps {
	isOpen: boolean;
}

interface ReduxDispatchProps {
	hide: () => void;
	reloadUserList: () => void;
}

type Props = LocalProps & ReduxStateProps & ReduxDispatchProps;

class UserCreateWizardComponent extends React.Component<Props, LocalState> {
	DEFAULT_STATE = {
		activeStep: 0,
		isCreating: false,
		newUser: {
			name: "",
			password: "",
			role: USER_ROLE.NONE
		}
	};

	constructor(props: Props) {
		super(props);

		this.state = { ...this.DEFAULT_STATE };
	}

	onSubmit = (user: User | NewUser) => {
		console.log("onSubmit", user);

		this.setState({ isCreating: true });

		const newUser = user as NewUser;

		UsersApi.create(newUser).then(
			() => {
				this.setState({ isCreating: false, activeStep: 1 });
				this.props.reloadUserList();
			},
			(error: AxiosError) => {
				this.setState({
					isCreating: false,
					error: `Failed to create user: ${
						error.response ? error.response.data.wrapped.msg : error.message
					}`
				});
			}
		);
	};

	render():
		| React.ReactElement<any, string | React.JSXElementConstructor<any>>
		| string
		| number
		| {}
		| React.ReactNodeArray
		| React.ReactPortal
		| boolean
		| null
		| undefined {
		const { activeStep, newUser, error } = this.state;

		const { isOpen, hide } = this.props;

		const stepper = (
			<>
				<Stepper activeStep={activeStep}>
					<Step key={1}>
						<StepLabel>{"Enter user data"}</StepLabel>
					</Step>

					<Step key={2}>
						<StepLabel>{"Finish"}</StepLabel>
					</Step>
				</Stepper>
			</>
		);

		const wizardButtons = (
			<>
				{(activeStep === 0 && (
					<>
						<div style={{ flexGrow: 1 }} />
						<Button
							size="small"
							onClick={(): void => {
								hide();
								this.setState({ ...this.DEFAULT_STATE });
							}}
						>
							Cancel
						</Button>
						<Button color="primary" type="submit" form="userForm" size="small">
							Create
						</Button>
					</>
				)) ||
					(activeStep === 1 && (
						<>
							<div style={{ flexGrow: 1 }} />
							<Button
								size="small"
								onClick={() => {
									hide();
									this.setState({ ...this.DEFAULT_STATE });
								}}
							>
								Close
							</Button>
							<Button
								size="small"
								color="primary"
								onClick={() => {
									console.log("showUser", newUser);
									hide();
									this.setState({ ...this.DEFAULT_STATE });
								}}
							>
								Show user
							</Button>
							<Button
								size="small"
								color="primary"
								onClick={() => {
									this.setState({ ...this.DEFAULT_STATE });
								}}
							>
								Add another user
							</Button>
						</>
					))}
			</>
		);

		const userForm = (
			<>
				<UserFormComponent user={newUser} onSubmit={this.onSubmit} />
			</>
		);

		const userCreatedMessage = (
			<>
				<Typography>User successfully created.</Typography>
			</>
		);

		const dialogContent = (
			<>
				<DialogContent>
					{stepper}
					{error && (
						<Grid item>
							<Typography variant="subtitle2" color="error">
								{error}
							</Typography>
						</Grid>
					)}
					{(activeStep === 0 && userForm) ||
						(activeStep === 1 && userCreatedMessage)}
				</DialogContent>
				<DialogActions>{wizardButtons}</DialogActions>
			</>
		);

		return (
			<>
				{/*Mobile*/}
				<HiddenJs mdUp>
					<Dialog
						fullScreen={true}
						disableBackdropClick={true}
						disableEscapeKeyDown={true}
						open={isOpen}
					>
						<DialogTitle>Create new user</DialogTitle>
						{dialogContent}
					</Dialog>
				</HiddenJs>

				{/*Desktop*/}
				<HiddenJs smDown>
					<Dialog
						fullScreen={false}
						maxWidth="sm"
						fullWidth={true}
						disableBackdropClick={true}
						disableEscapeKeyDown={true}
						open={isOpen}
					>
						<DialogTitle>Create new user</DialogTitle>
						{dialogContent}
					</Dialog>
				</HiddenJs>
			</>
		);
	}
}

const mapGlobalStateToProps = (state: AppState) => ({
	isOpen: state.userCreateWizard.isUserCreateWizardOpen
});

const mapGlobalDispatchToProps = (dispatch: any) => {
	return {
		hide: () => {
			dispatch(userCreateWizardHide());
		},
		reloadUserList: () => {
			dispatch(userListFetchRequested());
		}
	};
};

export default connect(
	mapGlobalStateToProps,
	mapGlobalDispatchToProps
)(UserCreateWizardComponent);
