import React from "react";
import LogsApi from "../../modules/api/LogsApi";
import { Grid, Typography } from "@material-ui/core";
import { LogLine } from "../sharedComponents/logViewer/types";
import { styles } from "./styles";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import SystemLogMessage from "./SystemLogMessage";
import objectHash from "object-hash";

interface LocalState {
	logContent: LogLine[];
}

interface LocalProps {}

type Props = LocalProps & WithStyles<typeof styles>;

class SystemLogComponent extends React.PureComponent<Props, LocalState> {
	constructor(props: Props) {
		super(props);

		this.state = { logContent: [] };

		this.fetchLogFile();
	}

	async fetchLogFile() {
		const { data } = await LogsApi.fetchGMSystemLog();

		console.log("data", data);

		const splitLogs = data.data.attributes.contents.split("\n");
		this.setState((state: LocalState) => ({
			logContent: splitLogs.reduce((result: any, row: string) => {
				// console.log("raw line", row);

				try {
					const line = JSON.parse(row);

					result.push({
						time: line.time,
						message: line.msg,
						level: line.level,
						_logLineHash: objectHash(line)
					});
				} catch (e) {
					// console.warn("Parsing log line failed", e);
				}

				return result;
			}, [])
		}));
	}

	render() {
		const { logContent } = this.state;
		const { classes } = this.props;
		console.log("logContent", logContent);
		return (
			<>
				<Typography variant="h5">Galera Manager logs</Typography>
				<Grid item sm className={classes.logContent} id="log-content">
					{logContent.map((log: LogLine) => {
						return <SystemLogMessage key={log._logLineHash} logLine={log} />;
					})}
				</Grid>
			</>
		);
	}
}

export default withStyles(styles)(SystemLogComponent);
