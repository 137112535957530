import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { styles } from "./styles";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { HostLog } from "../../../modules/webSocketHandler/WSService";

interface LocalProps {
	logLine: HostLog;
}

type Props = LocalProps & WithStyles<typeof styles>;

class DeploymentLogMessage extends React.Component<Props, any> {
	componentDidUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<any>,
		snapshot?: any
	): void {
		console.log("componentDidUpdate");
	}

	render():
		| React.ReactElement<any, string | React.JSXElementConstructor<any>>
		| string
		| number
		| {}
		| React.ReactNodeArray
		| React.ReactPortal
		| boolean
		| null
		| undefined {
		const { logLine, classes } = this.props;

		return (
			<Grid data-cy="log-message" container direction="row">
				<Typography
					component="span"
					variant="body2"
					data-cy="live-log-message"
					classes={{ body2: classes.logMessagesTypography }}
					className={
						(!logLine.hasOwnProperty("channel-type") &&
							classes.galeraManagerLogMessage) ||
						(logLine["channel-type"] === "host-stderr" && classes.errorLog) ||
						""
					}
				>
					{`${moment(logLine.time).format("MMM DD, YYYY HH:mm:ss")} | ${
						logLine["channel-type"] || "galera-manager"
					} | ${logLine.msg}`}
				</Typography>
			</Grid>
		);
	}
}

export default withStyles(styles)(DeploymentLogMessage);
