import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
	createStyles({
		stateOk: {
			color: theme.palette.success.main
		},
		stateError: {
			color: theme.palette.error.main
		}
	});
