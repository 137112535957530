import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { LogLine } from "components/sharedComponents/logViewer/types";
import { styles } from "./styles";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import moment from "moment";

interface LocalProps {
	logLine: LogLine;
}

type Props = LocalProps & WithStyles<typeof styles>;

class SystemLogMessage extends React.Component<Props, any> {
	componentDidUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<any>,
		snapshot?: any
	): void {
		console.log("componentDidUpdate");
	}

	shouldComponentUpdate(
		nextProps: Readonly<Props>,
		nextState: Readonly<any>,
		nextContext: any
	): boolean {
		return this.props.logLine._logLineHash !== nextProps.logLine._logLineHash;
	}

	render():
		| React.ReactElement<any, string | React.JSXElementConstructor<any>>
		| string
		| number
		| {}
		| React.ReactNodeArray
		| React.ReactPortal
		| boolean
		| null
		| undefined {
		const { logLine, classes } = this.props;

		return (
			<Grid data-cy="log-message" container direction="row">
				<Typography
					variant="body2"
					classes={{ body2: classes.logMessagesTypography }}
					className={
						(logLine.level === "error" && classes.errorLog) ||
						(logLine.level === "warning" && classes.warningLog) ||
						""
					}
				>
					{moment(logLine.time).format("MMM DD, YYYY HH:mm:ss")} |&nbsp;
				</Typography>

				<Typography
					style={{ whiteSpace: "pre-wrap" }}
					variant="body2"
					classes={{ body2: classes.logMessagesTypography }}
					className={
						(logLine.level === "error" && classes.errorLog) ||
						(logLine.level === "warning" && classes.warningLog) ||
						""
					}
				>
					{logLine.level.padEnd(7, " ")} |&nbsp;
				</Typography>

				<Typography
					variant="body2"
					classes={{ body2: classes.logMessagesTypography }}
					className={
						(logLine.level === "error" && classes.errorLog) ||
						(logLine.level === "warning" && classes.warningLog) ||
						""
					}
				>
					{logLine.message}
				</Typography>
			</Grid>
		);
	}
}

export default withStyles(styles)(SystemLogMessage);
