import * as React from "react";
import { ReactElement } from "react";
import Axios, { AxiosError, AxiosResponse } from "axios";
import { Cluster } from "components/management/cluster/types";
import { Node } from "components/management/node/types";
import ChartUtils from "components/monitoring/charts/utils";
import PlotComponent from "modules/reactPlotlyPlot";
import { AGGREGATION } from "components/monitoring/dashboard/types";
import {
	ChartMetric,
	DEFAULT_AGGREGATION,
	DEFAULT_PERIOD,
	DEFAULT_REFRESH_INTERVAL
} from "components/monitoring/charts/const";
import { DEFAULT_COLORS } from "components/sharedComponents/logViewer/const";
import Config from "modules/config/Config";
import { IResponse } from "influx";
import { IResponseSeries, IResultEntry, Row } from "influx/lib/src/results";
import moment from "moment";
import LiveValueComponent from "components/monitoring/charts/timeSeries/liveValue/LiveValueComponent";
import { Grid, Typography } from "@material-ui/core";

interface LocalState {
	traces: any[];
	isLoading: boolean;
}

interface LocalProps {
	containerHeight?: number;
	containerWidth?: number;
	disableRender: boolean;
	refreshInterval?: number;
	resolution?: string;
	period?: string;
	title: string;
	metric: ChartMetric;
	cluster: Cluster;
	node?: Node;
	aggregation?: AGGREGATION;
	unit?: string;
	dataScaling?: number;
	yAxisMax?: number;
}

type Props = LocalProps;

interface Snapshot {
	shouldFetchAsyncData: boolean;
	shouldClearExistingData: boolean;
}

class TimeSeriesChartComponent extends React.Component<Props, LocalState> {
	_isMounted = false;
	_scheduledFetch: any;
	_cancelRequest: any;

	constructor(props: Props) {
		super(props);

		this.state = {
			traces: [],
			isLoading: true
		};
	}

	fetchData() {
		if (!this.props.metric) return;

		this.setState({ isLoading: true });

		const {
			metric,
			cluster,
			node,
			period = DEFAULT_PERIOD,
			// resolution = DEFAULT_RESOLUTION,
			aggregation = DEFAULT_AGGREGATION
		} = this.props;

		const {
			influx_host: host,
			influx_port: port,
			influx_password: password,
			influx_protocol: protocol,
			influx_username: username,
			influx_db: db
		} = Config.getInstance();

		const url = `${protocol}://${host}:${port}/query`;

		// console.log("cluster and node", cluster, node, metric);

		const unit = period[period.length - 1];
		const amount = period.substring(0, period.length - 1);

		let select: string;
		if (aggregation === AGGREGATION.RAW) {
			select = metric.name;
		} else {
			select = `derivative(${metric.name}, 1s)`;
		}

		const params = {
			q: `SELECT ${select} FROM ${metric.table} WHERE cluster = '${
				cluster.name
			}'${
				(node && " AND node = '" + node.name + "'") || ""
			} AND time > ${moment()
				.subtract(amount, unit as any)
				.valueOf()}000000 GROUP BY "node"`,
			db
		};

		Axios.get(url, {
			auth: {
				username,
				password
			},
			params,
			cancelToken: new Axios.CancelToken((c: any) => {
				this._cancelRequest = c;
			})
		})
			.then((response: AxiosResponse<IResponse>) => {
				// console.log("result", response.data);

				if (!this._isMounted) return;

				let traces: any[] = [];

				response.data.results.forEach((result: IResultEntry) => {
					// console.log("result entries", result);

					result.series?.forEach((series: IResponseSeries, index: number) => {
						// console.log("result values", series);

						const node = series.tags ? series.tags["node"] : "unknown";

						// console.log("node name", node);

						let trace: any = {
							line: {
								width: 1,
								color: DEFAULT_COLORS[index % 10]
							},
							type: "scatter",
							hoverinfo: "name+y+x",
							x: [],
							y: [],
							name: node
						};

						series.values?.forEach((row: Row) => {
							// trace.x.push(moment.unix(row[0]).toDate());
							// trace.y.push(row[1]);
							// console.log("row", row[0], new Date(row[0]), moment.unix(row[0]));
							trace.x.push(new Date(row[0]));
							if (this.props.dataScaling) {
								trace.y.push(row[1] * this.props.dataScaling);
							} else {
								trace.y.push(row[1]);
							}
						});

						traces.push(trace);
					});
				});

				// console.log("traces", traces);
				if (!this._isMounted) return;

				this.setState({
					traces,
					isLoading: false
				});
			})
			.catch((err: AxiosError) => {
				if (!Axios.isCancel(err)) {
					console.error(
						"Metrics error response:",
						err.message,
						err.response?.data
					);
				}
				this.setState({
					isLoading: false
				});
			})
			.finally(() => {
				this._cancelRequest = undefined;
				if (this._isMounted) {
					this._scheduledFetch = setTimeout(
						() => {
							this.fetchData();
						},
						this.props.refreshInterval
							? this.props.refreshInterval
							: DEFAULT_REFRESH_INTERVAL
					);
				}
			});
	}

	getSnapshotBeforeUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<LocalState>
	): any | null {
		return {
			shouldFetchAsyncData:
				prevProps.metric !== this.props.metric ||
				prevProps.refreshInterval !== this.props.refreshInterval ||
				prevProps.period !== this.props.period ||
				prevProps.resolution !== this.props.resolution ||
				prevProps.cluster !== this.props.cluster ||
				prevProps.node !== this.props.node ||
				prevProps.aggregation !== this.props.aggregation ||
				prevProps.aggregation !== this.props.aggregation ||
				prevProps.dataScaling !== this.props.dataScaling,
			shouldClearExistingData:
				prevProps.metric !== this.props.metric ||
				prevProps.cluster !== this.props.cluster ||
				prevProps.node !== this.props.node ||
				prevProps.aggregation !== this.props.aggregation ||
				prevProps.dataScaling !== this.props.dataScaling
		};
	}

	componentDidUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<LocalState>,
		snapshot?: Snapshot
	): void {
		// if metric, cluster or node changed - reload async data
		if (this._isMounted && snapshot) {
			if (snapshot.shouldFetchAsyncData) {
				this.clearAsyncRequests();
				this.fetchData();
			}

			if (snapshot.shouldClearExistingData) {
				this.setState({ traces: [] });
			}
		}
	}

	// shouldComponentUpdate(
	// 	nextProps: Readonly<Props>,
	// 	nextState: Readonly<LocalState>,
	// 	nextContext: any
	// ): boolean {
	// 	const didPropsChange =
	// 		nextProps.metric !== this.props.metric ||
	// 		nextProps.refreshInterval !== this.props.refreshInterval ||
	// 		nextProps.period !== this.props.period ||
	// 		nextProps.resolution !== this.props.resolution ||
	// 		nextProps.cluster !== this.props.cluster ||
	// 		nextProps.node !== this.props.node ||
	// 		nextProps.aggregation !== this.props.aggregation ||
	// 		nextProps.title !== this.props.title ||
	// 		nextProps.unit !== this.props.unit ||
	// 		nextProps.dataScaling !== this.props.dataScaling ||
	// 		nextProps.yAxisMax !== this.props.yAxisMax ||
	// 		nextState.isLoading !== this.state.isLoading;
	//
	// 	const didDataChange = this.state.traces !== nextState.traces;
	//
	// 	console.log(
	// 		"shouldComponentUpdate",
	// 		nextContext.disableRender,
	// 		didPropsChange,
	// 		didDataChange,
	// 		window.innerHeight,
	// 		window.innerWidth
	// 	);
	//
	// 	return true;
	//
	// 	if (nextProps.disableRender) {
	// 		return false;
	// 	} else {
	// 		return didDataChange || didPropsChange;
	// 	}
	// }

	componentDidMount(): void {
		this._isMounted = true;
		this.props.metric && this.fetchData();
	}

	componentWillUnmount(): void {
		this._isMounted = true;
		this.clearAsyncRequests();
	}

	clearAsyncRequests(): void {
		if (this._cancelRequest) this._cancelRequest();
		if (this._scheduledFetch) clearTimeout(this._scheduledFetch);
	}

	render(): ReactElement {
		const { traces, isLoading } = this.state;
		const {
			title,
			period,
			metric,
			aggregation,
			cluster,
			node,
			unit,
			yAxisMax
		} = this.props;

		const isThereAnyData = traces.length !== 0;

		const untypedConfigValues = {
			spikedistance: 200,
			hoverdistance: 200
		};

		const yAxisTitle = unit
			? aggregation === AGGREGATION.DIFFERENTIAL
				? `${unit}/s`
				: unit
			: "";

		return (
			<>
				{(!isThereAnyData && isLoading && (
					<Grid
						container
						alignContent="center"
						justify="center"
						style={{
							position: "absolute",
							top: "0",
							left: "0",
							right: "0",
							bottom: "0",
							backgroundColor: "rgba(255, 255, 255, 0.4)",
							zIndex: 1
						}}
					>
						<Grid item>
							<Typography color="primary">Loading</Typography>
						</Grid>
					</Grid>
				)) ||
					(!isThereAnyData && (
						<Grid
							container
							alignContent="center"
							justify="center"
							style={{
								position: "absolute",
								top: "0",
								left: "0",
								right: "0",
								bottom: "0",
								backgroundColor: "rgba(255, 255, 255, 0.4)",
								zIndex: 1
							}}
						>
							<Grid item>
								<Typography color="primary">No data</Typography>
							</Grid>
						</Grid>
					))}

				<LiveValueComponent
					traces={traces.sort(
						(trace1: any, trace2: any) =>
							trace2.y[trace2.y.length - 1] - trace1.y[trace1.y.length - 1]
					)}
					// hostNames={sortedTraces.map((trace: any) => trace.name)}
					metric={metric}
					cluster={cluster}
					node={node}
					// lastValues={sortedTraces.map(
					// 	(trace: any) => trace.y[trace.y.length - 1]
					// )}
					// colors={sortedTraces.map((trace: any) => trace.line.color)}
					differential={aggregation === AGGREGATION.DIFFERENTIAL}
					refreshInterval={
						this.props.refreshInterval
							? this.props.refreshInterval
							: DEFAULT_REFRESH_INTERVAL
					}
				/>
				<PlotComponent
					style={{ height: "100%", width: "100%" }}
					data={traces}
					layout={{
						...untypedConfigValues,
						font: {
							size: 10
						},
						autosize: true,
						title: {
							text: title,
							font: {
								size: 14
							}
						},
						margin: { t: 20, l: 30, r: 0, b: 30 },
						showlegend: false,
						yaxis: {
							type: "linear",
							rangemode: "tozero",
							fixedrange: true,
							title: yAxisTitle,
							range: [0, yAxisMax]
						},
						xaxis: {
							fixedrange: true,
							showspikes: true,
							spikemode: "across",
							spikedash: "solid",
							spikethickness: 1,
							spikecolor: "black",
							range:
								(period && ChartUtils.getDateRangeFromPeriod(period)) ||
								undefined
						}
					}}
					config={{ displayModeBar: false }}
				/>
			</>
		);
	}
}

export default TimeSeriesChartComponent;
