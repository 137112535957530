import { AGGREGATION } from "components/monitoring/dashboard/types";

export const DEFAULT_REFRESH_INTERVAL = 5000;
export const DEFAULT_PERIOD = "15m";
export const DEFAULT_RESOLUTION = "5s";
export const DEFAULT_AGGREGATION: AGGREGATION = AGGREGATION.RAW;

export interface ChartMetric {
	name: string;
	table: string;
}
