import Axios from "axios";
import AppEnvironment from "modules/appEnvironment";
import { NewUser } from "components/users/userCreateWizard/types";
import { SerializedUser, User } from "components/users/types";
import APIUtils from "modules/api/utils";
import { deattribute, serialise } from "kitsu-core";

const ENDPOINT = "users";

class UsersApi {
	static async getMe(): Promise<User> {
		console.log("getMe");
		try {
			const response = await Axios.get(`${APIUtils.getUrl("me")}`);
			console.log("me", deattribute(response.data.data) as User);

			return deattribute(response.data.data) as User;
		} catch (e) {
			throw new Error(APIUtils.APIErrorResponseHandler(e));
		}
	}

	static async fetchList(): Promise<User[]> {
		try {
			const response = await Axios.get(
				`${AppEnvironment.getApiAddress()}/${AppEnvironment.getApiVersion()}/users`
			);

			return response.data.data.map((sUser: SerializedUser) =>
				deattribute({ ...sUser })
			);
		} catch (e) {
			throw new Error(APIUtils.APIErrorResponseHandler(e));
		}
	}

	static async create(newUser: NewUser): Promise<User> {
		try {
			const data = serialise("users", newUser, "POST");

			const response = await Axios.post(APIUtils.getUrl(ENDPOINT), data);

			return deattribute(response.data.data) as User;
		} catch (e) {
			throw new Error(APIUtils.APIErrorResponseHandler(e));
		}
	}

	static async delete(user: User): Promise<void> {
		try {
			await Axios.delete(`${APIUtils.getUrl(ENDPOINT)}/${user.id}`);
		} catch (e) {
			throw new Error(APIUtils.APIErrorResponseHandler(e));
		}
	}
}

export default UsersApi;
