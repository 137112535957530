import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
	createStyles({
		doubleNested: {
			paddingLeft: theme.spacing(6)
		},
		pulsating: {
			animation: "$pulse 1.2s infinite ease-in-out"
		},
		"@keyframes pulse": {
			"0%": {
				opacity: 1
			},
			"50%": {
				opacity: 0.5
			},
			"100%": {
				opacity: 1
			}
		}
	});
