import { FirewallRule } from "components/management/host/types";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import { styles } from "./styles";
import {
	Avatar,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	List,
	ListItem,
	ListItemAvatar,
	ListItemSecondaryAction,
	ListItemText,
	ListSubheader,
	MenuItem,
	Select,
	TextField,
	Tooltip,
	WithTheme
} from "@material-ui/core";
import React, { ChangeEvent, FormEvent } from "react";
import { Delete, Security } from "@material-ui/icons";
import HiddenJs from "@material-ui/core/Hidden/HiddenJs";
import { IPAddressField } from "./IPAddressFieldComponent";
import isIp from "is-ip";

interface LocalState {
	isDialogOpen: boolean;
	isAddRuleDialogOpen: boolean;
	newRule: {
		rule: FirewallRule;
		descriptionErrorMessage: string;
		portErrorMessage: string;
		rangesErrorMessage: string;
		maskErrorMessage: string;
	};
}

interface LocalProps {
	firewallRules: FirewallRule[];
	// inheritedFirewallRules?: FirewallRule[];
	readOnly?: boolean;
	onRemove?: (rule: FirewallRule, index: number) => void;
	onAdd?: (rule: FirewallRule) => void;
}

type Props = LocalProps & WithStyles<typeof styles> & WithTheme;

class FirewallRulesEditorComponent extends React.PureComponent<
	Props,
	LocalState
> {
	defaultState: LocalState = {
		isDialogOpen: false,
		isAddRuleDialogOpen: false,
		newRule: {
			rule: {
				description: "",
				port: 3306,
				ranges: ["/32"],
				protocol: "tcp"
			},
			descriptionErrorMessage: "",
			portErrorMessage: "",
			rangesErrorMessage: "",
			maskErrorMessage: ""
		}
	};

	constructor(props: Props) {
		super(props);

		this.state = this.defaultState;
	}

	openDialog = () => {
		this.setState((state: LocalState) => ({
			...state,
			isDialogOpen: true
		}));
	};

	closeDialog = () => {
		this.setState({ isDialogOpen: false });
	};

	closeAddRuleDialog = () => {
		this.setState({
			isAddRuleDialogOpen: false,
			newRule: { ...this.defaultState.newRule }
		});
	};

	addRule = (newRule: FirewallRule) => {
		this.props.onAdd && this.props.onAdd(newRule);
	};

	render() {
		const { classes, firewallRules, readOnly, theme } = this.props;
		const { isDialogOpen, isAddRuleDialogOpen, newRule } = this.state;

		const ListItemComponent = ListItem as any; // temporary workaround because of bug in TS/MUI: https://github.com/mui-org/material-ui/issues/14971

		let rulesCount = firewallRules?.length || 0;

		// check if there are custom rules for ports 22, 3306 and 33060
		const isThereCustom22 = firewallRules?.some(
			(rule: FirewallRule) => rule.port === 22
		);
		const isThereCustom3306 = firewallRules?.some(
			(rule: FirewallRule) => rule.port === 3306
		);
		const isThereCustom33060 = firewallRules?.some(
			(rule: FirewallRule) => rule.port === 33060
		);

		// increase counter if there are no custom rules for those ports
		// in that case we need to count default rules
		!isThereCustom22 && rulesCount++;
		!isThereCustom3306 && rulesCount++;
		!isThereCustom33060 && rulesCount++;

		const renderDialog = () => (
			<>
				{/*Mobile*/}
				<HiddenJs smUp>
					<Dialog
						open={isDialogOpen}
						onClose={() => {}}
						fullWidth={true}
						fullScreen={true}
						disableBackdropClick={true}
						aria-labelledby="form-dialog-title"
					>
						{renderDialogContent()}

						<Dialog
							open={isAddRuleDialogOpen}
							onClose={() => {}}
							fullWidth={true}
							fullScreen={true}
							disableBackdropClick={true}
							aria-labelledby="form-dialog-title"
						>
							{renderAddNewRuleDialog()}
						</Dialog>
					</Dialog>
				</HiddenJs>

				{/*Desktop*/}
				<HiddenJs xsDown>
					<Dialog
						open={isDialogOpen}
						onClose={() => {}}
						fullWidth={true}
						maxWidth={"sm"}
						disableBackdropClick={true}
						onEscapeKeyDown={this.closeDialog}
						aria-labelledby="form-dialog-title"
					>
						{renderDialogContent()}

						<Dialog
							open={isAddRuleDialogOpen}
							onClose={() => {}}
							fullWidth={true}
							maxWidth={"sm"}
							disableBackdropClick={true}
							onEscapeKeyDown={this.closeAddRuleDialog}
							aria-labelledby="form-dialog-title"
						>
							{renderAddNewRuleDialog()}
						</Dialog>
					</Dialog>
				</HiddenJs>
			</>
		);

		const renderDialogContent = () => (
			<>
				<DialogTitle id="form-dialog-title">Remote access control</DialogTitle>
				<DialogContent data-testid="firewall-rules-dialog-content">
					<DialogContentText variant={"body2"}>
						Rules below are used to allow access to the nodes. Rules will be
						applied using EC2 Security Groups, and will be applied to all nodes
						in the cluster.
					</DialogContentText>
					<List
						data-testid="base-list"
						className={classes.root}
						subheader={
							<ListSubheader
								component="div"
								data-testid="base-list-subheader"
								id="nested-list-subheader"
							>
								Base rules
							</ListSubheader>
						}
					>
						<ListItemComponent data-testid="base-rule" key={"ssh-access-rule"}>
							<ListItemAvatar>
								<Avatar
									style={{
										backgroundColor: theme.palette.primary.main
									}}
								>
									<Security />
								</Avatar>
							</ListItemAvatar>
							<ListItemText
								primary={"Allow Galera Manager SSH access"}
								secondary={`Allow access via TCP from Galera Manager Server on port 22`}
							/>
						</ListItemComponent>
						<ListItemComponent
							data-testid="base-rule"
							key={"inter-node-communication-rule"}
						>
							<ListItemAvatar>
								<Avatar
									style={{
										backgroundColor: theme.palette.primary.main
									}}
								>
									<Security />
								</Avatar>
							</ListItemAvatar>
							<ListItemText
								primary={"Allow inter-node communication"}
								secondary={`Allow access via TCP between all nodes in a cluster on ports 3306, 33060, 4444, 4567, 4568`}
							/>
						</ListItemComponent>
					</List>
					<List
						data-testid="allow-list"
						className={classes.root}
						subheader={
							<ListSubheader
								component="div"
								id="nested-list-subheader"
								data-testid="allow-list-subheader"
							>
								Allow list
							</ListSubheader>
						}
					>
						{/*Don't render if read only and has custom rule*/}
						{!readOnly || (readOnly && !isThereCustom3306) ? (
							<ListItemComponent
								data-testid="default-rule"
								key={"default-mysql-access-rule"}
							>
								<ListItemAvatar>
									<Avatar
										className={
											isThereCustom3306 ? undefined : classes.activeRuleAvatar
										}
									>
										<Security />
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary={`Allow MySQL access from anywhere${
										!readOnly
											? isThereCustom3306
												? " (overridden by custom rule)"
												: " (can be overridden by custom rule)"
											: ""
									}`}
									secondary={`Allow access via TCP from 0.0.0.0/0 on port 3306`}
								/>
							</ListItemComponent>
						) : (
							<></>
						)}

						{/*Don't render if read only and has custom rule*/}
						{!readOnly || (readOnly && !isThereCustom33060) ? (
							<ListItemComponent
								data-testid="default-rule"
								key={"default-mysql-x-communication-rule"}
							>
								<ListItemAvatar>
									<Avatar
										className={
											isThereCustom33060 ? undefined : classes.activeRuleAvatar
										}
									>
										<Security />
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary={`Allow MySQL X access from anywhere${
										!readOnly
											? isThereCustom33060
												? " (overridden by custom rule)"
												: " (can be overridden by custom rule)"
											: ""
									}`}
									secondary={`Allow access via TCP from 0.0.0.0/0 on port 33060`}
								/>
							</ListItemComponent>
						) : (
							<></>
						)}
						{/*Don't render if read only and has custom rule*/}
						{!readOnly || (readOnly && !isThereCustom22) ? (
							<ListItemComponent
								data-testid="default-rule"
								key={"default-ssh-communication-rule"}
							>
								<ListItemAvatar>
									<Avatar
										className={
											isThereCustom22 ? undefined : classes.activeRuleAvatar
										}
									>
										<Security />
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary={`Allow SSH access from anywhere${
										!readOnly
											? isThereCustom22
												? " (overridden by custom rule)"
												: " (can be overridden by custom rule)"
											: ""
									}`}
									secondary={`Allow access via TCP from 0.0.0.0/0 on port 22`}
								/>
							</ListItemComponent>
						) : (
							<></>
						)}
						{firewallRules?.map((rule: FirewallRule, index) => {
							return renderRuleItem(rule, index);
						})}
					</List>
					{!readOnly && (
						<Button
							data-testid="add-custom-rule-button"
							variant="contained"
							color="primary"
							onClick={() => {
								this.setState({ isAddRuleDialogOpen: true });
							}}
						>
							Add custom rule
						</Button>
					)}
				</DialogContent>
				<DialogActions>
					<Button
						data-cy="firewall-rules-dialog-close-button"
						onClick={(): void => {
							this.closeDialog();
						}}
					>
						Close
					</Button>
				</DialogActions>
			</>
		);

		const renderRuleItem = (
			rule: FirewallRule,
			index: number,
			inherited = false
		) => {
			return (
				<ListItemComponent data-testid="custom-rule" key={rule.description}>
					<ListItemAvatar>
						<Avatar
							style={{
								backgroundColor: theme.palette.primary.main
							}}
						>
							<Security />
						</Avatar>
					</ListItemAvatar>
					<ListItemText
						primary={rule.description}
						secondary={`Allow access via ${rule.protocol.toUpperCase()} from ${rule.ranges.join(
							", "
						)} on port ${rule.port} ${
							inherited ? "(Inherited from cluster)" : ""
						}`}
					/>
					{!inherited && !readOnly && this.props.onRemove && (
						<ListItemSecondaryAction>
							<Tooltip title={"Remove rule"}>
								<IconButton
									onClick={(): void => {
										this.props.onRemove && this.props.onRemove(rule, index);
									}}
									edge="end"
									aria-label="Delete"
								>
									<Delete />
								</IconButton>
							</Tooltip>
						</ListItemSecondaryAction>
					)}
				</ListItemComponent>
			);
		};

		const renderAddNewRuleDialog = () => (
			<form
				id="newRuleForm"
				onSubmit={(e: FormEvent) => {
					// console.log("onSubmit", e);
					e.preventDefault();
					e.stopPropagation();

					const rule: FirewallRule = {
						...this.state.newRule.rule,
						ranges: this.state.newRule.rule.ranges.map((ip: string) => {
							return ip.replace(/_/g, "");
						})
					};

					// validate IP addresses
					if (!isIp(rule.ranges[0].split("/")[0])) {
						this.setState(
							(state: LocalState): LocalState => ({
								...state,
								newRule: {
									...state.newRule,
									rangesErrorMessage: "Please enter valid IPv4 address"
								}
							})
						);
						return;
					}

					this.addRule(rule);
					this.closeAddRuleDialog();
				}}
			>
				<DialogTitle id="form-dialog-title">
					Add remote access control rule
				</DialogTitle>
				<DialogContent data-testid="add-custom-rule-dialog">
					<Grid container direction="column">
						<Grid container item direction="row">
							<Grid item sm={12} xs={12}>
								<FormControl
									fullWidth
									required
									onInvalid={(e: FormEvent): void => {
										e.preventDefault();
										const form = e.target as HTMLFormElement;

										this.setState((state: LocalState) => ({
											...state,
											newRule: {
												...state.newRule,
												descriptionErrorMessage: form.validationMessage
											}
										}));
									}}
								>
									<TextField
										required
										autoFocus
										error={newRule.descriptionErrorMessage !== ""}
										helperText={newRule.descriptionErrorMessage}
										multiline={false}
										margin="dense"
										id="description"
										label="Rule description"
										type="text"
										fullWidth
										variant="outlined"
										value={newRule.rule.description}
										onChange={(e: ChangeEvent<HTMLInputElement>) => {
											e.persist();
											const field = e.target;
											const description = field.value;
											// console.log("onDescriptionChange", description);

											this.setState((state: LocalState) => ({
												...state,
												newRule: {
													...state.newRule,
													rule: {
														...state.newRule.rule,
														description: description
													},
													descriptionErrorMessage: ""
												}
											}));

											if (
												firewallRules.some(
													(rule: FirewallRule) =>
														rule.description === description
												)
											) {
												field.setCustomValidity(
													"Rule exists with this description. Please enter another description."
												);
											} else {
												field.setCustomValidity("");
											}
										}}
										inputProps={{
											"data-cy": "firewall-rule-description",
											"data-testid": "rule-description-text-field",
											style: { fontFamily: "monospace" },
											spellCheck: false,
											autoComplete: "off",
											autoCorrect: "off",
											autoCapitalize: "off",
											maxLength: 80
										}}
									/>
								</FormControl>
							</Grid>
						</Grid>
						<Grid container item direction="row">
							<Grid item sm={2} xs={12}>
								<FormControl required variant="outlined">
									<InputLabel id="protocol-label">Protocol</InputLabel>
									<Select
										required
										margin="dense"
										data-cy="add-firewall-rule-protocol-select-container"
										labelId="protocol-label"
										label="Protocol"
										value={newRule.rule.protocol}
										onChange={(e) => {
											const value = e.target.value as string;

											if (value === "tcp") {
												this.setState((state: LocalState) => ({
													...state,
													newRule: {
														...state.newRule,
														rule: {
															...state.newRule.rule,
															protocol: "tcp"
														}
													}
												}));
											} else {
												this.setState((state: LocalState) => ({
													...state,
													newRule: {
														...state.newRule,
														rule: {
															...state.newRule.rule,
															protocol: "udp"
														}
													}
												}));
											}
										}}
										inputProps={{
											id: "add-firewall-rule-protocol",
											"data-cy": "add-firewall-rule-protocol-select"
										}}
									>
										<MenuItem key={"tcp"} value={"tcp"}>
											TCP
										</MenuItem>
										<MenuItem key={"udp"} value={"udp"}>
											UDP
										</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item sm={3} xs={12}>
								<FormControl
									margin="none"
									fullWidth
									required
									onInvalid={(e: FormEvent): void => {
										e.preventDefault();
										const form = e.target as HTMLFormElement;

										this.setState((state: LocalState) => ({
											...state,
											newRule: {
												...state.newRule,
												portErrorMessage: form.validationMessage
											}
										}));
									}}
								>
									<TextField
										required
										error={newRule.portErrorMessage !== ""}
										helperText={newRule.portErrorMessage}
										multiline={false}
										id="firewall-rule-port"
										type="number"
										label="On port"
										fullWidth
										variant="outlined"
										value={newRule.rule.port}
										onChange={(e: ChangeEvent<HTMLInputElement>) => {
											e.persist();
											const field = e.target;
											const port = parseInt(field.value);
											// console.log("onPortChange", port);
											this.setState((state: LocalState) => ({
												...state,
												newRule: {
													...state.newRule,
													rule: {
														...state.newRule.rule,
														port: port
													},
													portErrorMessage: ""
												}
											}));
										}}
										inputProps={{
											"data-cy": "add-firewall-rule-port",
											"data-testid": "rule-port-text-field",
											style: { fontFamily: "monospace" },
											min: 1,
											max: 65535
										}}
									/>
								</FormControl>
							</Grid>
						</Grid>
						{newRule.rule.ranges.map((cidr: string, index: number) => {
							const ip = cidr.split("/")[0];
							const mask = `${cidr.split("/")[1]}`;

							return (
								<Grid key={index} container item direction="row">
									<Grid item sm={8} xs={8}>
										<FormControl
											fullWidth
											required
											onInvalid={(e: FormEvent): void => {
												e.preventDefault();
												const form = e.target as HTMLFormElement;

												this.setState((state: LocalState) => ({
													...state,
													newRule: {
														...state.newRule,
														rangesErrorMessage: form.validationMessage
													}
												}));
											}}
										>
											<TextField
												required
												error={newRule.rangesErrorMessage !== ""}
												helperText={newRule.rangesErrorMessage}
												margin="dense"
												id="ranges"
												label="From IP address:"
												fullWidth
												variant="outlined"
												value={ip}
												onChange={(e: ChangeEvent<HTMLInputElement>) => {
													e.persist();
													const field = e.target;
													const newIP = field.value;
													// console.log("onRangesChange", ranges);

													this.setState((state: LocalState) => ({
														...state,
														newRule: {
															...state.newRule,
															rule: {
																...state.newRule.rule,
																ranges: [`${newIP}/${mask}`]
															},
															rangesErrorMessage: ""
														}
													}));
												}}
												inputProps={{
													"data-cy": "firewall-rule-ranges",
													"data-testid": `rule-ip-text-field-${index}`,
													style: { fontFamily: "monospace" }
												}}
												InputProps={{
													inputComponent: IPAddressField as any
												}}
											/>
										</FormControl>
									</Grid>
									<Grid item sm={4} xs={4}>
										<Tooltip
											title={
												"If not sure, leave 32. It matches exact IP address entered. For more advanced use research subnetting or CIDR."
											}
										>
											<FormControl
												margin="dense"
												variant="outlined"
												fullWidth
												required
												onInvalid={(e: FormEvent): void => {
													e.preventDefault();
													const form = e.target as HTMLFormElement;

													this.setState((state: LocalState) => ({
														...state,
														newRule: {
															...state.newRule,
															maskErrorMessage: form.validationMessage
														}
													}));
												}}
											>
												<TextField
													required
													type="number"
													error={newRule.maskErrorMessage !== ""}
													helperText={newRule.maskErrorMessage}
													margin="dense"
													id="mask"
													label="Mask:"
													fullWidth
													variant="outlined"
													value={mask}
													onChange={(e: ChangeEvent<HTMLInputElement>) => {
														e.persist();
														const field = e.target;
														const newMask = field.value;
														// console.log("onRangesChange", ranges);

														this.setState((state: LocalState) => ({
															...state,
															newRule: {
																...state.newRule,
																rule: {
																	...state.newRule.rule,
																	ranges: [`${ip}/${newMask}`]
																},
																maskErrorMessage: ""
															}
														}));
													}}
													inputProps={{
														"data-cy": `rule-mask-text-field-${index}`,
														"data-testid": `rule-mask-text-field-${index}`,
														style: { fontFamily: "monospace" },
														readOnly: readOnly,
														min: 0,
														max: 32
													}}
												/>
											</FormControl>
										</Tooltip>
									</Grid>
								</Grid>
							);
						})}
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={(): void => {
							this.closeAddRuleDialog();
						}}
					>
						Close
					</Button>
					<Button
						form="newRuleForm"
						type="submit"
						data-testid="rule-add-button"
						color="primary"
					>
						Add
					</Button>
				</DialogActions>
			</form>
		);

		return (
			<>
				<List className={classes.root}>
					<ListItemComponent
						data-testid="firewall-rules-component"
						data-cy="firewall-rules-component"
						button={true}
						onClick={this.openDialog}
					>
						<ListItemAvatar>
							<Avatar style={{ backgroundColor: theme.palette.primary.main }}>
								<Security />
							</Avatar>
						</ListItemAvatar>
						<ListItemText
							primary="Remote access control"
							secondary={`2 base rules, ${rulesCount} custom rules`}
						/>
					</ListItemComponent>
				</List>
				{renderDialog()}
			</>
		);
	}
}

export default withStyles(styles, { withTheme: true })(
	FirewallRulesEditorComponent
);
