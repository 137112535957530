import Axios, { AxiosResponse } from "axios";
import { Dashboard } from "components/monitoring/dashboard/types";
import APIUtils from "modules/api/utils";
import { deattribute, serialise } from "kitsu-core";
import { User } from "components/users/types";
import Config from "modules/config/Config";

let dashboardMap: Map<string, Dashboard> = new Map<string, Dashboard>();

class DashboardsApi {
	static async fetchList(): Promise<AxiosResponse> {
		const currentUserID = Config.getInstance().currentUserID;
		const response = await Axios.get(APIUtils.getUrl(`users/${currentUserID}`));

		const user = deattribute(response.data.data) as User;

		console.log("current user data", user);

		if (user.meta?.dashboards) {
			dashboardMap = new Map<string, Dashboard>(
				Object.entries(user.meta?.dashboards)
			);
		} else {
			dashboardMap = new Map<string, Dashboard>();
		}

		console.log("dashboards fetch", dashboardMap);
		return response;
	}

	static fetch(name: string): Dashboard | undefined {
		// return dashboards.find((dashboard: Dashboard) => {
		// 	return dashboard.name === name;
		// });
		return dashboardMap.get(name);
	}

	static async save(dashboard: Dashboard): Promise<AxiosResponse> {
		console.log("save dash", dashboard);
		dashboardMap.set(dashboard.name, dashboard);

		const currentUserID = Config.getInstance().currentUserID;
		const response = await Axios.get(APIUtils.getUrl(`users/${currentUserID}`));

		let currentUser = deattribute(response.data.data) as User;

		currentUser = {
			...currentUser,
			meta: {
				...currentUser.meta,
				dashboards: Object.fromEntries(dashboardMap.entries())
			}
		};

		console.log("update user meta", currentUser);
		const serializedUser = serialise("users", currentUser);
		console.log("serialized user update", serializedUser);
		// update local store
		// let dashboardExists = false;
		// dashboards = dashboards.map((storedDashboard: Dashboard) => {
		// 	if (storedDashboard.name === dashboard.name) {
		// 		dashboardExists = true;
		// 		return dashboard;
		// 	} else return storedDashboard;
		// });
		//
		// if (!dashboardExists) dashboards.push(dashboard);

		return Axios.put(APIUtils.getUrl(`users/${currentUserID}`), serializedUser);
	}

	// static async delete(dashboard: Dashboard): Promise<AxiosResponse> {
	// 	return await Axios.delete(`${APIUtils.getUrl("users")}/${dashboard.name}`);
	// }
}

export default DashboardsApi;
