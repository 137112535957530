export interface User {
	id: number;
	name: string;
	password?: string;
	role: USER_ROLE;
	publicKey?: string;
	meta?: any;
}

export interface SerializedUser {
	attributes: User;
	id?: number;
	type: "users";
}

export enum USER_ROLE {
	NONE = "ROLE_NONE",
	OBSERVER = "ROLE_OBSERVER",
	MANAGER = "ROLE_MANAGER",
	ADMINISTRATOR = "ROLE_ADMINISTRATOR"
}

// redux actions
export enum USER_ACTION {
	LIST_FETCH_REQUESTED = "USER/LIST_FETCH_REQUESTED",
	LIST_FETCH_SUCCEEDED = "USER/LIST_FETCH_SUCCEEDED"
}

export interface UserListFetchRequestedAction {
	type: USER_ACTION.LIST_FETCH_REQUESTED;
}

export interface UserListFetchSucceededAction {
	type: USER_ACTION.LIST_FETCH_SUCCEEDED;
	payload: {
		userList: User[];
	};
}

export type UserAction =
	| UserListFetchRequestedAction
	| UserListFetchSucceededAction;
