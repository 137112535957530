export enum SNACKBAR_TYPE {
	ERROR,
	WARNING,
	INFO,
	SUCCESS,
	DEFAULT
}

// redux store state
export interface SnackbarState {
	msg: string;
	snackbarType: SNACKBAR_TYPE;
	open: boolean;
}

// redux actions
export interface SnackbarActionPayload {
	msg: string;
	snackbarType?: SNACKBAR_TYPE;
}

export enum SNACKBAR_ACTION {
	SHOW = "SNACKBAR/SHOW",
	HIDE = "SNACKBAR/HIDE"
}

export interface SnackbarActionShow {
	type: SNACKBAR_ACTION.SHOW;
	snackbar: SnackbarActionPayload;
}

export interface SnackbarActionHide {
	type: SNACKBAR_ACTION.HIDE;
}

export type SnackBarAction = SnackbarActionShow | SnackbarActionHide;
