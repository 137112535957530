import * as React from "react";
import { ChangeEvent, FormEvent } from "react";
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Tooltip,
	Typography
} from "@material-ui/core";
import { Node, NODE_DB_ENGINE } from "components/management/node/types";
import {
	EC2SpecificSettings,
	Host,
	HOST_SYSTEM,
	HOST_TYPE
} from "components/management/host/types";
import PrivateKeyEditor from "components/sharedComponents/privateKeyEditor/PrivateKeyEditorComponent";
import AuthorizedKeysEditor from "components/sharedComponents/authorizedKeysEditor/AuthorizedKeysEditorComponent";
import { Cluster } from "components/management/cluster/types";
import { RouteComponentProps, StaticContext, withRouter } from "react-router";
import NodeUtils from "components/management/node/utils";
import HostUtils from "components/management/host/utils";
import PasswordField from "components/sharedComponents/passwordField/passwordFieldComponent";
import { AppState } from "AppState";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import DbEngineConfigButtonComponent from "components/sharedComponents/dbEngineConfigEditor/DbEngineConfigButtonComponent";
import { styles } from "./styles";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import EC2ConfigComponent from "components/sharedComponents/ec2Config/EC2ConfigComponent";
import FirewallRulesEditorComponent from "../../../sharedComponents/firewallRulesEditor/FirewallRulesEditorComponent";

interface LocalState {
	formValidation: {
		nodeName: {
			invalid: boolean;
			message: string;
		};
		hostName: {
			invalid: boolean;
			message: string;
		};
		segment: {
			invalid: boolean;
			message: string;
		};
	};
	supportedSystems: HOST_SYSTEM[];
	isNodeNameLinkEnabled: boolean;
}

interface LocalProps {
	cluster?: Cluster;
	node?: Node;
	host: Host;
	readOnly?: boolean;
	onNodeChange?: (node: Node) => void;
	onHostChange?: (host: Host) => void;
	onSubmit?: () => void;
	multipleDeploymentMode?: boolean;
	onToggleGeneralLog?: () => void;
}

// PROPS
interface ReduxStateProps {
	nodeNames: string[];
	hostNames: string[];
}

type Props = LocalProps &
	ReduxStateProps &
	WithStyles<typeof styles> &
	RouteComponentProps<any, StaticContext, any>;

class NodeFormComponent extends React.Component<Props, LocalState> {
	constructor(props: Props) {
		super(props);

		this.state = {
			formValidation: {
				nodeName: {
					invalid: false,
					message: ""
				},
				hostName: {
					invalid: false,
					message: ""
				},
				segment: {
					invalid: false,
					message: ""
				}
			},
			supportedSystems: HostUtils.getSupportedHostSystems(
				props.node?.dbEngine ||
					// todo props.cluster?.nodeDefaults.dbEngine ||
					NODE_DB_ENGINE.MARIADB_10_3
			),
			isNodeNameLinkEnabled: true
		};
	}

	onSubmit = (e: FormEvent) => {
		e.preventDefault();
		// console.log("onSubmit", e);
		if (this.props.readOnly !== false && this.props.onSubmit) {
			this.props.onSubmit();
		}
	};

	onHostNameChange = (e: ChangeEvent) => {
		const {
			onHostChange,
			onNodeChange,
			multipleDeploymentMode,
			hostNames,
			host,
			node
		} = this.props;

		const field = e.target as HTMLFormElement;

		onHostChange && onHostChange({ ...host, name: field.value as string });
		!this.state.isNodeNameLinkEnabled &&
			node &&
			onNodeChange &&
			onNodeChange({ ...node, hostID: field.value });

		!multipleDeploymentMode && hostNames.includes(field.value)
			? field.setCustomValidity("Host name already in use")
			: field.setCustomValidity("");

		const isValid = field.checkValidity();

		this.setState((state: LocalState) => ({
			formValidation: {
				...state.formValidation,
				hostName: {
					...state.formValidation.hostName,
					invalid: !isValid,
					message: isValid ? "" : field.validationMessage
				}
			}
		}));
	};

	setHostName(hostName: string) {
		const { onHostChange, host } = this.props;

		onHostChange && onHostChange({ ...host, name: hostName });
		// node && onNodeChange && onNodeChange({ ...node, hostID: hostName });
	}

	render() {
		const {
			cluster,
			node,
			host,
			multipleDeploymentMode,
			onHostChange,
			onNodeChange,
			readOnly,
			nodeNames
		} = this.props;

		const {
			supportedSystems,
			isNodeNameLinkEnabled,
			formValidation
		} = this.state;

		return (
			<>
				<form id="nodeForm" onSubmit={this.onSubmit}>
					{node && (
						<Grid container item direction="column">
							<Grid item>
								<Typography variant="subtitle1">Node configuration</Typography>
							</Grid>
							<Grid container item direction="row" spacing={2}>
								<Grid item sm={6} xs={12}>
									<FormControl
										onInvalid={(e: FormEvent): void => {
											e.preventDefault();
											const form = e.target as HTMLFormElement;

											this.setState((state: LocalState) => ({
												formValidation: {
													...state.formValidation,
													nodeName: {
														...state.formValidation.nodeName,
														invalid: true,
														message: form.validationMessage
													}
												}
											}));
										}}
										error={formValidation.nodeName.invalid}
										fullWidth={true}
										required
									>
										<TextField
											error={formValidation.nodeName.invalid}
											margin="dense"
											required
											label={
												multipleDeploymentMode
													? "Node name prefix"
													: "Node name"
											}
											autoFocus={!readOnly}
											inputProps={{
												readOnly: readOnly,
												minLength: 3,
												maxLength: 20,
												"data-cy": "node-name"
											}}
											autoComplete="off"
											value={node.name}
											helperText={formValidation.nodeName.message}
											onChange={(e: ChangeEvent) => {
												const field = e.target as HTMLFormElement;

												isNodeNameLinkEnabled && this.onHostNameChange(e);

												onNodeChange &&
													onNodeChange({
														...node,
														name: field.value,
														hostID: isNodeNameLinkEnabled
															? field.value
															: node?.hostID
													});

												!multipleDeploymentMode &&
												nodeNames.includes(field.value)
													? field.setCustomValidity("Node name already in use")
													: field.setCustomValidity("");

												const isValid = field.checkValidity();

												this.setState((state: LocalState) => ({
													formValidation: {
														...state.formValidation,
														nodeName: {
															...state.formValidation.nodeName,
															invalid: !isValid,
															message: isValid ? "" : field.validationMessage
														}
													}
												}));
											}}
										/>
									</FormControl>
								</Grid>
								<Grid item sm={6} xs={12}>
									<Tooltip title="Set on a cluster level. Cannot be changed.">
										<FormControl fullWidth={true} margin="normal">
											<InputLabel htmlFor="node-db-engine">
												Node DB Engine
											</InputLabel>
											<Select
												data-cy="node-db-engine-select-container"
												fullWidth={true}
												disabled={true}
												value={node.dbEngine}
												onChange={(e) => {
													const value = e.target.value as string;

													onNodeChange &&
														onNodeChange({
															...node,
															dbEngine: NodeUtils.getNodeDbEngineEnum(value)
														});
												}}
												inputProps={{
													id: "node-db-engine",
													"data-cy": "node-db-engine-select"
												}}
											>
												{Object.values(NODE_DB_ENGINE).map(
													(db_engine: string) => (
														<MenuItem key={db_engine} value={db_engine}>
															{db_engine}
														</MenuItem>
													)
												)}
											</Select>
										</FormControl>
									</Tooltip>
								</Grid>
							</Grid>
							{(readOnly || host.type === HOST_TYPE.UNMANAGED) && (
								<>
									<Grid container direction="row" spacing={2}>
										<Grid item sm={6} xs={12}>
											<TextField
												fullWidth={true}
												margin="dense"
												label="DB Address"
												value={host && host.db && host.db.address}
												inputProps={{ readOnly }}
												onChange={(e: ChangeEvent) => {
													const field = e.target as HTMLFormElement;
													const address = field.value as string;

													onHostChange &&
														onHostChange({
															...host,
															db: {
																address,
																port: host.db?.port || "3306"
															}
														});
												}}
											/>
										</Grid>
										<Grid item sm={6} xs={12}>
											<TextField
												fullWidth={true}
												margin="dense"
												label="DB Port"
												value={host && host.db && host.db.port}
												inputProps={{ readOnly, min: 0, max: 65535 }}
												onChange={(e: ChangeEvent) => {
													const field = e.target as HTMLFormElement;
													const port = field.value as string;

													onHostChange &&
														onHostChange({
															...host,
															db: {
																address: host.db?.address || "",
																port
															}
														});
												}}
											/>
										</Grid>
									</Grid>
								</>
							)}
							<Grid container item direction="row" spacing={2}>
								{readOnly && (
									<Grid item sm={6} xs={12}>
										<PasswordField
											value={
												cluster ? cluster.sharedConfig.node.rootPassword : ""
											}
											label="DB Root Password"
										/>
									</Grid>
								)}

								<Grid item sm={6} xs={12}>
									{host.type !== HOST_TYPE.UNMANAGED && (
										<DbEngineConfigButtonComponent
											config={node.userConfig}
											// todo inheritedConfig={cluster?.nodeDefaults.userConfig}
											inheritedConfig={""}
											readOnly={readOnly || false}
											onChange={(config: string) => {
												node &&
													onNodeChange &&
													onNodeChange({ ...node, userConfig: config });
											}}
											subtitleText={
												readOnly
													? "This is currently active custom DB engine configuration"
													: "Enter custom DB engine configuration that will be applied to this node"
											}
										/>
									)}
								</Grid>
							</Grid>
							{readOnly && (
								<Grid container item direction="row" spacing={2}>
									<Grid item sm={6} xs={12}>
										<Tooltip
											title={`Click to ${
												node.settings.logs.generalLog.enabled
													? "disable"
													: "enable"
											} general log tracking`}
										>
											<FormControlLabel
												control={
													<Checkbox
														data-cy="toggle-general-log"
														checked={node.settings.logs.generalLog.enabled}
														onChange={() => {
															this.props.onToggleGeneralLog &&
																this.props.onToggleGeneralLog();
														}}
														value="startNode"
														color="primary"
													/>
												}
												label={
													"General log (consumes large amounts of disk space on GMD host over time)"
												}
											/>
										</Tooltip>
									</Grid>
								</Grid>
							)}
						</Grid>
					)}
					{host && (
						<Grid container direction="column" style={{ marginTop: 20 }}>
							<Grid item container direction="row">
								<Typography variant="subtitle1">Host configuration</Typography>
							</Grid>
							<Grid container direction="row" spacing={2}>
								<Grid item sm={6} xs={12}>
									<FormControl
										onInvalid={(e: FormEvent): void => {
											e.preventDefault();
											const form = e.target as HTMLFormElement;

											this.setState((state: LocalState) => ({
												...state,
												formValidation: {
													...state.formValidation,
													hostName: {
														...state.formValidation.hostName,
														invalid: true,
														message: form.validationMessage
													}
												}
											}));
										}}
										error={formValidation.hostName.invalid}
										fullWidth={true}
										required
									>
										<TextField
											error={formValidation.hostName.invalid}
											helperText={formValidation.hostName.message}
											margin="dense"
											required
											label={
												multipleDeploymentMode
													? "Host name prefix"
													: "Host name"
											}
											autoComplete="off"
											inputProps={{
												maxLength: 20,
												minLength: 3,
												readOnly: readOnly,
												"data-cy": "host-name"
											}}
											value={host.name}
											onChange={(e: ChangeEvent) => {
												isNodeNameLinkEnabled &&
													this.setState({ isNodeNameLinkEnabled: false });
												this.onHostNameChange(e);
											}}
										/>
									</FormControl>
								</Grid>
								<Grid item sm={6} xs={12}>
									<FormControl
										onInvalid={(e: FormEvent): void => {
											e.preventDefault();
											const form = e.target as HTMLFormElement;

											this.setState((state: LocalState) => ({
												...state,
												formValidation: {
													...state.formValidation,
													segment: {
														...state.formValidation.segment,
														invalid: true,
														message: form.validationMessage
													}
												}
											}));
										}}
										error={formValidation.segment.invalid}
										fullWidth={true}
										required
									>
										<TextField
											error={formValidation.segment.invalid}
											helperText={formValidation.segment.message}
											fullWidth={true}
											type="number"
											required
											margin="dense"
											label="Segment"
											value={host.segment}
											onChange={(e) => {
												const value = parseInt(e.target.value);

												onHostChange &&
													onHostChange({
														...host,
														segment: isNaN(value) ? 0 : value
													});
											}}
											inputProps={{
												id: "host-segment",
												"data-cy": "host-segment",
												readOnly: readOnly,
												min: 0
											}}
										/>
									</FormControl>
								</Grid>
							</Grid>
							<Grid container direction="row" spacing={2}>
								<Grid item sm={6} xs={12}>
									<FormControl fullWidth={true} margin="normal">
										<InputLabel htmlFor="host-system">Host system</InputLabel>
										<Select
											data-cy="host-system-select-container"
											fullWidth={true}
											value={host.system}
											onChange={(e) => {
												const value = e.target.value as string;

												onHostChange &&
													onHostChange({
														...host,
														system: HostUtils.getHostSystemEnum(value)
													});
											}}
											inputProps={{
												id: "host-system",
												"data-cy": "host-system-select",
												readOnly: readOnly
											}}
										>
											{supportedSystems.map((system: string) => (
												<MenuItem key={system} value={system}>
													{system}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								{host.type === HOST_TYPE.EC2 && (
									<Grid item sm={6} xs={12}>
										<FirewallRulesEditorComponent
											readOnly={true}
											firewallRules={cluster?.firewallRules || []}
										/>
									</Grid>
								)}
								{/*<Grid item sm={6} xs={12}>*/}
								{/*	<FormControl fullWidth={true} margin="normal">*/}
								{/*		<InputLabel htmlFor="host-type">Host type</InputLabel>*/}
								{/*		<Select*/}
								{/*			data-cy="host-type-select-container"*/}
								{/*			fullWidth={true}*/}
								{/*			value={host.type}*/}
								{/*			disabled={true}*/}
								{/*			onChange={(e) => {*/}
								{/*				const value = e.target.value as string;*/}

								{/*				onHostChange &&*/}
								{/*					onHostChange({*/}
								{/*						...host,*/}
								{/*						type: HostUtils.getHostTypeEnum(value),*/}
								{/*						hostTypeSpecific: HostUtils.getHostTypeSpecificDefaults(*/}
								{/*							HostUtils.getHostTypeEnum(value),*/}
								{/*							cluster*/}
								{/*						)*/}
								{/*					});*/}

								{/*				onNodeChange &&*/}
								{/*					node &&*/}
								{/*					onNodeChange({*/}
								{/*						...node,*/}
								{/*						unmanaged:*/}
								{/*							HostUtils.getHostTypeEnum(value) ===*/}
								{/*							HOST_TYPE.UNMANAGED*/}
								{/*					});*/}
								{/*			}}*/}
								{/*			inputProps={{*/}
								{/*				id: "host-type",*/}
								{/*				"data-cy": "host-type-select"*/}
								{/*			}}*/}
								{/*		>*/}
								{/*			{Object.values(HOST_TYPE).map((type: string) => (*/}
								{/*				<MenuItem key={type} value={type}>*/}
								{/*					{type}*/}
								{/*				</MenuItem>*/}
								{/*			))}*/}
								{/*		</Select>*/}
								{/*	</FormControl>*/}
								{/*</Grid>*/}
							</Grid>
							{host.type === HOST_TYPE.EC2 && host.hostTypeSpecific && (
								<>
									<EC2ConfigComponent
										config={host.hostTypeSpecific}
										readOnly={readOnly}
										onChange={(ec2Config: EC2SpecificSettings) => {
											console.log("onEC2 config change", ec2Config);

											onHostChange &&
												onHostChange({
													...host,
													hostTypeSpecific: {
														...ec2Config
													}
												});
										}}
									/>
								</>
							)}
							<Grid container direction="row" spacing={2}>
								<Grid item sm={6} xs={12}>
									<PrivateKeyEditor
										readOnly={readOnly || false}
										subtitleText={
											"Enter private key to override the key inherited from cluster."
										}
										inheritedPrivateKey={
											cluster ? cluster.sharedConfig.host.privateKey : ""
										}
										privateKey={host.privateKey}
										onChange={(privateKey: string) => {
											onHostChange &&
												onHostChange({
													...host,
													privateKey
												});
										}}
									/>
								</Grid>
								<Grid item sm={6} xs={12}>
									<AuthorizedKeysEditor
										subtitleText={
											readOnly
												? ""
												: "You can add custom authorized keys in addition to the keys inherited from cluster"
										}
										authorizedKeys={host.authorizedKeys}
										inheritedAuthorizedKeys={
											cluster ? cluster.sharedConfig.host.authorizedKeys : []
										}
										readOnly={readOnly || false}
										onAdd={(publicKey: string): void => {
											onHostChange &&
												onHostChange({
													...host,
													authorizedKeys: [...host.authorizedKeys, publicKey]
												});
										}}
										onRemove={(deletedKey: string): void => {
											const filteredKeys = host.authorizedKeys.filter(
												(publicKey: string) => publicKey !== deletedKey
											);

											onHostChange &&
												onHostChange({
													...host,
													authorizedKeys: filteredKeys
												});
										}}
									/>
								</Grid>
							</Grid>

							{(readOnly || host.type === HOST_TYPE.UNMANAGED) && (
								<>
									<Grid container direction="row" spacing={2}>
										<Grid item sm={6} xs={12}>
											<TextField
												fullWidth={true}
												margin="dense"
												label="SSH Address"
												value={host.ssh && host.ssh.address}
												inputProps={{ readOnly }}
												onChange={(e: ChangeEvent) => {
													const field = e.target as HTMLFormElement;
													const address = field.value as string;

													onHostChange &&
														onHostChange({
															...host,
															ssh: {
																address,
																port: host.ssh?.port || "22"
															}
														});
												}}
											/>
										</Grid>
										<Grid item sm={6} xs={12}>
											<TextField
												fullWidth={true}
												type="number"
												margin="dense"
												label="SSH Port"
												value={host.ssh && host.ssh.port}
												inputProps={{ readOnly, min: 0, max: 65535 }}
												onChange={(e: ChangeEvent) => {
													const field = e.target as HTMLFormElement;
													const port = field.value as string;

													onHostChange &&
														onHostChange({
															...host,
															ssh: {
																address: host.ssh?.address || "",
																port
															}
														});
												}}
											/>
										</Grid>
									</Grid>
								</>
							)}
						</Grid>
					)}
				</form>
			</>
		);
	}
}

const makeNodeNamesSelector = () =>
	createSelector(
		(state: AppState) => state.nodeList,
		// (nodeList: Node[]): string[] => nodeList.map((node: Node) => node.name) // todo: used for duplicate name check
		(nodeList: Map<number, Node[]>): string[] => []
	);

const makeHostNamesSelector = () =>
	createSelector(
		(state: AppState) => state.hostList,
		// (hostList: Map<number, Host[]>): string[] => hostList.map((host: Host) => host.name) // todo: used for duplicate name check
		(hostList: Map<number, Host[]>): string[] => []
	);

const mapGlobalStateToProps = (state: AppState) => {
	const nodeNamesSelector = makeNodeNamesSelector();
	const hostNamesSelector = makeHostNamesSelector();

	return {
		nodeNames: nodeNamesSelector(state),
		hostNames: hostNamesSelector(state)
	};
};

export default withStyles(styles, { withTheme: true })(
	withRouter(connect(mapGlobalStateToProps, {})(NodeFormComponent))
);
