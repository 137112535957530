import React from "react";
import { Box, Typography } from "@material-ui/core";
import { DEPLOYMENT_STATUS } from "components/management/cluster/types";
import { HOST_STATE } from "components/management/host/types";

interface LocalState {}

// default private key is used only as indicator that
interface LocalProps {
	hostState: HOST_STATE;
}

type Props = LocalProps;

class HostStateComponent extends React.PureComponent<Props, LocalState> {
	render() {
		const { hostState } = this.props;

		const renderHostState = (hostState: HOST_STATE) => {
			switch (hostState) {
				case DEPLOYMENT_STATUS.FAILURE:
					return (
						<Typography color="error" component="div">
							<Box textAlign="right">DEPLOYMENT ERROR</Box>
						</Typography>
					);

				case DEPLOYMENT_STATUS.PENDING:
					return <Typography>PENDING</Typography>;

				case DEPLOYMENT_STATUS.SUCCESS:
					return <Typography color="primary">DEPLOYED</Typography>;

				default:
					return <Typography>UNKNOWN</Typography>;
			}
		};

		return <>{renderHostState(hostState)}</>;
	}
}

export default HostStateComponent;
