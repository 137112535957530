import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
	createStyles({
		card: {
			width: "100%"
			// [theme.breakpoints.up("sm")]: {
			// 	width: 700
			// }
			// height: "100%"
		},
		tabs: {
			minHeight: 42
		},
		tab: {
			minHeight: 42
		}
	});
