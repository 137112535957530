import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
	createStyles({
		root: {
			position: "absolute",
			top: 0,
			right: 0,
			zIndex: 1
		},
		formControl: {
			marginTop: 0,
			marginRight: theme.spacing(1)
		},
		// icon: {
		// 	display: "none"
		// },
		selectOption: {
			minHeight: 25
		}
	});
