import {
	HOST_ACTION,
	Host,
	HostListFetchSucceededAction,
	HostListFetchRequestedAction
} from "components/management/host/types";

export const hostListFetchRequested = (
	clusterID: number
): HostListFetchRequestedAction => ({
	type: HOST_ACTION.LIST_FETCH_REQUESTED,
	payload: {
		clusterID
	}
});

export const hostListFetchSucceeded = (
	clusterID: number,
	hostList: Host[]
): HostListFetchSucceededAction => ({
	type: HOST_ACTION.LIST_FETCH_SUCCEEDED,
	payload: {
		clusterID,
		hostList
	}
});
