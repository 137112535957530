import {
	CLUSTER_ACTION,
	ClusterListFetchSuccededAction,
	Cluster
} from "components/management/cluster/types";

export const clusterListFetchRequested = () => ({
	type: CLUSTER_ACTION.LIST_FETCH_REQUESTED
});

export const clusterListFetchSucceeded = (
	clusterList: Cluster[]
): ClusterListFetchSuccededAction => ({
	type: CLUSTER_ACTION.LIST_FETCH_SUCCEDED,
	payload: {
		clusterList
	}
});

// export const clusterCreateWizardCreateRequested = (cluster: Cluster) => ({
// 	type: CLUSTER_ACTION.CREATE_REQUESTED,
// 	payload: {
// 		cluster
// 	}
// });
//
// export const clusterCreateSucceded = (cluster: Cluster) => ({
// 	type: CLUSTER_ACTION.CREATE_SUCCEEDED,
// 	payload: {
// 		cluster
// 	}
// });

export const clusterDeleteRequested = (cluster: Cluster) => ({
	type: CLUSTER_ACTION.DELETE_REQUESTED,
	payload: {
		cluster
	}
});

export const clusterDeleteSucceded = (clusterName: string) => ({
	type: CLUSTER_ACTION.DELETE_SUCCEDED,
	payload: {
		clusterName
	}
});
