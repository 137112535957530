import {
	USER_CREATE_WIZARD_ACTION,
	UserCreateWizardAction,
	UserCreateWizardState
} from "components/users/userCreateWizard/types";

const initialState: UserCreateWizardState = {
	isUserCreateWizardOpen: false
};

export function userCreateWizardReducer(
	state: UserCreateWizardState = initialState,
	action: UserCreateWizardAction
): UserCreateWizardState {
	switch (action.type) {
		case USER_CREATE_WIZARD_ACTION.SHOW:
			return {...state, isUserCreateWizardOpen: true};
		case USER_CREATE_WIZARD_ACTION.HIDE:
			return {...state, isUserCreateWizardOpen: false};
		default:
			return state;
	}
}
