import { USER_ROLE } from "components/users/types";

export interface NewUser {
	name: string;
	password: string;
	role: USER_ROLE;
	publicKey?: string;
}

export interface UserCreateWizardState {
	isUserCreateWizardOpen: boolean;
}

export enum USER_CREATE_WIZARD_ACTION {
	SHOW = "USER_CREATE_WIZARD/SHOW",
	HIDE = "USER_CREATE_WIZARD/HIDE"
}

export interface UserCreateWizardShow {
	type: USER_CREATE_WIZARD_ACTION.SHOW;
}

export interface UserCreateWizardHide {
	type: USER_CREATE_WIZARD_ACTION.HIDE;
}

export type UserCreateWizardAction =
	| UserCreateWizardShow
	| UserCreateWizardHide;
