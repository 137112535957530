import {
	HOST_STATE,
	HOST_STATUS,
	HOST_SYSTEM,
	HOST_TYPE
} from "components/management/host/types";
import {
	Cluster,
	DEPLOYMENT_STATUS
} from "components/management/cluster/types";
import { NODE_DB_ENGINE } from "components/management/node/types";
import Config from "modules/config/Config";
import { DEFAULT_HOST_SHARED_CONFIG } from "./const";

class HostUtils {
	static getHostSystemEnum = (value: string): HOST_SYSTEM => {
		switch (value) {
			case HOST_SYSTEM.CENTOS_7:
				return HOST_SYSTEM.CENTOS_7;
			case HOST_SYSTEM.CENTOS_8:
				return HOST_SYSTEM.CENTOS_8;
			case HOST_SYSTEM.REDHAT_8:
				return HOST_SYSTEM.REDHAT_8;
			case HOST_SYSTEM.UBUNTU_18:
				return HOST_SYSTEM.UBUNTU_18;
			case HOST_SYSTEM.UBUNTU_20:
				return HOST_SYSTEM.UBUNTU_20;
			case HOST_SYSTEM.DEBIAN_10:
				return HOST_SYSTEM.DEBIAN_10;
			default:
				return HOST_SYSTEM.UBUNTU_20;
		}
	};

	static getHostTypeEnum = (value: string): HOST_TYPE => {
		switch (value) {
			// case HOST_TYPE.LOCAL_LXD:
			// 	return HOST_TYPE.LOCAL_LXD;
			case HOST_TYPE.EC2:
				return HOST_TYPE.EC2;
			case HOST_TYPE.UNMANAGED:
				return HOST_TYPE.UNMANAGED;
			default:
				return HOST_TYPE.EC2;
		}
	};

	static getSupportedHostSystems = (
		dbEngine: NODE_DB_ENGINE
	): HOST_SYSTEM[] => {
		const support_matrix = Config.getInstance().support_matrix;

		// console.log("supportMatrix", support_matrix);

		if (!support_matrix) {
			return [HOST_SYSTEM.CENTOS_7];
		} else {
			return support_matrix[dbEngine];
		}
	};

	static getHostTypeSpecificDefaults = (
		hostType: HOST_TYPE,
		cluster?: Cluster
	): any => {
		switch (hostType) {
			case HOST_TYPE.EC2:
				if (cluster && cluster.sharedConfig.host.type === HOST_TYPE.EC2) {
					return cluster.sharedConfig.host.hostTypeSpecific;
				}

				return DEFAULT_HOST_SHARED_CONFIG;
			case HOST_TYPE.UNMANAGED:
				return {};
			// case HOST_TYPE.LOCAL_LXD:
			default:
				return undefined;
		}
	};

	static getHostState(deploymentStatus?: string): HOST_STATE {
		switch (deploymentStatus) {
			case "pending":
				return DEPLOYMENT_STATUS.PENDING;
			case "error":
				return DEPLOYMENT_STATUS.FAILURE;
			case "ok":
				return DEPLOYMENT_STATUS.SUCCESS;

			default:
				console.warn("Unknown host status:", deploymentStatus);
				return HOST_STATUS.UNKNOWN;
		}
	}
}

export default HostUtils;
