import {
	Avatar,
	createStyles,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Theme,
	withStyles,
	WithStyles,
	WithTheme
} from "@material-ui/core";
import { AppState } from "AppState";
import React from "react";
import { connect } from "react-redux";
import { StaticContext } from "react-router";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { clusterCreateWizardShow } from "../../cluster/clusterCreateWizard/actions";
import { Plus } from "mdi-material-ui";

const styles = (theme: Theme) =>
	createStyles({
		nested: {
			paddingLeft: theme.spacing(4)
		}
	});

// component local state interface
interface State {}

// PROPS
interface StateProps {}

interface DispatchProps {
	clusterCreateWizardShow: () => void;
}

type Props = StateProps &
	DispatchProps &
	WithStyles<typeof styles> &
	WithTheme &
	RouteComponentProps<any, StaticContext, any>;

// COMPONENT
class NoClusterItemComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {};
	}

	onItemClick = () => {
		this.props.clusterCreateWizardShow();
	};

	render(): React.ReactNode {
		return (
			<>
				<ListItem button onClick={this.onItemClick}>
					<ListItemAvatar>
						<Avatar>
							<Plus />
						</Avatar>
					</ListItemAvatar>
					<ListItemText
						primary={"No clusters"}
						secondary={"Click here to create first cluster"}
					/>
				</ListItem>
			</>
		);
	}
}

// REDUX MAPPINGS
const mapGlobalStateToProps = (state: AppState) => {
	return {};
};

const mapGlobalDispatchToProps = (dispatch: any) => {
	return {
		clusterCreateWizardShow: () => {
			dispatch(clusterCreateWizardShow());
		}
	};
};

export default withStyles(styles, { withTheme: true })(
	withRouter(
		connect(
			mapGlobalStateToProps,
			mapGlobalDispatchToProps
		)(NoClusterItemComponent)
	)
);
