import MaskedInput from "react-text-mask";
import React from "react";

interface TextMaskCustomProps {
	inputRef: (ref: HTMLInputElement | null) => void;
}

const ipAddrFieldProps = {
	guide: true,
	mask: (value: string) => {
		let result = [];
		const chunks = value.split(".");

		for (let i = 0; i < 4; ++i) {
			const chunk = (chunks[i] || "").replace(/_/gi, "");

			if (chunk === "") {
				result.push(/\d/, /\d/, /\d/, ".");
			} else if (+chunk === 0) {
				result.push(/\d/, ".");
			} else if (
				chunks.length < 4 ||
				(chunk.length < 3 && chunks[i].indexOf("_") !== -1)
			) {
				if (
					(chunk.length < 2 && +`${chunk}00` > 255) ||
					(chunk.length < 3 && +`${chunk}0` > 255)
				) {
					result.push(/\d/, /\d/, ".");
				} else {
					result.push(/\d/, /\d/, /\d/, ".");
				}
			} else {
				result.push(...new Array(chunk.length).fill(/\d/), ".");
			}
		}

		result = result.slice(0, -1);
		return result;
	},
	pipe: (value: string) => {
		if (value === "." || value.endsWith("..")) return false;

		const parts = value.split(".");

		if (
			parts.length > 4 ||
			parts.some(
				(part) => part === "00" || parseInt(part) < 0 || parseInt(part) > 255
			)
		) {
			return false;
		}

		return value;
	}
};

export function IPAddressField(props: TextMaskCustomProps) {
	const { inputRef, ...other } = props;

	return (
		<MaskedInput
			{...other}
			{...ipAddrFieldProps}
			// ref={(ref: any) => {
			// 	inputRef(ref ? ref.inputElement : null);
			// }}
			// mask={[
			// 	"(",
			// 	/[1-9]/,
			// 	/\d/,
			// 	/\d/,
			// 	")",
			// 	" ",
			// 	/\d/,
			// 	/\d/,
			// 	/\d/,
			// 	"-",
			// 	/\d/,
			// 	/\d/,
			// 	/\d/,
			// 	/\d/
			// ]}
			// placeholderChar={"\u2000"}
			// showMask
		/>
	);
}
