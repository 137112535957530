import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
	createStyles({
		toolbar: {
			marginBottom: theme.spacing(2)
		},
		formControl: {
			marginTop: theme.spacing(1),
			marginRight: theme.spacing(2)
		},
		selectMenu: {
			paddingTop: 2,
			paddingBottom: 2,
			paddingRight: 27
		},
		selectOption: {
			minHeight: 25
		}
	});
