import React, { ChangeEvent } from "react";
import {
	FormControl,
	IconButton,
	Input,
	InputAdornment,
	InputLabel,
	Tooltip
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { SnackbarActionPayload } from "components/sharedComponents/snackbar/types";
import { showSnackbar } from "components/sharedComponents/snackbar/actionCreators";
import { connect } from "react-redux";

interface LocalState {
	isPasswordVisible: boolean;
}

interface LocalProps {
	value?: string;
	label: string;
	required?: boolean;
	onChange?: (e: ChangeEvent) => void;
}

interface DispatchProps {
	showSnackbar: (snackbar: SnackbarActionPayload) => void;
}

type Props = LocalProps & DispatchProps;

class PasswordField extends React.PureComponent<Props, LocalState> {
	constructor(props: Props) {
		super(props);

		this.state = { isPasswordVisible: false };
	}

	render() {
		const { isPasswordVisible } = this.state;
		const { value, label, onChange, required } = this.props;

		const isReadOnly = !onChange;

		return (
			<FormControl fullWidth={true}>
				<InputLabel htmlFor="password-field">{`${label}${
					(required && " *") || ""
				}`}</InputLabel>
				<Input
					fullWidth={true}
					margin="dense"
					id="password-field"
					type={isPasswordVisible ? "text" : "password"}
					value={value}
					readOnly={isReadOnly}
					onChange={onChange}
					endAdornment={
						<InputAdornment position="end">
							{/*{isReadOnly && (*/}
							{/*	<Tooltip*/}
							{/*		enterDelay={500}*/}
							{/*		placement={"top"}*/}
							{/*		title={"Copy to clipboard"}*/}
							{/*	>*/}
							{/*		<IconButton*/}
							{/*			aria-label="copy to clipboard"*/}
							{/*			onClick={() => {*/}
							{/*				if (value) {*/}
							{/*					navigator.clipboard.writeText(value).then(*/}
							{/*						() => {*/}
							{/*							this.props.showSnackbar({*/}
							{/*								msg: "Password copied to clipboard!"*/}
							{/*							});*/}
							{/*						},*/}
							{/*						(err: Error) => {*/}
							{/*							console.error("Password copy error:", err);*/}
							{/*							this.props.showSnackbar({*/}
							{/*								msg: "Couldn't copy password to clipboard"*/}
							{/*							});*/}
							{/*						}*/}
							{/*					);*/}
							{/*				} else {*/}
							{/*					this.props.showSnackbar({*/}
							{/*						msg: "Didn't copy because password is empty"*/}
							{/*					});*/}
							{/*				}*/}
							{/*			}}*/}
							{/*			onMouseDown={(*/}
							{/*				event: React.MouseEvent<HTMLButtonElement>*/}
							{/*			) => {*/}
							{/*				event.preventDefault();*/}
							{/*			}}*/}
							{/*		>*/}
							{/*			<ContentCopy />*/}
							{/*		</IconButton>*/}
							{/*	</Tooltip>*/}
							{/*)}*/}
							<Tooltip
								enterDelay={500}
								placement={"top"}
								title={"Toggle visibility"}
							>
								<IconButton
									aria-label="toggle password visibility"
									onClick={() => {
										this.setState((state: LocalState) => ({
											isPasswordVisible: !state.isPasswordVisible
										}));
									}}
									onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
										event.preventDefault();
									}}
								>
									{isPasswordVisible ? <Visibility /> : <VisibilityOff />}
								</IconButton>
							</Tooltip>
						</InputAdornment>
					}
				/>
			</FormControl>
		);
	}
}

const mapGlobalDispatchToProps = (dispatch: any) => {
	return {
		showSnackbar: (snackbar: SnackbarActionPayload) => {
			dispatch(showSnackbar(snackbar));
		}
	};
};

export default connect(undefined, mapGlobalDispatchToProps)(PasswordField);
