import AppEnvironment from "modules/appEnvironment";
import { JSONAPIError, JSONAPIErrorResponse } from "modules/api/types";

export default class APIUtils {
	static getBaseURL() {
		return `${AppEnvironment.getApiAddress()}/
		${AppEnvironment.getApiVersion()}`;
	}

	static getUrl(endpoint: string) {
		return `${AppEnvironment.getApiAddress()}/
		${AppEnvironment.getApiVersion()}/${endpoint}`;
	}

	static APIErrorResponseHandler(e: any) {
		if (e.response) {
			return APIUtils.parseJSONAPIError(e.response as JSONAPIErrorResponse);
		} else {
			return e.message;
		}
	}

	static parseJSONAPIError(errorResponse: JSONAPIErrorResponse) {
		console.log("parseJSONAPIERROR", errorResponse);
		if (errorResponse.data.errors) {
			return errorResponse.data.errors
				.map((error: JSONAPIError) => error.detail)
				.join(", ");
		} else {
			return errorResponse.statusText;
		}
	}
}
