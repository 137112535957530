import { User, UserAction, USER_ACTION } from "components/users/types";

const initialState: User[] = [];

export function userListReducer(
	state: User[] = initialState,
	action: UserAction
): User[] {
	switch (action.type) {
		case USER_ACTION.LIST_FETCH_SUCCEEDED:
			return action.payload.userList;
		default:
			return state;
	}
}
