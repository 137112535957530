import {
	Button,
	List,
	ListSubheader,
	Paper,
	withStyles,
	WithStyles
} from "@material-ui/core";
import { AppState } from "AppState";
import { Cluster } from "components/management/cluster/types";
import ClusterItemComponent from "components/management/treeView/clusterItem/ClusterItemComponent";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { styles } from "./styles";
import { clusterCreateWizardShow } from "components/management/cluster/clusterCreateWizard/actions";
import NoClusterItemComponent from "components/management/treeView/clusterItem/NoClusterItemComponent";

// PROPS
interface LocalProps {
	clusterList: Cluster[];
}

interface ReduxProps {
	clusterCreateDialogShow: () => void;
}

// component local state interface
interface State {}

type Props = LocalProps &
	ReduxProps &
	WithStyles<typeof styles> &
	RouteComponentProps<{}>;

// COMPONENT
class TreeViewComponent extends React.Component<Props, State> {
	render(): React.ReactNode {
		const { clusterList } = this.props;

		return (
			<>
				<Paper data-cy="tree-view" className={this.props.classes.root}>
					<List subheader={<ListSubheader>Managed clusters</ListSubheader>}>
						{clusterList.length > 0 ? (
							clusterList
								.sort((cluster1: Cluster, cluster2: Cluster) =>
									cluster1.name
										.toLowerCase()
										.localeCompare(cluster2.name.toLowerCase())
								)
								.map((cluster: Cluster) => {
									return (
										<ClusterItemComponent
											data-cy="tree-view-cluster"
											key={cluster.id}
											clusterID={cluster.id || -1}
										/>
									);
								})
						) : (
							<NoClusterItemComponent data-cy="tree-view-empty" />
						)}
					</List>
				</Paper>
				<Button
					data-cy="cluster-create-button"
					fullWidth={true}
					onClick={() => {
						this.props.clusterCreateDialogShow();
					}}
				>
					Add cluster
				</Button>
			</>
		);
	}
}

// REDUX MAPPINGS
const mapGlobalStateToProps = (state: AppState) => {
	return {
		clusterList: state.clusterList
	};
};

const mapGlobalDispatchToProps = (dispatch: any) => ({
	clusterCreateDialogShow: () => {
		dispatch(clusterCreateWizardShow());
	}
});

export default withStyles(styles)(
	connect(mapGlobalStateToProps, mapGlobalDispatchToProps)(TreeViewComponent)
);
