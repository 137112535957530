export interface SystemStatusState {
	prometheus: {
		isOnline: boolean;
	};
}

export enum SYSTEM_STATUS_ACTION {
	PROMETHEUS_SET_ONLINE = "SYSTEM_STATUS/PROMETHEUS_SET_ONLINE",
	PROMETHEUS_SET_OFFLINE = "SYSTEM_STATUS/PROMETHEUS_SET_OFFLINE"
}

export interface PrometheusSetOnlineAction {
	type: SYSTEM_STATUS_ACTION.PROMETHEUS_SET_ONLINE;
}

export interface PrometheusSetOfflineAction {
	type: SYSTEM_STATUS_ACTION.PROMETHEUS_SET_OFFLINE;
}

export type SystemStatusAction =
	| PrometheusSetOnlineAction
	| PrometheusSetOfflineAction;
