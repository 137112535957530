import {
	SnackbarActionPayload,
	SnackbarActionShow,
	SNACKBAR_ACTION,
	SnackbarActionHide
} from "./types";

export const showSnackbar = (
	snackbar: SnackbarActionPayload
): SnackbarActionShow => ({
	type: SNACKBAR_ACTION.SHOW,
	snackbar
});

export const hideSnackbar = (): SnackbarActionHide => ({
	type: SNACKBAR_ACTION.HIDE
});
