import {
	WEB_SOCKET_HANDLER_ACTION,
	WebSocketHandlerAction,
	WebSocketHandlerState
} from "modules/webSocketHandler/types";

const initialState: WebSocketHandlerState = {
	isConnectionOpen: false,
	connectionOpenErrorMessage: ""
};

export function webSocketHandlerReducer(
	state: WebSocketHandlerState = initialState,
	action: WebSocketHandlerAction
): WebSocketHandlerState {
	switch (action.type) {
		case WEB_SOCKET_HANDLER_ACTION.CONNECTION_OPEN_SUCCEDED:
			return {
				...state,
				isConnectionOpen: true
			};
		case WEB_SOCKET_HANDLER_ACTION.CONNECTION_OPEN_FAILED:
			return {
				...state,
				isConnectionOpen: false,
				connectionOpenErrorMessage: action.payload.reason
			};
		case WEB_SOCKET_HANDLER_ACTION.CONNECTION_CLOSE_SUCCEDED:
			return {
				...state,
				isConnectionOpen: false
			};
		default:
			return state;
	}
}
