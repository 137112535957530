import {
	Job,
	JOB_ACTION,
	JobCreatedAction,
	JobListFetchRequestedAction,
	JobListFetchSucceededAction
} from "modules/jobs/types";

export const jobCreated = (job: Job): JobCreatedAction => ({
	type: JOB_ACTION.CREATED,
	payload: {
		job
	}
});

export const jobListFetchRequested = (): JobListFetchRequestedAction => ({
	type: JOB_ACTION.LIST_FETCH_REQUESTED
});

export const jobListFetchSucceeded = (
	jobList: Job[]
): JobListFetchSucceededAction => ({
	type: JOB_ACTION.LIST_FETCH_SUCCEEDED,
	payload: {
		jobList
	}
});

export const jobFetchRequested = (jobId: number) => ({
	type: JOB_ACTION.FETCH_REQUESTED,
	payload: {
		jobId
	}
});

export const jobFetchSucceeded = (job: Job) => ({
	type: JOB_ACTION.FETCH_SUCCEEDED,
	payload: {
		job
	}
});
