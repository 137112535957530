import {
	FirewallRule,
	HostSharedConfig
} from "components/management/host/types";
import { NodeSharedConfig } from "components/management/node/types";

export interface Cluster {
	id?: number;
	name: string;
	nodes?: Readonly<string[]>;
	hosts?: Readonly<string[]>;
	sharedConfig: {
		node: NodeSharedConfig;
		host: HostSharedConfig;
	};
	unmanaged: boolean;
	firewallRules: FirewallRule[];
}

export interface SerializedCluster {
	attributes: Cluster;
	id?: number;
	type: "clusters";
	relationships?: any;
}

// 	WSREP_MEMBER_UNDEFINED = 0, //!< undefined state
// 	WSREP_MEMBER_JOINER = 1,    //!< incomplete state, requested state transfer
// 	WSREP_MEMBER_DONOR = 2,     //!< complete state, donates state transfer
// 	WSREP_MEMBER_JOINED = 3,    //!< complete state
// 	WSREP_MEMBER_SYNCED = 4,    //!< complete state, synchronized with group
// 	WSREP_MEMBER_ERROR = 5,     //!< this and above is provider-specific error code
// 	WSREP_MEMBER_MAX = 6

export enum WSREP_MEMBER_STATE {
	UNDEFINED,
	JOINER,
	DONOR,
	JOINED,
	SYNCED,
	ERROR,
	MAX
}

export enum DEPLOYMENT_STATUS {
	PENDING = 998,
	FAILURE = 999,
	SUCCESS = 1000
}

// REDUX
export enum CLUSTER_ACTION {
	LIST_FETCH_REQUESTED = "CLUSTER/LIST_FETCH_REQUESTED",
	LIST_FETCH_SUCCEDED = "CLUSTER/LIST_FETCH_SUCCEDED",
	// CREATE_REQUESTED = "CLUSTER/CREATE_REQUESTED",
	// CREATE_SUCCEEDED = "CLUSTER/CREATE_SUCCEEDED",
	DELETE_REQUESTED = "CLUSTER/DELETE_REQUESTED",
	DELETE_SUCCEDED = "CLUSTER/DELETE_SUCCEDED"
}

export interface ClusterListFetchRequestedAction {
	type: CLUSTER_ACTION.LIST_FETCH_REQUESTED;
}

export interface ClusterListFetchSuccededAction {
	type: CLUSTER_ACTION.LIST_FETCH_SUCCEDED;
	payload: {
		clusterList: Cluster[];
	};
}
export interface ClusterDeleteRequestedAction {
	type: CLUSTER_ACTION.DELETE_REQUESTED;
	payload: {
		cluster: Cluster;
	};
}

export interface ClusterDeleteSuccededAction {
	type: CLUSTER_ACTION.DELETE_SUCCEDED;
	payload: {
		clusterName: string;
	};
}

export type ClusterAction =
	| ClusterListFetchRequestedAction
	| ClusterListFetchSuccededAction
	// | ClusterCreateRequestedAction
	// | ClusterCreateSuccededAction
	| ClusterDeleteRequestedAction
	| ClusterDeleteSuccededAction;
