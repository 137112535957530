import Axios, { AxiosResponse } from "axios";
import Config from "modules/config/Config";

class MetricsApi {
	static async fetch(query: string): Promise<AxiosResponse> {
		// console.log("metrics fetch", query, Config.getInstance());

		const {
			influx_host: host,
			influx_port: port,
			influx_password: password,
			influx_protocol: protocol,
			influx_username: username,
			influx_db: db
		} = Config.getInstance();

		return await Axios.get(`${protocol}://${host}:${port}/query`, {
			auth: {
				username,
				password
			},
			params: {
				q: query,
				db
			}
		});
	}

	// static async getTargetMetadata(): Promise<AxiosResponse> {
	// 	return await Axios.get(
	// 		`${Config.getInstance().prometheus_url}/api/v1/targets/metadata`
	// 	);
	// }
}

export default MetricsApi;
