import { Badge, createStyles, Theme, withStyles } from "@material-ui/core";

export const BlinkingBadge = withStyles((theme: Theme) =>
	createStyles({
		badge: {
			"&::after": {
				position: "absolute",
				top: 0,
				left: 0,
				width: "100%",
				height: "100%",
				borderRadius: "50%",
				animation: "$ripple 1.2s infinite ease-in-out",
				border: "1px solid",
				borderColor: theme.palette.primary.main,
				content: '""'
			}
		},
		"@keyframes ripple": {
			"0%": {
				transform: "scale(.8)",
				opacity: 1
			},
			"100%": {
				transform: "scale(2.4)",
				opacity: 0
			}
		}
	})
)(Badge);
