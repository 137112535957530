import Axios, { AxiosResponse } from "axios";
import APIUtils from "modules/api/utils";
import { Host } from "components/management/host/types";

class LogsApi {
	// static async fetchLogList(filter: {
	// 	cluster_id: number;
	// 	host_id?: number;
	// 	node_id?: number;
	// }): Promise<AxiosResponse> {
	// 	return await Axios.get(
	// 		`${AppEnvironment.getApiAddress()}/${AppEnvironment.getApiVersion()}/logs`,
	// 		{
	// 			params: {
	// 				"filter[cluster_id]": filter.cluster_id,
	// 				"filter[host_id]": filter.host_id,
	// 				"filter[node_id]": filter.node_id
	// 			}
	// 		}
	// 	);
	// }
	static async fetchGMSystemLog(): Promise<AxiosResponse> {
		return Axios.get(`${APIUtils.getUrl(`logs/default.log`)}`);
	}

	static async fetchHostLog(host: Host): Promise<AxiosResponse> {
		return await Axios.get(
			`${APIUtils.getUrl(`logs/cluster-${host.clusterID}-host-${host.id}.log`)}`
		);
	}
}

export default LogsApi;
