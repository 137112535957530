import React, { ChangeEvent } from "react";
import { withStyles, WithStyles, WithTheme } from "@material-ui/core/styles";
import { styles } from "./styles";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
	Tooltip
} from "@material-ui/core";
import HiddenJs from "@material-ui/core/Hidden/HiddenJs";

// default config is used only as indicator that
interface LocalProps {
	isDialogOpen: boolean;
	config: string;
	onChange: (config: string) => void;
	onConfirm: () => void;
	onClose: () => void;
	inheritedConfig?: string;
	readOnly?: boolean;
	subtitleText?: string;
}

type Props = LocalProps & WithStyles<typeof styles> & WithTheme;

class DbEngineConfigEditor extends React.PureComponent<Props> {
	render() {
		const {
			readOnly,
			config,
			inheritedConfig,
			subtitleText,
			isDialogOpen,
			onClose,
			onConfirm,
			onChange
		} = this.props;

		const isInherited = !config && !!inheritedConfig;

		const dialogRender = () => (
			<>
				{/*Mobile */}
				<HiddenJs smUp>
					<Dialog
						open={isDialogOpen}
						scroll="body"
						onClose={onClose}
						fullWidth={true}
						fullScreen={true}
						aria-labelledby="form-dialog-title"
					>
						{dialogContentRender()}
					</Dialog>
				</HiddenJs>

				{/*Desktop*/}
				<HiddenJs xsDown>
					<Dialog
						scroll="body"
						open={isDialogOpen}
						onClose={onClose}
						fullWidth={true}
						maxWidth={"lg"}
						aria-labelledby="form-dialog-title"
					>
						{dialogContentRender()}
					</Dialog>
				</HiddenJs>
			</>
		);

		const dialogContentRender = () => (
			<>
				<DialogTitle id="form-dialog-title">
					Custom DB engine configuration editor
				</DialogTitle>
				<DialogContent>
					{subtitleText && (
						<DialogContentText variant={"subtitle2"}>
							{subtitleText}
						</DialogContentText>
					)}

					<TextField
						autoFocus
						multiline={true}
						rows={5}
						rowsMax={1000}
						margin="dense"
						id="dbEngineConfig"
						label="Custom configuration"
						type="text"
						fullWidth
						variant="outlined"
						value={isInherited ? inheritedConfig : config}
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							onChange(e.target.value);
						}}
						helperText={isInherited && "Inherited from cluster"}
						inputProps={{
							"data-testid": "db-engine-config-text-field",
							style: { fontFamily: "monospace" },
							spellCheck: false,
							autoComplete: "off",
							autoCorrect: "off",
							autoCapitalize: "off",
							readOnly: readOnly
						}}
					/>
				</DialogContent>
				<DialogActions>
					{readOnly ? (
						<Button onClick={onClose}>Close</Button>
					) : (
						<>
							{inheritedConfig && (
								<Tooltip
									title={"Inherits configuration from cluster"}
									disableHoverListener={isInherited}
									disableFocusListener={isInherited}
									disableTouchListener={isInherited}
								>
									<Button
										disabled={isInherited}
										onClick={() => {
											onChange("");
										}}
									>
										Reset
									</Button>
								</Tooltip>
							)}

							<Button onClick={onClose}>Cancel</Button>

							<Button
								data-cy="db-engine-config-set-button"
								onClick={onConfirm}
								color="primary"
							>
								Set
							</Button>
						</>
					)}
				</DialogActions>
			</>
		);

		return <>{dialogRender()}</>;
	}
}

export default withStyles(styles, { withTheme: true })(DbEngineConfigEditor);
