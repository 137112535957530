import {
	UserListFetchRequestedAction,
	USER_ACTION,
	User,
	UserListFetchSucceededAction
} from "components/users/types";

export const userListFetchRequested = (): UserListFetchRequestedAction => ({
	type: USER_ACTION.LIST_FETCH_REQUESTED
});

export const userListFetchSucceeded = (
	userList: User[]
): UserListFetchSucceededAction => ({
	type: USER_ACTION.LIST_FETCH_SUCCEEDED,
	payload: {
		userList
	}
});
