import { initializeInterceptors } from "modules/Interceptors";
import SnackbarSideEffects from "components/sharedComponents/snackbar/sideEffects";
import GMSnackbar from "components/sharedComponents/snackbar/SnackbarComponent";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { applyMiddleware, compose, createStore, Store } from "redux";
import createSagaMiddleware from "redux-saga";
import { rootReducer } from "./AppState";
import GMRouter from "components/Router";
import "./index.sass";
import * as serviceWorker from "./serviceWorker";
import UserSideEffects from "components/users/sideEffects";
import ClusterSideEffects from "components/management/cluster/sideEffects";
import NodeSideEffects from "components/management/node/sideEffects";
import HostSideEffects from "components/management/host/sideEffects";
import JobSideEffects from "modules/jobs/sideEffects";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import MetricsStoreSideEffects from "modules/metricsStore/sideEffects";
import GMDialog from "components/sharedComponents/dialog/DialogComponent";
import PreloaderSideEffects from "./modules/preloader/sideEffects";

const sagaMiddleware = createSagaMiddleware();

export const appStore: Store = createStore(
	rootReducer,
	compose(
		applyMiddleware(sagaMiddleware),
		((window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
			(window as any).__REDUX_DEVTOOLS_EXTENSION__({ serialize: true })) ||
			compose
	)
);

sagaMiddleware.run(SnackbarSideEffects);
sagaMiddleware.run(UserSideEffects);
sagaMiddleware.run(ClusterSideEffects);
sagaMiddleware.run(NodeSideEffects);
sagaMiddleware.run(HostSideEffects);
sagaMiddleware.run(JobSideEffects);
// sagaMiddleware.run(WebSocketHandlerSideEffects);
sagaMiddleware.run(PreloaderSideEffects);
sagaMiddleware.run(MetricsStoreSideEffects);

const defaultTheme = createMuiTheme();

const theme = createMuiTheme({
	overrides: {
		MuiCardHeader: {
			root: {
				paddingBottom: defaultTheme.spacing(1),
				paddingTop: defaultTheme.spacing(1)
			},
			action: {
				marginTop: 5
			}
		},
		MuiDivider: {
			root: {
				margin: "1 0"
			}
		}
	},
	props: {
		MuiButton: {
			size: "small"
		},
		MuiFilledInput: {
			margin: "dense"
		},
		MuiFormControl: {
			margin: "dense"
		},
		MuiFormHelperText: {
			margin: "dense"
		},
		MuiIconButton: {
			size: "small"
		},
		MuiInputBase: {
			margin: "dense"
		},
		MuiInputLabel: {
			margin: "dense"
		},
		MuiListItem: {
			dense: true
		},
		MuiOutlinedInput: {
			margin: "dense"
		},
		MuiFab: {
			size: "small"
		},
		MuiTable: {
			size: "small"
		},
		MuiTextField: {
			margin: "dense"
		},
		MuiToolbar: {
			variant: "dense"
		}
	},
	spacing: 6,
	palette: {
		primary: {
			light: "#ffa742",
			main: "#ed7703",
			dark: "#b44900",
			contrastText: "#fff"
		},
		secondary: {
			light: "#5e71ef",
			main: "#1346bc",
			dark: "#00208b",
			contrastText: "#fff"
		},
		error: {
			main: "#ed0303",
			light: "#ff5735",
			dark: "#b10000"
		},
		warning: {
			main: "#eded03"
		},
		success: {
			main: "#4c9404"
		}
	}
});

initializeInterceptors(appStore);

ReactDOM.render(
	<Provider store={appStore}>
		<MuiThemeProvider theme={theme}>
			<GMRouter />
			<GMSnackbar />
			<GMDialog />
		</MuiThemeProvider>
	</Provider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

if (process.env.NODE_ENV !== "development") {
	console.log = () => {};
}
