import { USER_ROLE } from "components/users/types";

export default class UserUtils {
	static getRoleName(userRole: USER_ROLE) {
		switch (userRole) {
			case USER_ROLE.NONE:
				return "No role";
			case USER_ROLE.OBSERVER:
				return "Observer";
			case USER_ROLE.MANAGER:
				return "Manager";
			case USER_ROLE.ADMINISTRATOR:
				return "Administrator";
			default:
				console.warn(`Unknown user role ${userRole}`);
				return "Unknown role";
		}
	}
}
