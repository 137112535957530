import React from "react";
import { Typography, withStyles } from "@material-ui/core";
import Config from "modules/config/Config";
import { styles } from "modules/appInfo/styles";
import { WithStyles } from "@material-ui/core/styles";
import { SnackbarActionPayload } from "components/sharedComponents/snackbar/types";
import { showSnackbar } from "components/sharedComponents/snackbar/actionCreators";
import { connect } from "react-redux";

interface LocalProps {}

interface DispatchProps {
	showSnackbar: (snackbar: SnackbarActionPayload) => void;
}

type Props = LocalProps & DispatchProps & WithStyles<typeof styles>;

class AppInfo extends React.Component<Props> {
	MAX_RETRIES = 5;
	RETRY_TIMEOUT = 2000;

	retry = 0;

	render() {
		if (
			Config.getInstance().public_version === "" ||
			Config.getInstance().gmd_version === "" ||
			Config.getInstance().gui_version === ""
		) {
			if (this.retry < this.MAX_RETRIES) {
				this.retry++;
				setTimeout(() => {
					this.forceUpdate();
				}, this.RETRY_TIMEOUT);
			}
		}

		return (
			<Typography
				data-cy="public-version"
				variant="caption"
				className={this.props.classes.appInfo}
				title="Click to copy to clipboard"
				onClick={() => {
					const versionInfo = `${Config.getInstance().public_version} (GMD: ${
						Config.getInstance().gmd_version || "n/a"
					}, GUI: ${Config.getInstance().gui_version || "n/a"})`;

					navigator.clipboard.writeText(versionInfo).then(
						() => {
							this.props.showSnackbar({
								msg: "Version info copied to clipboard!"
							});
						},
						(err: Error) => {
							console.error("Version copy to clipboard error:", err);
							this.props.showSnackbar({
								msg: "Couldn't copy version info to clipboard"
							});
						}
					);
				}}
			>
				{Config.getInstance().public_version}{" "}
				{`(GMD: ${Config.getInstance().gmd_version || "n/a"}, GUI: ${
					Config.getInstance().gui_version || "n/a"
				})`}
			</Typography>
		);
	}
}

const mapGlobalDispatchToProps = (dispatch: any) => {
	return {
		showSnackbar: (snackbar: SnackbarActionPayload) => {
			dispatch(showSnackbar(snackbar));
		}
	};
};

export default withStyles(styles)(
	connect(undefined, mapGlobalDispatchToProps)(AppInfo)
);
