import {
	UserSession,
	UserSessionStartAction,
	USER_SESSION_ACTION,
	UserSessionEndAction,
	UserSessionExtendAction
} from "./types";

export const startSession = (session: UserSession): UserSessionStartAction => ({
	type: USER_SESSION_ACTION.START_SESSION,
	payload: {
		session
	}
});

export const extendSessionDuration = (
	accessExpirationTimestamp: number
): UserSessionExtendAction => ({
	type: USER_SESSION_ACTION.EXTEND_DURATION,
	payload: {
		accessExpirationTimestamp
	}
});

export const endSession = (): UserSessionEndAction => ({
	type: USER_SESSION_ACTION.END_SESSION
});
