import React, { ChangeEvent } from "react";
import { withStyles, WithStyles, WithTheme } from "@material-ui/core/styles";
import { styles } from "./styles";
import {
	Avatar,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemSecondaryAction,
	ListItemText,
	TextField,
	Tooltip,
	Typography
} from "@material-ui/core";
import { Delete, VpnKey } from "@material-ui/icons";
import HiddenJs from "@material-ui/core/Hidden/HiddenJs";
import SSHKeyUtils from "components/sharedComponents/privateKeyEditor/utils";

interface LocalState {
	privateKey: string;
	isDialogOpen: boolean;
	isKeyValid: boolean;
	keyProperties: {
		size: number;
		type: string;
	};
}

interface LocalProps {
	privateKey: string;
	inheritedPrivateKey?: string;
	readOnly: boolean;
	onChange: (privateKey: string) => void;
	subtitleText?: string;
}

type Props = LocalProps & WithStyles<typeof styles> & WithTheme;

class PrivateKeyEditor extends React.PureComponent<Props, LocalState> {
	defaultState: LocalState = {
		privateKey: "",
		isDialogOpen: false,
		isKeyValid: true,
		keyProperties: {
			size: NaN,
			type: ""
		}
	};

	constructor(props: Props) {
		super(props);
		let keyProperties: any = this.defaultState.keyProperties;
		let key;

		if (props.privateKey) {
			key = SSHKeyUtils.getKeyProperties(props.privateKey);
		} else if (props.inheritedPrivateKey) {
			key = SSHKeyUtils.getKeyProperties(props.inheritedPrivateKey);
		}

		keyProperties = {
			...keyProperties,
			size: key && key.size,
			type: key && key.type
		};

		this.state = { ...this.defaultState, keyProperties: { ...keyProperties } };
	}

	static getDerivedStateFromProps(nextProps: Readonly<Props>) {
		let key: any;

		if (nextProps.privateKey) {
			key = SSHKeyUtils.getKeyProperties(nextProps.privateKey);
		} else if (nextProps.inheritedPrivateKey) {
			key = SSHKeyUtils.getKeyProperties(nextProps.inheritedPrivateKey);
		}

		if (key) {
			return {
				keyProperties: {
					size: key.size,
					type: key.type
				}
			};
		} else {
			return null;
		}
	}

	closeDialog = (): void => {
		this.setState(
			(state: LocalState): LocalState => ({
				...state,
				isDialogOpen: false
			})
		);
	};

	onItemClick = (): void => {
		if (this.props.readOnly) return;
		this.setState((state: LocalState) => ({
			...state,
			privateKey: this.props.privateKey,
			isDialogOpen: true
		}));
	};

	onPrivateKeyChanged = (e: ChangeEvent<HTMLInputElement>): void => {
		e.persist();
		let pk = e.target.value.trim();

		const validate = (privateKey: string): boolean => {
			try {
				SSHKeyUtils.getKeyProperties(privateKey);
				return true;
			} catch (e) {
				// console.warn("Entered data is not isValid private key.");
				return false;
			}
		};

		if (validate(pk)) {
			this.setState((state: LocalState) => ({
				...state,
				isKeyValid: true,
				privateKey: pk
			}));
		} else {
			this.setState((state: LocalState) => ({
				...state,
				isKeyValid: false,
				privateKey: e.target.value
			}));
		}
	};

	onRemoveClick = (): void => {
		this.setState((state: LocalState) => ({
			...state,
			privateKey: ""
		}));
		this.props.onChange("");
	};

	onSetClick = (): void => {
		this.props.onChange(this.state.privateKey);
		this.closeDialog();
	};

	render() {
		const {
			classes,
			readOnly,
			theme,
			privateKey,
			inheritedPrivateKey,
			subtitleText
		} = this.props;
		const {
			isDialogOpen,
			privateKey: localPrivateKey,
			isKeyValid,
			keyProperties
		} = this.state;

		const isKeyPresent = privateKey || inheritedPrivateKey;
		const isKeyInherited = readOnly
			? privateKey === inheritedPrivateKey
			: !privateKey && inheritedPrivateKey;

		const ListItemComponent = ListItem as any; // temporary workaround because of bug in TS/MUI: https://github.com/mui-org/material-ui/issues/14971

		const dialogRender = () => (
			<>
				{/* mobile */}
				<HiddenJs smUp>
					<Dialog
						open={isDialogOpen}
						onClose={() => {
							this.setState({ isDialogOpen: false });
						}}
						fullWidth={true}
						fullScreen={true}
						aria-labelledby="form-dialog-title"
					>
						{dialogContentRender()}
					</Dialog>
				</HiddenJs>

				{/* desktop */}
				<HiddenJs xsDown>
					<Dialog
						open={isDialogOpen}
						onClose={() => {
							this.setState({ isDialogOpen: false });
						}}
						fullWidth={true}
						maxWidth={"md"}
						aria-labelledby="form-dialog-title"
					>
						{dialogContentRender()}
					</Dialog>
				</HiddenJs>
			</>
		);

		const dialogContentRender = () => (
			<>
				<DialogTitle id="form-dialog-title">SSH private key</DialogTitle>
				<DialogContent>
					{subtitleText && (
						<DialogContentText variant={"subtitle2"}>
							{subtitleText}
						</DialogContentText>
					)}

					{!isKeyValid && (
						<Typography variant="subtitle2" color="error">
							Please enter valid SSH private key.
						</Typography>
					)}
					<TextField
						autoFocus
						multiline={true}
						margin="dense"
						id="privateKey"
						label="SSH Private Key"
						type="text"
						fullWidth
						variant="outlined"
						value={localPrivateKey}
						onChange={this.onPrivateKeyChanged}
						inputProps={{
							"data-cy": "private-key-text-field",
							style: { fontFamily: "monospace" },
							spellCheck: false,
							autoComplete: "off",
							autoCorrect: "off",
							autoCapitalize: "off"
						}}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={(): void => {
							this.closeDialog();
						}}
					>
						Cancel
					</Button>

					<Button
						data-cy="private-key-set-button"
						onClick={this.onSetClick}
						disabled={!localPrivateKey || !isKeyValid}
						color="primary"
					>
						Set
					</Button>
				</DialogActions>
			</>
		);

		return (
			<>
				<List className={classes.root}>
					<ListItemComponent
						button={!readOnly}
						onClick={this.onItemClick}
						data-testid="private-key-button"
					>
						<ListItemAvatar>
							<Avatar
								style={
									isKeyPresent
										? { backgroundColor: theme.palette.primary.main }
										: {}
								}
							>
								<VpnKey />
							</Avatar>
						</ListItemAvatar>
						<ListItemText
							data-cy="private-key"
							primary="SSH Private Key"
							secondary={
								!isKeyPresent
									? "No key"
									: `${keyProperties.type.toUpperCase()} ${
											keyProperties.size
									  } ${isKeyInherited ? "(Inherited from cluster)" : ""}`
							}
						/>
						{privateKey && !readOnly && (
							<ListItemSecondaryAction>
								<Tooltip title={"Remove private key"}>
									<IconButton
										onClick={this.onRemoveClick}
										edge="end"
										aria-label="Delete"
									>
										<Delete />
									</IconButton>
								</Tooltip>
							</ListItemSecondaryAction>
						)}
					</ListItemComponent>
				</List>
				{dialogRender()}
			</>
		);
	}
}

export default withStyles(styles, { withTheme: true })(PrivateKeyEditor);
