import {
	PreloaderState,
	PUBLIC_PRELOADER_ACTION,
	PublicPreloaderAction,
	SECURE_PRELOADER_ACTION,
	SecurePreloaderAction
} from "modules/preloader/types";

const initialState: PreloaderState = {
	publicPreloader: {
		isPreloadDone: false
	},
	securePreloader: {
		isPreloadDone: false
	}
};

export function preloaderReducer(
	state: PreloaderState = initialState,
	action: PublicPreloaderAction | SecurePreloaderAction
): PreloaderState {
	switch (action.type) {
		case PUBLIC_PRELOADER_ACTION.PRELOAD_SUCCEEDED:
			return {
				...state,
				publicPreloader: {
					isPreloadDone: true
				}
			};
		case PUBLIC_PRELOADER_ACTION.PRELOAD_FAILED:
			return {
				...state,
				publicPreloader: {
					...state.securePreloader,
					errorMsg: action.payload.reason
				}
			};
		case SECURE_PRELOADER_ACTION.PRELOAD_REQUESTED:
			console.log("secure preload requested");
			return { ...state };
		case SECURE_PRELOADER_ACTION.PRELOAD_SUCCEEDED:
			return {
				...state,
				securePreloader: {
					isPreloadDone: true
				}
			};
		case SECURE_PRELOADER_ACTION.PRELOAD_FAILED:
			return {
				...state,
				securePreloader: {
					...state.securePreloader,
					errorMsg: action.payload.reason
				}
			};
		default:
			return state;
	}
}
