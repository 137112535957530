import {
	NODE_ACTION,
	NodeListFetchSucceededAction
} from "components/management/node/types";
import { Node } from "./types";

export const nodeListFetchRequested = (clusterID: number) => ({
	type: NODE_ACTION.LIST_FETCH_REQUESTED,
	payload: {
		clusterID
	}
});

export const nodeListFetchSucceeded = (
	clusterID: number,
	nodeList: Node[]
): NodeListFetchSucceededAction => ({
	type: NODE_ACTION.LIST_FETCH_SUCCEEDED,
	payload: {
		clusterID,
		nodeList
	}
});

export const nodeCreateRequested = (node: Node) => ({
	type: NODE_ACTION.CREATE_REQUESTED,
	payload: {
		node
	}
});

export const nodeCreateSucceeded = (node: Node) => ({
	type: NODE_ACTION.CREATE_SUCCEEDED,
	payload: {
		node
	}
});
