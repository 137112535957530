import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
	createStyles({
		root: {
			width: "100%",
			height: 900
		},
		autocomplete: {
			width: "100%"
		}
	});
