import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles, WithStyles, WithTheme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {
	ArrowBack,
	DeviceHub,
	ExitToApp,
	SupervisorAccount
} from "@material-ui/icons";
import MenuIcon from "@material-ui/icons/Menu";
import { AppState } from "AppState";
import gc_logo from "assets/images/gc_logo.png";
import logo from "assets/images/gc_logo_orange.png";
import TreeViewComponent from "components/management/treeView/TreeViewComponent";
import { History } from "history";
import Auth from "modules/auth/Auth";
import { showSnackbar } from "components/sharedComponents/snackbar/actionCreators";
import { SnackbarActionPayload } from "components/sharedComponents/snackbar/types";
import React from "react";
import { connect } from "react-redux";
import {
	Redirect,
	Route,
	RouteComponentProps,
	Switch,
	withRouter
} from "react-router-dom";
import "./RootComponent.sass";
import ClusterManagerComponent from "components/management/cluster/ClusterManagerComponent";
import { Grid } from "@material-ui/core";
import NodeManagerComponent from "components/management/node/NodeManagerComponent";
import { styles } from "./styles";
import Box from "@material-ui/core/Box";
import ClusterCreateDialogComponent from "components/management/cluster/clusterCreateWizard/ClusterCreateDialogComponent";
import NodeDeploymentWizardComponent from "components/management/node/nodeDeploymentWizard/NodeDeploymentWizardComponent";
import HiddenJs from "@material-ui/core/Hidden/HiddenJs";
import { SecurePreloaderState } from "modules/preloader/types";
import { StaticContext } from "react-router";
import UserListComponent from "components/users/UserListComponent";
import { endSession } from "modules/userSession/actions";
import UserCreateWizard from "components/users/userCreateWizard/UserCreateWizard";
import UserManagerComponent from "components/users/UserManagerComponent";
import SecurePreloader from "modules/preloader/SecurePreloaderComponent";
import HostManagerComponent from "components/management/host/HostManagerComponent";
import { GMDialogService } from "../sharedComponents/dialog/DialogService";
import SystemLogComponent from "../systemLog/SystemLogComponent";
import { TextBoxOutline } from "mdi-material-ui";

interface LocalState {
	isDrawerOpen: boolean;
	showBackButton: boolean;
}

interface LocalProps {
	history?: History;
}

interface ReduxStateProps {
	securePreloader: SecurePreloaderState;
	isNodeDeploymentWizardOpen: boolean;
}

interface ReduxDispatchProps {
	showSnackbar: (snackbar: SnackbarActionPayload) => void;
	endUserSession: () => void;
}

type Props = LocalProps &
	ReduxDispatchProps &
	ReduxStateProps &
	WithStyles<typeof styles> &
	WithTheme &
	RouteComponentProps<any, StaticContext, any>;

class RootComponent extends React.Component<Props, LocalState> {
	history: History;

	constructor(props: Props) {
		super(props);
		this.history = props.history;

		this.state = {
			isDrawerOpen: false,
			showBackButton: false
		};
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.location !== prevProps.location) {
			this.updateShowBackButton();
		}
	}

	updateShowBackButton() {
		const { pathname } = this.props.location;

		// console.log("updateShowBackButton", pathname);

		this.setState(
			(state: LocalState): LocalState => ({
				...state,
				showBackButton: pathname.split("/").length > 2
			})
		);
	}

	handleDrawerToggle = () => {
		this.setState((state) => ({ isDrawerOpen: !state.isDrawerOpen }));
	};

	logOut = async () => {
		try {
			await Auth.logout();
			await Auth.clearJWTTokens();

			this.props.endUserSession();
			this.history.push("/login");
		} catch (e) {
			console.error("Logout failed:", e);
			GMDialogService.showInfo({ message: e.message, title: "Logout failed" });
		}
	};

	render() {
		const {
			classes,
			securePreloader,
			isNodeDeploymentWizardOpen,
			history
		} = this.props;
		const { showBackButton } = this.state;

		const drawer = (
			<div>
				{/*<Hidden smDown>*/}
				{/*	<div className={classes.toolbar} />*/}
				{/*</Hidden>*/}
				<div className="logo-container">
					<img
						className="galera-cluster-logo"
						src={gc_logo}
						alt="Galera Cluster Manager"
					/>
				</div>
				<Divider />
				<List>
					<ListItem
						button
						key={2}
						onClick={() => {
							history.push("/clusters");
							this.setState(
								(state: LocalState): LocalState => ({
									...state,
									isDrawerOpen: false
								})
							);
						}}
					>
						<ListItemIcon>
							<DeviceHub />
						</ListItemIcon>
						<ListItemText primary={"Management"} />
					</ListItem>
					<ListItem
						button
						key={3}
						onClick={() => {
							history.push("/users");
							this.setState(
								(state: LocalState): LocalState => ({
									...state,
									isDrawerOpen: false
								})
							);
						}}
					>
						<ListItemIcon>
							<SupervisorAccount />
						</ListItemIcon>
						<ListItemText primary={"Users"} />
					</ListItem>
					<ListItem
						button
						key={4}
						onClick={() => {
							history.push("/system-log");
							this.setState(
								(state: LocalState): LocalState => ({
									...state,
									isDrawerOpen: false
								})
							);
						}}
					>
						<ListItemIcon>
							<TextBoxOutline />
						</ListItemIcon>
						<ListItemText primary={"System log"} />
					</ListItem>
				</List>
				<Divider />
				<List>
					{/*<ListItem button key={1}>*/}
					{/*	<ListItemIcon>*/}
					{/*		<Settings />*/}
					{/*	</ListItemIcon>*/}
					{/*	<ListItemText primary={"Settings"} />*/}
					{/*</ListItem>*/}
					{/*<ListItem button key={2}>*/}
					{/*	<ListItemIcon>*/}
					{/*		<Info />*/}
					{/*	</ListItemIcon>*/}
					{/*	<ListItemText primary="About" />*/}
					{/*</ListItem>*/}
					<ListItem button id="LogOutButton" onClick={this.logOut} key={3}>
						<ListItemIcon>
							<ExitToApp />
						</ListItemIcon>
						<ListItemText primary={"Logout"} />
					</ListItem>
				</List>
			</div>
		);

		return (
			<>
				{!securePreloader.isPreloadDone ? (
					<Box height="100%">
						<SecurePreloader />
					</Box>
				) : (
					<Box className={classes.root} height="100%">
						<CssBaseline />
						<AppBar position="fixed" className={classes.appBar}>
							<Toolbar variant="dense">
								<HiddenJs smUp>
									{showBackButton ? (
										<IconButton
											id="GoBackButton"
											color="inherit"
											aria-label="Go back"
											onClick={() => {
												this.props.history.goBack();
											}}
											className={classes.menuButton}
										>
											<ArrowBack />
										</IconButton>
									) : (
										<IconButton
											id="MainMenuButton"
											color="inherit"
											aria-label="Open drawer"
											onClick={this.handleDrawerToggle}
											className={classes.menuButton}
										>
											<MenuIcon />
										</IconButton>
									)}
								</HiddenJs>

								<HiddenJs xsDown>
									<IconButton
										id="MainMenuButton"
										color="inherit"
										aria-label="Open drawer"
										onClick={this.handleDrawerToggle}
										className={classes.menuButton}
									>
										<MenuIcon />
									</IconButton>
								</HiddenJs>

								<Hidden smUp>
									<img
										className="logo"
										src={logo}
										height="30px"
										alt="Galera Cluster Logo"
									/>
								</Hidden>

								<Typography variant="h6" color="inherit" noWrap>
									Galera Manager
								</Typography>
							</Toolbar>
						</AppBar>
						<nav className={classes.drawer}>
							{/*Mobile*/}
							{/*<HiddenJs smUp>*/}
							<Drawer
								variant="temporary"
								open={this.state.isDrawerOpen}
								onClose={this.handleDrawerToggle}
								classes={{
									paper: classes.drawerPaper
								}}
							>
								{drawer}
							</Drawer>
							{/*</HiddenJs>*/}
							{/*Desktop*/}
							{/*<HiddenJs xsDown>*/}
							{/*	<Drawer*/}
							{/*		classes={{*/}
							{/*			paper: classes.drawerPaper*/}
							{/*		}}*/}
							{/*		variant="permanent"*/}
							{/*		open*/}
							{/*	>*/}
							{/*		{drawer}*/}
							{/*	</Drawer>*/}
							{/*</HiddenJs>*/}
						</nav>
						<Grid
							container
							direction="column"
							component="main"
							className={classes.content}
						>
							{/*Mobile*/}
							<HiddenJs smUp>
								<Grid item />

								<Route path="/" exact component={TreeViewComponent} />
								<Route path="/" component={ClusterCreateDialogComponent} />
								{/*<Route path="/" component={NodeDeploymentWizardComponent} />*/}
								<Route
									path="/clusters/:name"
									exact
									component={ClusterManagerComponent}
								/>
								<Route
									path="/nodes/:name"
									exact
									component={NodeManagerComponent}
								/>
								<Route
									path="/hosts/:name"
									exact
									component={HostManagerComponent}
								/>
							</HiddenJs>

							{/*Desktop*/}
							<HiddenJs xsDown>
								<Grid container direction="column">
									<Grid item style={{ height: 58 }} />

									<Grid
										item
										id="container"
										container
										direction="row"
										xs
										spacing={2}
									>
										<Route exact path="/">
											<Redirect to="/clusters" />
										</Route>
										{/*Dialogs*/}
										<Route
											path={["/clusters", "/nodes", "/hosts"]}
											component={ClusterCreateDialogComponent}
										/>

										<Route path="/users" component={UserCreateWizard} />

										{/*Display tree view for cluster management*/}
										<Grid item>
											<Route path="/users" component={UserListComponent} />
											<Route
												path={["/clusters", "/nodes", "/hosts"]}
												component={TreeViewComponent}
											/>
										</Grid>

										{/*Details pages*/}
										<Grid item xs>
											<Switch>
												<Route
													path="/clusters/:clusterID"
													exact
													component={ClusterManagerComponent}
												/>
												<Route
													path="/clusters/:clusterID/nodes/:nodeID"
													exact
													component={NodeManagerComponent}
												/>

												<Route
													path="/clusters/:clusterID/hosts/:hostID"
													exact
													component={HostManagerComponent}
												/>

												<Route
													path="/users/:name" // todo: change to /users/:userID to be consistent with cluster management routing
													exact
													component={UserManagerComponent}
												/>

												<Route
													path="/system-log"
													exact
													component={SystemLogComponent}
												/>
											</Switch>
										</Grid>
									</Grid>
								</Grid>
							</HiddenJs>
						</Grid>
						{isNodeDeploymentWizardOpen && <NodeDeploymentWizardComponent />}
					</Box>
				)}
			</>
		);
	}
}

// REDUX MAPPINGS
const mapGlobalStateToProps = (state: AppState) => ({
	isNodeDeploymentWizardOpen: state.nodeCreateWizard.isOpen,
	securePreloader: state.preloader.securePreloader
});

const mapGlobalDispatchToProps = (dispatch: any) => {
	return {
		showSnackbar: (snackbar: SnackbarActionPayload) => {
			dispatch(showSnackbar(snackbar));
		},
		endUserSession: () => {
			dispatch(endSession());
		}
	};
};

export default withStyles(styles, { withTheme: true })(
	withRouter(
		connect(mapGlobalStateToProps, mapGlobalDispatchToProps)(RootComponent)
	)
);
