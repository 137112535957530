import {
	METRICS_ACTION,
	MetricsAction,
	MetricsStoreState,
	WsrepLastCommittedMetric
} from "modules/metricsStore/types";
import MetricsUtils from "modules/metricsStore/utils";

const initialState: MetricsStoreState = {
	wsrepLastCommittedMetrics: {},
	wsrepLocalStateMetrics: {},
	joinedNodes: {}
};

export function metricsReducer(
	state: MetricsStoreState = initialState,
	action: MetricsAction
): MetricsStoreState {
	switch (action.type) {
		case METRICS_ACTION.WSREP_LOCAL_STATE_METRICS_FETCH_SUCCEEDED:
			return {
				...state,
				wsrepLocalStateMetrics: MetricsUtils.toRecord(action.payload.metrics),
				joinedNodes: MetricsUtils.countJoinedNodes(action.payload.metrics)
			};

		case METRICS_ACTION.WSREP_LAST_COMMITTED_METRICS_FETCH_SUCCEEDED:
			let newLastCommittedMetricRecords: Record<
				string,
				WsrepLastCommittedMetric
			> = {};

			action.payload.metrics.forEach((metric: WsrepLastCommittedMetric) => {
				newLastCommittedMetricRecords[`${metric.cluster}`] = metric;
			});

			return {
				...state,
				wsrepLastCommittedMetrics: {
					...state.wsrepLastCommittedMetrics,
					...newLastCommittedMetricRecords
				}
			};

		default:
			return state;
	}
}
