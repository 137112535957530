import {
	NODE_CREATE_WIZARD_ACTION,
	NodeCreateWizardHideAction,
	NodeCreateWizardShowAction
} from "components/management/node/nodeDeploymentWizard/types";
import { Cluster } from "components/management/cluster/types";

export const nodeCreateWizardShow = (
	cluster: Cluster
): NodeCreateWizardShowAction => ({
	type: NODE_CREATE_WIZARD_ACTION.SHOW,
	payload: {
		cluster
	}
});

export const nodeCreateWizardHide = (): NodeCreateWizardHideAction => ({
	type: NODE_CREATE_WIZARD_ACTION.HIDE
});
