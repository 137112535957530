import React from "react";
import { createSelector } from "reselect";
import { AppState } from "../../../../AppState";
import { Node } from "components/management/node/types";
import { Job } from "modules/jobs/types";
import { connect } from "react-redux";
import {
	Grid,
	Icon,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Typography
} from "@material-ui/core";
import { Console } from "mdi-material-ui";
import moment from "moment";
import JobStatusComponent from "components/management/node/jobs/jobStatus/jobStatusComponent";
import { DoneAll } from "@material-ui/icons";
import { Host } from "components/management/host/types";
import { Cluster } from "components/management/cluster/types";

interface LocalState {}

// pass node and/or host for which you want this component to display jobs
interface LocalProps {
	cluster?: Cluster;
	node?: Node;
	host?: Host;
}

interface ReduxStateProps {
	jobList: Job[];
}

type Props = LocalProps & ReduxStateProps;

class JobListComponent extends React.Component<Props, LocalState> {
	render():
		| React.ReactElement<any, string | React.JSXElementConstructor<any>>
		| string
		| number
		| {}
		| React.ReactNodeArray
		| React.ReactPortal
		| boolean
		| null
		| undefined {
		const { jobList } = this.props;

		return (
			<>
				{jobList.length > 0 ? (
					<List>
						{jobList.map((job: Job) => (
							<ListItem button key={job.id}>
								<ListItemAvatar>
									<Console />
								</ListItemAvatar>
								<ListItemText
									primary={job.description}
									secondary={`Started on ${moment(job.createdAt).format(
										"LLL"
									)} (${moment(job.createdAt).fromNow()})${
										job.executionInfo.details
											? ", response: " + job.executionInfo.details
											: ""
									}`}
								/>
								<JobStatusComponent jobStatus={job.executionInfo.status} />
							</ListItem>
						))}
					</List>
				) : (
					<>
						<Grid container direction="row" spacing={2}>
							<Grid item>
								<Icon>
									<DoneAll />
								</Icon>
							</Grid>
							<Grid item>
								<Typography variant="subtitle2">No jobs</Typography>
							</Grid>
						</Grid>
					</>
				)}
			</>
		);
	}
}

// selectors
const makeGetJobs = () =>
	createSelector(
		(state: AppState) => state.jobList,
		(state: AppState, props: LocalProps) => props.node,
		(state: AppState, props: LocalProps) => props.host,
		(state: AppState, props: LocalProps) => props.cluster,
		(jobList: Job[], node?: Node, host?: Host, cluster?: Cluster) => {
			let filteredJobs = jobList.filter((job: Job) => {
				if (node && job.meta.node_id) {
					return (
						job.meta.cluster_id === node.clusterID &&
						job.meta.host_id === node.hostID &&
						job.meta.node_id === node.id
					);
				} else if (host) {
					return (
						job.meta.cluster_id === host.clusterID &&
						job.meta.host_id === host.id
					);
				} else if (cluster) {
					return job.meta.cluster_id === cluster.id;
				} else {
					return false;
				}
			});

			// get unique jobs - filter out duplicates
			// filteredJobs = [
			// 	...new Map(filteredJobs.map((job) => [job["id"], job])).values()
			// ];

			filteredJobs.sort((job1: Job, job2: Job) => {
				// sort jobs by time and id
				const timeDiff =
					moment(job2.createdAt).unix() - moment(job1.createdAt).unix();
				if (timeDiff === 0) {
					return job1.id - job2.id;
				} else {
					return timeDiff;
				}
			});

			// console.log("filtered jobs", filteredJobs);

			return filteredJobs;
		}
	);

// redux state mappings
const mapStateToProps = (state: AppState, props: LocalProps) => {
	const getJobs = makeGetJobs();
	return { jobList: getJobs(state, props) };
};

export default connect(mapStateToProps)(JobListComponent);
