export default class AppEnvironment {
	private static getApiProtocol(): string {
		return process.env.REACT_APP_API_PROTOCOL || "http";
	}

	public static getApiAddress(): string {
		if (process.env.REACT_APP_SERVER_ADDRESS) {
			return `${this.getApiProtocol()}://${
				process.env.REACT_APP_SERVER_ADDRESS
			}:${process.env.REACT_APP_API_PORT || 8000}`;
		} else {
			return window.location.origin;
		}
	}

	public static getApiVersion(): string {
		return process.env.REACT_APP_API_VERSION || "v2";
	}

	public static getWsHostAddress(): string {
		return this.getApiAddress().replace("http", "ws");
	}

	// returns apiProtocol://serverAddress:prometheusPort
	public static getPrometheusAddress(): string {
		if (
			process.env.REACT_APP_SERVER_ADDRESS &&
			process.env.REACT_APP_PROMETHEUS_PORT
		) {
			return `${this.getApiProtocol()}://${
				process.env.REACT_APP_SERVER_ADDRESS
			}:${process.env.REACT_APP_PROMETHEUS_PORT}`;
		} else {
			return this.getApiAddress();
		}
	}
}
