import React, { ChangeEvent } from "react";
import { withStyles, WithStyles, WithTheme } from "@material-ui/core/styles";
import { styles } from "./styles";
import {
	Avatar,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemSecondaryAction,
	ListItemText,
	Popover,
	TextField,
	Tooltip,
	Typography
} from "@material-ui/core";
import { Delete, VpnKey } from "@material-ui/icons";
import * as sshpk from "sshpk";
import HiddenJs from "@material-ui/core/Hidden/HiddenJs";

interface LocalState {
	isDialogOpen: boolean;
	isPopoverOpen: boolean;
	popoverAnchorEl: Element | null;
	popoverContent: string;
	addNewKey: {
		value: string;
		isValid: boolean;
		message: string;
	};
}

interface LocalProps {
	authorizedKeys: string[];
	inheritedAuthorizedKeys?: string[];
	subtitleText: string;
	readOnly: boolean;
	onRemove: (publicKey: string, index: number) => void;
	onAdd: (publicKey: string) => void;
}

type Props = LocalProps & WithStyles<typeof styles> & WithTheme;

class AuthorizedKeysEditor extends React.PureComponent<Props, LocalState> {
	defaultState: LocalState = {
		isDialogOpen: false,
		isPopoverOpen: false,
		popoverAnchorEl: null,
		popoverContent: "",
		addNewKey: {
			value: "",
			isValid: false,
			message: ""
		}
	};

	constructor(props: Props) {
		super(props);

		this.state = this.defaultState;
	}

	onItemClick = (): void => {
		this.setState((state: LocalState) => ({
			...state,
			authorizedKeys: this.props.authorizedKeys,
			isDialogOpen: true
		}));
	};

	closeDialog = (): void => {
		this.setState((state: LocalState) => ({ ...state, isDialogOpen: false }));
	};

	onPublicKeyChanged = (e: ChangeEvent<HTMLInputElement>): void => {
		e.persist();
		console.log("publicKeyChanged", e.target.value);
		let isValid = false;
		let message = "";
		const value = e.target.value;

		if (value) {
			try {
				sshpk.parseKey(value, "auto");
				isValid = true;
				// message = `Valid ${key.type.toUpperCase()} ${
				// 	key.size
				// }bit key. Press ENTER or click "add" icon on the right to save the key.`;
			} catch (e) {
				// console.warn("Not valid public key");
				message = "Please enter valid SSH public key";
			}

			if (
				this.props.authorizedKeys.includes(value) ||
				(this.props.inheritedAuthorizedKeys &&
					this.props.inheritedAuthorizedKeys.includes(value))
			) {
				isValid = false;
				message = "Key already exists";
			}
		}

		if (isValid) {
			this.props.onAdd(value);
			this.setState((state: LocalState) => ({
				...state,
				addNewKey: {
					...state.addNewKey,
					value: "",
					isValid: false,
					message: ""
				}
			}));
		} else {
			this.setState((state: LocalState) => ({
				...state,
				addNewKey: {
					...state.addNewKey,
					value,
					isValid,
					message: message
				}
			}));
		}
	};

	render() {
		const {
			classes,
			readOnly,
			theme,
			subtitleText,
			authorizedKeys,
			inheritedAuthorizedKeys
		} = this.props;
		const {
			isDialogOpen,
			isPopoverOpen,
			popoverAnchorEl,
			popoverContent,
			addNewKey
		} = this.state;

		let keysCount = authorizedKeys.length;
		let inheritedKeysCount = 0;

		if (inheritedAuthorizedKeys) {
			inheritedKeysCount = inheritedAuthorizedKeys.filter(
				(publicKey: string) => !authorizedKeys?.includes(publicKey)
			).length;
			keysCount += inheritedKeysCount;
		}

		const ListItemComponent = ListItem as any; // temporary workaround because of bug in TS/MUI: https://github.com/mui-org/material-ui/issues/14971

		const renderKeyItem = (
			publicKey: string,
			index: number,
			inherited = false
		) => {
			const key = sshpk.parseKey(publicKey, "auto");

			return (
				<ListItemComponent key={publicKey}>
					<ListItemAvatar>
						<Avatar
							style={{
								backgroundColor: theme.palette.primary.main
							}}
						>
							<VpnKey />
						</Avatar>
					</ListItemAvatar>
					<ListItemText
						primary={key.comment}
						secondary={`${key.type.toUpperCase()} ${key.size} ${
							inherited ? "(Inherited from cluster)" : ""
						}`}
					/>
					{!inherited && !readOnly && (
						<ListItemSecondaryAction>
							<Tooltip title={"Remove key"}>
								<IconButton
									onClick={(): void => {
										this.props.onRemove(publicKey, index);
									}}
									edge="end"
									aria-label="Delete"
								>
									<Delete />
								</IconButton>
							</Tooltip>
						</ListItemSecondaryAction>
					)}
				</ListItemComponent>
			);
		};

		const renderDialog = () => (
			<>
				{/*Mobile*/}
				<HiddenJs smUp>
					<Dialog
						open={isDialogOpen}
						onClose={() => {}}
						fullWidth={true}
						fullScreen={true}
						disableBackdropClick={true}
						aria-labelledby="form-dialog-title"
					>
						{dialogContentRender()}
					</Dialog>
				</HiddenJs>

				{/*Desktop*/}
				<HiddenJs xsDown>
					<Dialog
						open={isDialogOpen}
						onClose={() => {}}
						fullWidth={true}
						maxWidth={"sm"}
						disableBackdropClick={true}
						onEscapeKeyDown={this.closeDialog}
						aria-labelledby="form-dialog-title"
					>
						{dialogContentRender()}
					</Dialog>
				</HiddenJs>
			</>
		);

		const dialogContentRender = () => (
			<>
				<DialogTitle id="form-dialog-title">Authorized keys</DialogTitle>
				<DialogContent>
					{subtitleText && (
						<DialogContentText variant={"subtitle2"}>
							{subtitleText}
						</DialogContentText>
					)}
					<List className={classes.root}>
						{inheritedAuthorizedKeys &&
							inheritedAuthorizedKeys.map((publicKey: string, index) => {
								return renderKeyItem(publicKey, index, true);
							})}
						{authorizedKeys
							.filter(
								(publicKey: string) =>
									!inheritedAuthorizedKeys?.includes(publicKey)
							)
							.map((publicKey: string, index) => {
								return renderKeyItem(publicKey, index);
							})}
					</List>
					{!readOnly && (
						<TextField
							autoFocus
							error={addNewKey.message !== "" && !addNewKey.isValid}
							helperText={addNewKey.message}
							multiline={true}
							margin="dense"
							id="publicKey"
							label="Paste SSH public key"
							type="text"
							fullWidth
							variant="outlined"
							value={addNewKey.value}
							onChange={this.onPublicKeyChanged}
							inputProps={{
								"data-cy": "authorized-keys-text-field",
								style: { fontFamily: "monospace" },
								spellCheck: false,
								autoComplete: "off",
								autoCorrect: "off",
								autoCapitalize: "off"
							}}
						/>
					)}

					<Popover
						open={isPopoverOpen}
						anchorEl={popoverAnchorEl}
						onClose={() => {
							console.log("onClose");
							this.setState(
								(state: LocalState): LocalState => ({
									...state,
									isPopoverOpen: false,
									popoverAnchorEl: null
								})
							);
						}}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "center"
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "center"
						}}
					>
						<Typography style={{ padding: theme.spacing(2) }}>
							{popoverContent}
						</Typography>
					</Popover>
				</DialogContent>
				<DialogActions>
					<Button
						data-cy="authorized-keys-editor-close-button"
						onClick={(): void => {
							this.closeDialog();
						}}
					>
						Close
					</Button>
				</DialogActions>
			</>
		);

		return (
			<>
				<List className={classes.root}>
					<ListItemComponent button={true} onClick={this.onItemClick}>
						<ListItemAvatar>
							<Avatar
								style={
									keysCount > 0
										? { backgroundColor: theme.palette.primary.main }
										: {}
								}
							>
								<VpnKey />
							</Avatar>
						</ListItemAvatar>
						<ListItemText
							data-cy="authorized-keys"
							primary="Authorized keys"
							secondary={
								keysCount > 0
									? `${keysCount} key/s ${
											inheritedKeysCount > 0
												? `(${inheritedKeysCount} inherited)`
												: ""
									  }`
									: "No keys"
							}
						/>
					</ListItemComponent>
				</List>
				{renderDialog()}
			</>
		);
	}
}

export default withStyles(styles, { withTheme: true })(AuthorizedKeysEditor);
