import moment from "moment";
import { INanoDate, toNanoDate } from "influx";

export class Utils {
	static generateRandomStringArray(
		arrayLength: number,
		stringLength: number
	): string[] {
		return [...Array(arrayLength)].map(() =>
			[...Array(~~stringLength)]
				.map(() => String.fromCharCode(Math.random() * (123 - 97) + 97))
				.join("")
		);
	}

	static toNanoDate(date: Date): INanoDate {
		return toNanoDate(`${moment(date).valueOf()}000000`);
	}
}
