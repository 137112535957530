import * as sshpk from "sshpk";

class SSHKeyUtils {

	static getKeyProperties = (privateKey: string): any => {
		sshpk.parsePrivateKey(privateKey, "auto");
		const key = sshpk.parseKey(privateKey, "auto");
		return {
			size: key.size,
			type: key.type
		};
	};

}

export default SSHKeyUtils;

