import { NODE_DB_ENGINE } from "components/management/node/types";
import { HOST_SYSTEM } from "components/management/host/types";
import Config from "modules/config/Config";

class NodeUtils {
	static getNodeDbEngineEnum = (value: string): NODE_DB_ENGINE => {
		switch (value) {
			case NODE_DB_ENGINE.MARIADB_10_3:
				return NODE_DB_ENGINE.MARIADB_10_3;
			case NODE_DB_ENGINE.MARIADB_10_4:
				return NODE_DB_ENGINE.MARIADB_10_4;
			case NODE_DB_ENGINE.MARIADB_10_5:
				return NODE_DB_ENGINE.MARIADB_10_5;
			case NODE_DB_ENGINE.MYSQL_5_7:
				return NODE_DB_ENGINE.MYSQL_5_7;
			case NODE_DB_ENGINE.MYSQL_8_0:
				return NODE_DB_ENGINE.MYSQL_8_0;
			default:
				console.warn(
					`Unrecognised node DB engine: ${value}. Returning default value instead.`
				);
				return NODE_DB_ENGINE.MARIADB_10_3;
		}
	};

	static getSupportedDbEngines = (system: HOST_SYSTEM): NODE_DB_ENGINE[] => {
		const support_matrix = Config.getInstance().support_matrix;

		if (!support_matrix) {
			return [NODE_DB_ENGINE.MARIADB_10_3];
		} else {
			return support_matrix[system];
		}
	};

	// static getNodeState(
	// 	localStateMetric?: ResultItem,
	// 	mysqlUpMetric?: ResultItem,
	// 	deploymentStatus?: string
	// ): NODE_STATE {
	// 	// if deployment failed
	// 	if (deploymentStatus === "error") {
	// 		return DEPLOYMENT_STATUS.FAILURE;
	// 	}
	//
	// 	// if no local state and mysql_up metric
	// 	if (!localStateMetric && !mysqlUpMetric) {
	// 		return DB_STATE.UNKNOWN;
	//
	// 		// if not local state metric
	// 	} else if (!localStateMetric && mysqlUpMetric) {
	// 		if (mysqlUpMetric.value[1] === "0") {
	// 			return DB_STATE.DOWN;
	// 		} else {
	// 			return DB_STATE.UP;
	// 		}
	// 	} else {
	// 		if (localStateMetric) {
	// 			return parseInt(localStateMetric.value[1]);
	// 		} else {
	// 			return DB_STATE.UNKNOWN;
	// 		}
	// 	}
	// }
}

export default NodeUtils;
