import {
	SnackbarActionShow,
	SNACKBAR_ACTION,
	SnackbarState,
	SNACKBAR_TYPE,
	SnackBarAction
} from "./types";

// REDUCERS
const initialState: SnackbarState = {
	msg: "",
	snackbarType: SNACKBAR_TYPE.DEFAULT,
	open: false
};

export default function snackbarReducer(
	state: SnackbarState = initialState,
	action: SnackBarAction
): SnackbarState {
	switch (action.type) {
		case SNACKBAR_ACTION.SHOW:
			const showAction = action as SnackbarActionShow;

			return {
				...state,
				msg: showAction.snackbar.msg,
				snackbarType: showAction.snackbar.snackbarType || SNACKBAR_TYPE.DEFAULT,
				open: true
			};

		case SNACKBAR_ACTION.HIDE:
			return initialState;

		default:
			return state;
	}
}
