import JobsApi from "modules/api/JobsApi";
import { JOB_ACTION, JobFetchRequestedAction } from "modules/jobs/types";
import { call, delay, put } from "typed-redux-saga";
import { takeLatest } from "redux-saga/effects";
import { jobFetchSucceeded, jobListFetchSucceeded } from "modules/jobs/actions";
import { SECURE_PRELOADER_ACTION } from "modules/preloader/types";

// function* fetchJobList(action: JobListFetchRequestedAction) {
// const response = yield call(JobsApi.fetchList);
// yield put(jobListFetchSucceeded(response.data));
// const dummyResponse = [
// 	{
// 		id: 3,
// 		name: 'node start ("n1")',
// 		parent: null,
// 		when: "2019-10-21T10:36:21.867203652Z",
// 		status: 2
// 	},
// 	{
// 		id: 4,
// 		name: 'node status ("n2")',
// 		parent: null,
// 		when: "2019-10-21T10:36:21.867579549Z",
// 		status: 2
// 	},
// 	{
// 		id: 5,
// 		name: 'node start ("n1")',
// 		parent: null,
// 		when: "2019-10-21T10:36:21.867203652Z",
// 		status: -2
// 	},
// 	{
// 		id: 6,
// 		name: 'node start ("n1")',
// 		parent: null,
// 		when: "2019-10-21T10:36:21.867203652Z",
// 		status: -1
// 	},
// 	{
// 		id: 7,
// 		name: 'node start ("n1")',
// 		parent: null,
// 		when: "2019-10-21T10:36:21.867203652Z",
// 		status: 0
// 	},
// 	{
// 		id: 8,
// 		name: 'node start ("n1")',
// 		parent: null,
// 		when: "2019-10-21T10:36:21.867203652Z",
// 		status: 1
// 	},
// 	{
// 		id: 9,
// 		name: 'node start ("n1")',
// 		parent: null,
// 		when: "2019-10-21T10:36:21.867203652Z",
// 		status: 3
// 	},
// 	{
// 		id: 10,
// 		name: 'node start ("n1")',
// 		parent: null,
// 		when: "2019-10-21T10:36:21.867203652Z",
// 		status: 4
// 	}
// ];
//
// yield put(jobListFetchSucceeded(dummyResponse));
// }

function* fetchJob(action: JobFetchRequestedAction) {
	const response = yield* call(JobsApi.fetch, action.payload.jobId);
	yield put(jobFetchSucceeded(response.data));
}

function* monitorJobList() {
	console.log("Jobs monitor started");

	while (true) {
		try {
			const jobs = yield* call(JobsApi.fetchList);

			yield put(jobListFetchSucceeded(jobs));
		} catch (e) {
			console.error("Job list monitor error:", e);
		} finally {
			yield delay(2000);
		}
	}
}

function* JobSideEffects() {
	yield takeLatest(SECURE_PRELOADER_ACTION.PRELOAD_SUCCEEDED, monitorJobList);
	// yield takeLatest(JOB_ACTION.LIST_FETCH_REQUESTED, fetchJobList);
	yield takeLatest(JOB_ACTION.FETCH_REQUESTED, fetchJob);
}

export default JobSideEffects;
