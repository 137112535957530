import { call, put } from "typed-redux-saga";
import { takeLatest } from "redux-saga/effects";
import { USER_ACTION } from "components/users/types";
import UsersApi from "modules/api/UsersApi";
import { userListFetchSucceeded } from "components/users/actions";

function* fetchUserList() {
	// console.log("fetchUserList saga");

	try {
		const userList = yield* call(UsersApi.fetchList);
		console.log("saga user list fetched", userList);

		yield put(userListFetchSucceeded(userList));
	} catch (e) {
		console.error(e);
	}
}

function* UserSideEffects() {
	yield takeLatest(USER_ACTION.LIST_FETCH_REQUESTED, fetchUserList);
}

export default UserSideEffects;
