import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => createStyles({
	root: {
		width: "100%",
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper
	},
	nested: {
		paddingLeft: theme.spacing(4)
	},
	doubleNested: {
		paddingLeft: theme.spacing(6)
	}
});