import {
	NODE_DB_ENGINE,
	NodeSharedConfig
} from "components/management/node/types";

export const DEFAULT_NODE_SHARED_CONFIG: NodeSharedConfig = {
	dbEngine: NODE_DB_ENGINE.MARIADB_10_5,
	settings: {
		logs: {
			generalLog: {
				enabled: false
			}
		}
	}
};
