import {
	UserSessionAction,
	UserSessionState,
	USER_SESSION_ACTION
} from "./types";

// INITIAL STATE
const initialState: UserSessionState = {
	isActive: false,
	session: undefined
};

// REDUCERS
export function userSessionReducer(
	state: UserSessionState = initialState,
	action: UserSessionAction
): UserSessionState {
	switch (action.type) {
		case USER_SESSION_ACTION.START_SESSION:
			return {
				isActive: true,
				session: action.payload.session
			};

		case USER_SESSION_ACTION.EXTEND_DURATION:
			return {
				...state,
				session: state.session && {
					...state.session,
					accessExpirationTimestamp: action.payload.accessExpirationTimestamp
				}
			};

		case USER_SESSION_ACTION.END_SESSION:
			return initialState;

		default:
			return state;
	}
}
