import Axios from "axios";
import axios from "axios";
import { Node, SerializedNode } from "components/management/node/types";
import AppEnvironment from "modules/appEnvironment";
import APIUtils from "modules/api/utils";
import { deattribute, serialise } from "kitsu-core";
import { Job } from "modules/jobs/types";
import { CLUSTERS_ENDPOINT, NODES_ENDPOINT } from "modules/api/const";

class NodesAPI {
	static async fetchList(clusterID: number): Promise<Node[]> {
		try {
			const nodesResponse = await Axios.get(
				`${APIUtils.getBaseURL()}/${CLUSTERS_ENDPOINT}/${clusterID}/${NODES_ENDPOINT}`
			);

			return nodesResponse.data.data.map((item: SerializedNode) =>
				deattribute({ ...item })
			);
		} catch (e) {
			throw new Error(APIUtils.APIErrorResponseHandler(e));
		}
	}

	static async create(node: Node): Promise<{ job: Job; node: Node }> {
		try {
			const data = serialise("nodes", node, "POST");
			console.log("create node", data);
			const response = await axios.post(
				`${APIUtils.getBaseURL()}/${CLUSTERS_ENDPOINT}/${
					node.clusterID
				}/${NODES_ENDPOINT}`,
				data
			);

			console.log("response", response);
			const createdNode = deattribute(response.data.data) as Node;
			const job = response.data.meta.jobInfo as Job;

			// appStore.dispatch(jobCreated(job));

			return { job: { ...job }, node: { ...createdNode } };
		} catch (e) {
			throw new Error(APIUtils.APIErrorResponseHandler(e));
		}

		// return await Axios.post(
		// 	`${AppEnvironment.getApiAddress()}/${AppEnvironment.getApiVersion()}/nodes`,
		// 	node,
		// 	{
		// 		transformRequest: (requestData: Node) => {
		// 			// console.log("requestData", requestData);
		// 			return JSON.stringify({
		// 				...requestData,
		// 				settings: {
		// 					logs: {
		// 						generalLog: {
		// 							enabled: requestData.settings.logs.generalLog.enabled
		// 								? "ON"
		// 								: "OFF"
		// 						}
		// 					}
		// 				}
		// 			});
		// 		}
		// 	}
		// );
	}

	static async start(node: Node): Promise<Job> {
		console.log("start node", node);
		try {
			const response = await Axios.post(
				`${AppEnvironment.getApiAddress()}/${AppEnvironment.getApiVersion()}/tasks`,
				{
					data: {
						attributes: {
							data: {
								clusterID: node.clusterID,
								nodeID: node.id
							},
							type: "node-start"
						},
						type: "tasks"
					}
				}
			);

			const job = response.data.meta.jobInfo as Job;
			// appStore.dispatch(jobCreated(job));

			return job;
		} catch (e) {
			throw new Error(APIUtils.APIErrorResponseHandler(e));
		}
	}

	static async stop(node: Node): Promise<Job> {
		try {
			const response = await Axios.post(
				`${AppEnvironment.getApiAddress()}/${AppEnvironment.getApiVersion()}/tasks`,
				{
					data: {
						attributes: {
							data: {
								clusterID: node.clusterID,
								nodeID: node.id
							},
							type: "node-stop"
						},
						type: "tasks"
					}
				}
			);

			const job = response.data.meta.jobInfo as Job;
			// appStore.dispatch(jobCreated(job));

			return job;
		} catch (e) {
			throw new Error(APIUtils.APIErrorResponseHandler(e));
		}
	}

	static async toggleGeneralLog(node: Node): Promise<Job> {
		// console.log("toggle", node);
		try {
			const response = await Axios.post(
				`${AppEnvironment.getApiAddress()}/${AppEnvironment.getApiVersion()}/tasks`,
				{
					data: {
						attributes: {
							data: {
								clusterID: node.clusterID,
								logSettings: {
									enabled: !node.settings.logs.generalLog.enabled,
									path: node.settings.logs.generalLog.path
								},
								nodeID: node.id
							},
							type: "node-general-log-settings"
						},
						type: "tasks"
					}
				}
			);

			const job = response.data.meta.jobInfo as Job;
			// appStore.dispatch(jobCreated(job));

			return job;
		} catch (e) {
			throw new Error(APIUtils.APIErrorResponseHandler(e));
		}
	}
}

export default NodesAPI;
