import { ChartMetric } from "components/monitoring/charts/const";

export const AVAILABLE_METRICS: ChartMetric[] = [
	// NODE metrics
	{ name: "aborted_clients", table: "mysql" },
	{ name: "aborted_connects", table: "mysql" },
	{ name: "access_denied_errors", table: "mysql" },
	{ name: "acl_column_grants", table: "mysql" },
	{ name: "acl_database_grants", table: "mysql" },
	{ name: "acl_function_grants", table: "mysql" },
	{ name: "acl_package_body_grants", table: "mysql" },
	{ name: "acl_package_spec_grants", table: "mysql" },
	{ name: "acl_procedure_grants", table: "mysql" },
	{ name: "acl_proxy_users", table: "mysql" },
	{ name: "acl_role_grants", table: "mysql" },
	{ name: "acl_roles", table: "mysql" },
	{ name: "acl_table_grants", table: "mysql" },
	{ name: "acl_users", table: "mysql" },
	{ name: "aria_pagecache_blocks_not_flushed", table: "mysql" },
	{ name: "aria_pagecache_blocks_unused", table: "mysql" },
	{ name: "aria_pagecache_blocks_used", table: "mysql" },
	{ name: "aria_pagecache_read_requests", table: "mysql" },
	{ name: "aria_pagecache_reads", table: "mysql" },
	{ name: "aria_pagecache_write_requests", table: "mysql" },
	{ name: "aria_pagecache_writes", table: "mysql" },
	{ name: "aria_transaction_log_syncs", table: "mysql" },
	{ name: "binlog_bytes_written", table: "mysql" },
	{ name: "binlog_cache_disk_use", table: "mysql" },
	{ name: "binlog_cache_use", table: "mysql" },
	{ name: "binlog_commits", table: "mysql" },
	{ name: "binlog_group_commit_trigger_count", table: "mysql" },
	{ name: "binlog_group_commit_trigger_lock_wait", table: "mysql" },
	{ name: "binlog_group_commit_trigger_timeout", table: "mysql" },
	{ name: "binlog_group_commits", table: "mysql" },
	{ name: "binlog_snapshot_position", table: "mysql" },
	{ name: "binlog_stmt_cache_disk_use", table: "mysql" },
	{ name: "binlog_stmt_cache_use", table: "mysql" },
	{ name: "busy_time", table: "mysql" },
	{ name: "bytes_received", table: "mysql" },
	{ name: "bytes_sent", table: "mysql" },
	{ name: "column_compressions", table: "mysql" },
	{ name: "column_decompressions", table: "mysql" },
	{ name: "com_admin_commands", table: "mysql" },
	{ name: "com_alter_db", table: "mysql" },
	{ name: "com_alter_db_upgrade", table: "mysql" },
	{ name: "com_alter_event", table: "mysql" },
	{ name: "com_alter_function", table: "mysql" },
	{ name: "com_alter_procedure", table: "mysql" },
	{ name: "com_alter_sequence", table: "mysql" },
	{ name: "com_alter_server", table: "mysql" },
	{ name: "com_alter_table", table: "mysql" },
	{ name: "com_alter_tablespace", table: "mysql" },
	{ name: "com_alter_user", table: "mysql" },
	{ name: "com_analyze", table: "mysql" },
	{ name: "com_assign_to_keycache", table: "mysql" },
	{ name: "com_begin", table: "mysql" },
	{ name: "com_binlog", table: "mysql" },
	{ name: "com_call_procedure", table: "mysql" },
	{ name: "com_change_db", table: "mysql" },
	{ name: "com_change_master", table: "mysql" },
	{ name: "com_check", table: "mysql" },
	{ name: "com_checksum", table: "mysql" },
	{ name: "com_commit", table: "mysql" },
	{ name: "com_compound_sql", table: "mysql" },
	{ name: "com_create_db", table: "mysql" },
	{ name: "com_create_event", table: "mysql" },
	{ name: "com_create_function", table: "mysql" },
	{ name: "com_create_index", table: "mysql" },
	{ name: "com_create_package", table: "mysql" },
	{ name: "com_create_package_body", table: "mysql" },
	{ name: "com_create_procedure", table: "mysql" },
	{ name: "com_create_role", table: "mysql" },
	{ name: "com_create_sequence", table: "mysql" },
	{ name: "com_create_server", table: "mysql" },
	{ name: "com_create_table", table: "mysql" },
	{ name: "com_create_temporary_table", table: "mysql" },
	{ name: "com_create_trigger", table: "mysql" },
	{ name: "com_create_udf", table: "mysql" },
	{ name: "com_create_user", table: "mysql" },
	{ name: "com_create_view", table: "mysql" },
	{ name: "com_dealloc_sql", table: "mysql" },
	{ name: "com_delete", table: "mysql" },
	{ name: "com_delete_multi", table: "mysql" },
	{ name: "com_do", table: "mysql" },
	{ name: "com_drop_db", table: "mysql" },
	{ name: "com_drop_event", table: "mysql" },
	{ name: "com_drop_function", table: "mysql" },
	{ name: "com_drop_index", table: "mysql" },
	{ name: "com_drop_package", table: "mysql" },
	{ name: "com_drop_package_body", table: "mysql" },
	{ name: "com_drop_procedure", table: "mysql" },
	{ name: "com_drop_role", table: "mysql" },
	{ name: "com_drop_sequence", table: "mysql" },
	{ name: "com_drop_server", table: "mysql" },
	{ name: "com_drop_table", table: "mysql" },
	{ name: "com_drop_temporary_table", table: "mysql" },
	{ name: "com_drop_trigger", table: "mysql" },
	{ name: "com_drop_user", table: "mysql" },
	{ name: "com_drop_view", table: "mysql" },
	{ name: "com_empty_query", table: "mysql" },
	{ name: "com_execute_immediate", table: "mysql" },
	{ name: "com_execute_sql", table: "mysql" },
	{ name: "com_flush", table: "mysql" },
	{ name: "com_get_diagnostics", table: "mysql" },
	{ name: "com_grant", table: "mysql" },
	{ name: "com_grant_role", table: "mysql" },
	{ name: "com_ha_close", table: "mysql" },
	{ name: "com_ha_open", table: "mysql" },
	{ name: "com_ha_read", table: "mysql" },
	{ name: "com_help", table: "mysql" },
	{ name: "com_insert", table: "mysql" },
	{ name: "com_insert_select", table: "mysql" },
	{ name: "com_install_plugin", table: "mysql" },
	{ name: "com_kill", table: "mysql" },
	{ name: "com_load", table: "mysql" },
	{ name: "com_lock_tables", table: "mysql" },
	{ name: "com_multi", table: "mysql" },
	{ name: "com_optimize", table: "mysql" },
	{ name: "com_preload_keys", table: "mysql" },
	{ name: "com_prepare_sql", table: "mysql" },
	{ name: "com_purge", table: "mysql" },
	{ name: "com_purge_before_date", table: "mysql" },
	{ name: "com_release_savepoint", table: "mysql" },
	{ name: "com_rename_table", table: "mysql" },
	{ name: "com_rename_user", table: "mysql" },
	{ name: "com_repair", table: "mysql" },
	{ name: "com_replace", table: "mysql" },
	{ name: "com_replace_select", table: "mysql" },
	{ name: "com_reset", table: "mysql" },
	{ name: "com_resignal", table: "mysql" },
	{ name: "com_revoke", table: "mysql" },
	{ name: "com_revoke_all", table: "mysql" },
	{ name: "com_revoke_role", table: "mysql" },
	{ name: "com_rollback", table: "mysql" },
	{ name: "com_rollback_to_savepoint", table: "mysql" },
	{ name: "com_savepoint", table: "mysql" },
	{ name: "com_select", table: "mysql" },
	{ name: "com_set_option", table: "mysql" },
	{ name: "com_show_authors", table: "mysql" },
	{ name: "com_show_binlog_events", table: "mysql" },
	{ name: "com_show_binlogs", table: "mysql" },
	{ name: "com_show_charsets", table: "mysql" },
	{ name: "com_show_collations", table: "mysql" },
	{ name: "com_show_contributors", table: "mysql" },
	{ name: "com_show_create_db", table: "mysql" },
	{ name: "com_show_create_event", table: "mysql" },
	{ name: "com_show_create_func", table: "mysql" },
	{ name: "com_show_create_package", table: "mysql" },
	{ name: "com_show_create_package_body", table: "mysql" },
	{ name: "com_show_create_proc", table: "mysql" },
	{ name: "com_show_create_table", table: "mysql" },
	{ name: "com_show_create_trigger", table: "mysql" },
	{ name: "com_show_create_user", table: "mysql" },
	{ name: "com_show_databases", table: "mysql" },
	{ name: "com_show_engine_logs", table: "mysql" },
	{ name: "com_show_engine_mutex", table: "mysql" },
	{ name: "com_show_engine_status", table: "mysql" },
	{ name: "com_show_errors", table: "mysql" },
	{ name: "com_show_events", table: "mysql" },
	{ name: "com_show_explain", table: "mysql" },
	{ name: "com_show_fields", table: "mysql" },
	{ name: "com_show_function_status", table: "mysql" },
	{ name: "com_show_generic", table: "mysql" },
	{ name: "com_show_grants", table: "mysql" },
	{ name: "com_show_keys", table: "mysql" },
	{ name: "com_show_master_status", table: "mysql" },
	{ name: "com_show_open_tables", table: "mysql" },
	{ name: "com_show_package_body_status", table: "mysql" },
	{ name: "com_show_package_status", table: "mysql" },
	{ name: "com_show_plugins", table: "mysql" },
	{ name: "com_show_privileges", table: "mysql" },
	{ name: "com_show_procedure_status", table: "mysql" },
	{ name: "com_show_processlist", table: "mysql" },
	{ name: "com_show_profile", table: "mysql" },
	{ name: "com_show_profiles", table: "mysql" },
	{ name: "com_show_relaylog_events", table: "mysql" },
	{ name: "com_show_slave_hosts", table: "mysql" },
	{ name: "com_show_slave_status", table: "mysql" },
	{ name: "com_show_status", table: "mysql" },
	{ name: "com_show_storage_engines", table: "mysql" },
	{ name: "com_show_table_status", table: "mysql" },
	{ name: "com_show_tables", table: "mysql" },
	{ name: "com_show_triggers", table: "mysql" },
	{ name: "com_show_variables", table: "mysql" },
	{ name: "com_show_warnings", table: "mysql" },
	{ name: "com_shutdown", table: "mysql" },
	{ name: "com_signal", table: "mysql" },
	{ name: "com_start_all_slaves", table: "mysql" },
	{ name: "com_start_slave", table: "mysql" },
	{ name: "com_stmt_close", table: "mysql" },
	{ name: "com_stmt_execute", table: "mysql" },
	{ name: "com_stmt_fetch", table: "mysql" },
	{ name: "com_stmt_prepare", table: "mysql" },
	{ name: "com_stmt_reprepare", table: "mysql" },
	{ name: "com_stmt_reset", table: "mysql" },
	{ name: "com_stmt_send_long_data", table: "mysql" },
	{ name: "com_stop_all_slaves", table: "mysql" },
	{ name: "com_stop_slave", table: "mysql" },
	{ name: "com_truncate", table: "mysql" },
	{ name: "com_uninstall_plugin", table: "mysql" },
	{ name: "com_unlock_tables", table: "mysql" },
	{ name: "com_update", table: "mysql" },
	{ name: "com_update_multi", table: "mysql" },
	{ name: "com_xa_commit", table: "mysql" },
	{ name: "com_xa_end", table: "mysql" },
	{ name: "com_xa_prepare", table: "mysql" },
	{ name: "com_xa_recover", table: "mysql" },
	{ name: "com_xa_rollback", table: "mysql" },
	{ name: "com_xa_start", table: "mysql" },
	{ name: "compression", table: "mysql" },
	{ name: "connection_errors_accept", table: "mysql" },
	{ name: "connection_errors_internal", table: "mysql" },
	{ name: "connection_errors_max_connections", table: "mysql" },
	{ name: "connection_errors_peer_address", table: "mysql" },
	{ name: "connection_errors_select", table: "mysql" },
	{ name: "connection_errors_tcpwrap", table: "mysql" },
	{ name: "connections", table: "mysql" },
	{ name: "cpu_time", table: "mysql" },
	{ name: "created_tmp_disk_tables", table: "mysql" },
	{ name: "created_tmp_files", table: "mysql" },
	{ name: "created_tmp_tables", table: "mysql" },
	{ name: "delayed_errors", table: "mysql" },
	{ name: "delayed_insert_threads", table: "mysql" },
	{ name: "delayed_writes", table: "mysql" },
	{ name: "delete_scan", table: "mysql" },
	{ name: "empty_queries", table: "mysql" },
	{ name: "executed_events", table: "mysql" },
	{ name: "executed_triggers", table: "mysql" },
	{ name: "feature_check_constraint", table: "mysql" },
	{ name: "feature_custom_aggregate_functions", table: "mysql" },
	{ name: "feature_delay_key_write", table: "mysql" },
	{ name: "feature_dynamic_columns", table: "mysql" },
	{ name: "feature_fulltext", table: "mysql" },
	{ name: "feature_gis", table: "mysql" },
	{ name: "feature_invisible_columns", table: "mysql" },
	{ name: "feature_json", table: "mysql" },
	{ name: "feature_locale", table: "mysql" },
	{ name: "feature_subquery", table: "mysql" },
	{ name: "feature_system_versioning", table: "mysql" },
	{ name: "feature_timezone", table: "mysql" },
	{ name: "feature_trigger", table: "mysql" },
	{ name: "feature_window_functions", table: "mysql" },
	{ name: "feature_xml", table: "mysql" },
	{ name: "flush_commands", table: "mysql" },
	{ name: "handler_commit", table: "mysql" },
	{ name: "handler_delete", table: "mysql" },
	{ name: "handler_discover", table: "mysql" },
	{ name: "handler_external_lock", table: "mysql" },
	{ name: "handler_icp_attempts", table: "mysql" },
	{ name: "handler_icp_match", table: "mysql" },
	{ name: "handler_mrr_init", table: "mysql" },
	{ name: "handler_mrr_key_refills", table: "mysql" },
	{ name: "handler_mrr_rowid_refills", table: "mysql" },
	{ name: "handler_prepare", table: "mysql" },
	{ name: "handler_read_first", table: "mysql" },
	{ name: "handler_read_key", table: "mysql" },
	{ name: "handler_read_last", table: "mysql" },
	{ name: "handler_read_next", table: "mysql" },
	{ name: "handler_read_prev", table: "mysql" },
	{ name: "handler_read_retry", table: "mysql" },
	{ name: "handler_read_rnd", table: "mysql" },
	{ name: "handler_read_rnd_deleted", table: "mysql" },
	{ name: "handler_read_rnd_next", table: "mysql" },
	{ name: "handler_rollback", table: "mysql" },
	{ name: "handler_savepoint", table: "mysql" },
	{ name: "handler_savepoint_rollback", table: "mysql" },
	{ name: "handler_tmp_delete", table: "mysql" },
	{ name: "handler_tmp_update", table: "mysql" },
	{ name: "handler_tmp_write", table: "mysql" },
	{ name: "handler_update", table: "mysql" },
	{ name: "handler_write", table: "mysql" },
	{ name: "innodb_available_undo_logs", table: "mysql" },
	{ name: "innodb_buffer_pool_bytes_data", table: "mysql" },
	{ name: "innodb_buffer_pool_bytes_dirty", table: "mysql" },
	{ name: "innodb_buffer_pool_load_incomplete", table: "mysql" },
	{ name: "innodb_buffer_pool_load_status", table: "mysql" },
	{ name: "innodb_buffer_pool_pages_data", table: "mysql" },
	{ name: "innodb_buffer_pool_pages_dirty", table: "mysql" },
	{ name: "innodb_buffer_pool_pages_flushed", table: "mysql" },
	{ name: "innodb_buffer_pool_pages_free", table: "mysql" },
	{ name: "innodb_buffer_pool_pages_misc", table: "mysql" },
	{ name: "innodb_buffer_pool_pages_total", table: "mysql" },
	{ name: "innodb_buffer_pool_read_ahead", table: "mysql" },
	{ name: "innodb_buffer_pool_read_ahead_evicted", table: "mysql" },
	{ name: "innodb_buffer_pool_read_ahead_rnd", table: "mysql" },
	{ name: "innodb_buffer_pool_read_requests", table: "mysql" },
	{ name: "innodb_buffer_pool_reads", table: "mysql" },
	{ name: "innodb_buffer_pool_wait_free", table: "mysql" },
	{ name: "innodb_buffer_pool_write_requests", table: "mysql" },
	{ name: "innodb_data_fsyncs", table: "mysql" },
	{ name: "innodb_data_pending_fsyncs", table: "mysql" },
	{ name: "innodb_data_pending_reads", table: "mysql" },
	{ name: "innodb_data_pending_writes", table: "mysql" },
	{ name: "innodb_data_read", table: "mysql" },
	{ name: "innodb_data_reads", table: "mysql" },
	{ name: "innodb_data_writes", table: "mysql" },
	{ name: "innodb_data_written", table: "mysql" },
	{ name: "innodb_dblwr_pages_written", table: "mysql" },
	{ name: "innodb_dblwr_writes", table: "mysql" },
	{ name: "innodb_defragment_compression_failures", table: "mysql" },
	{ name: "innodb_defragment_count", table: "mysql" },
	{ name: "innodb_defragment_failures", table: "mysql" },
	{ name: "innodb_encryption_key_rotation_list_length", table: "mysql" },
	{ name: "innodb_encryption_n_merge_blocks_decrypted", table: "mysql" },
	{ name: "innodb_encryption_n_merge_blocks_encrypted", table: "mysql" },
	{ name: "innodb_encryption_n_rowlog_blocks_decrypted", table: "mysql" },
	{ name: "innodb_encryption_n_rowlog_blocks_encrypted", table: "mysql" },
	{ name: "innodb_encryption_n_temp_blocks_decrypted", table: "mysql" },
	{ name: "innodb_encryption_n_temp_blocks_encrypted", table: "mysql" },
	{ name: "innodb_encryption_num_key_requests", table: "mysql" },
	{ name: "innodb_encryption_rotation_estimated_iops", table: "mysql" },
	{ name: "innodb_encryption_rotation_pages_flushed", table: "mysql" },
	{ name: "innodb_encryption_rotation_pages_modified", table: "mysql" },
	{ name: "innodb_encryption_rotation_pages_read_from_cache", table: "mysql" },
	{ name: "innodb_encryption_rotation_pages_read_from_disk", table: "mysql" },
	{ name: "innodb_have_bzip2", table: "mysql" },
	{ name: "innodb_have_lz4", table: "mysql" },
	{ name: "innodb_have_lzma", table: "mysql" },
	{ name: "innodb_have_lzo", table: "mysql" },
	{ name: "innodb_have_punch_hole", table: "mysql" },
	{ name: "innodb_have_snappy", table: "mysql" },
	{ name: "innodb_instant_alter_column", table: "mysql" },
	{ name: "innodb_log_waits", table: "mysql" },
	{ name: "innodb_log_write_requests", table: "mysql" },
	{ name: "innodb_log_writes", table: "mysql" },
	{ name: "innodb_num_index_pages_written", table: "mysql" },
	{ name: "innodb_num_non_index_pages_written", table: "mysql" },
	{ name: "innodb_num_open_files", table: "mysql" },
	{ name: "innodb_num_page_compressed_trim_op", table: "mysql" },
	{ name: "innodb_num_pages_decrypted", table: "mysql" },
	{ name: "innodb_num_pages_encrypted", table: "mysql" },
	{ name: "innodb_num_pages_page_compressed", table: "mysql" },
	{ name: "innodb_num_pages_page_compression_error", table: "mysql" },
	{ name: "innodb_num_pages_page_decompressed", table: "mysql" },
	{ name: "innodb_onlineddl_pct_progress", table: "mysql" },
	{ name: "innodb_onlineddl_rowlog_pct_used", table: "mysql" },
	{ name: "innodb_onlineddl_rowlog_rows", table: "mysql" },
	{ name: "innodb_os_log_fsyncs", table: "mysql" },
	{ name: "innodb_os_log_pending_fsyncs", table: "mysql" },
	{ name: "innodb_os_log_pending_writes", table: "mysql" },
	{ name: "innodb_os_log_written", table: "mysql" },
	{ name: "innodb_page_compression_saved", table: "mysql" },
	{ name: "innodb_page_size", table: "mysql" },
	{ name: "innodb_pages0_read", table: "mysql" },
	{ name: "innodb_pages_created", table: "mysql" },
	{ name: "innodb_pages_read", table: "mysql" },
	{ name: "innodb_pages_written", table: "mysql" },
	{ name: "innodb_row_lock_current_waits", table: "mysql" },
	{ name: "innodb_row_lock_time", table: "mysql" },
	{ name: "innodb_row_lock_time_avg", table: "mysql" },
	{ name: "innodb_row_lock_time_max", table: "mysql" },
	{ name: "innodb_row_lock_waits", table: "mysql" },
	{ name: "innodb_rows_deleted", table: "mysql" },
	{ name: "innodb_rows_inserted", table: "mysql" },
	{ name: "innodb_rows_read", table: "mysql" },
	{ name: "innodb_rows_updated", table: "mysql" },
	{ name: "innodb_scrub_background_page_reorganizations", table: "mysql" },
	{
		name: "innodb_scrub_background_page_split_failures_missing_index",
		table: "mysql"
	},
	{
		name: "innodb_scrub_background_page_split_failures_out_of_filespace",
		table: "mysql"
	},
	{
		name: "innodb_scrub_background_page_split_failures_underflow",
		table: "mysql"
	},
	{
		name: "innodb_scrub_background_page_split_failures_unknown",
		table: "mysql"
	},
	{ name: "innodb_scrub_background_page_splits", table: "mysql" },
	{ name: "innodb_scrub_log", table: "mysql" },
	{ name: "innodb_secondary_index_triggered_cluster_reads", table: "mysql" },
	{
		name: "innodb_secondary_index_triggered_cluster_reads_avoided",
		table: "mysql"
	},
	{ name: "innodb_system_rows_deleted", table: "mysql" },
	{ name: "innodb_system_rows_inserted", table: "mysql" },
	{ name: "innodb_system_rows_read", table: "mysql" },
	{ name: "innodb_system_rows_updated", table: "mysql" },
	{ name: "innodb_truncated_status_writes", table: "mysql" },
	{ name: "innodb_undo_truncations", table: "mysql" },
	{ name: "key_blocks_not_flushed", table: "mysql" },
	{ name: "key_blocks_unused", table: "mysql" },
	{ name: "key_blocks_used", table: "mysql" },
	{ name: "key_blocks_warm", table: "mysql" },
	{ name: "key_read_requests", table: "mysql" },
	{ name: "key_reads", table: "mysql" },
	{ name: "key_write_requests", table: "mysql" },
	{ name: "key_writes", table: "mysql" },
	{ name: "last_query_cost", table: "mysql" },
	{ name: "master_gtid_wait_count", table: "mysql" },
	{ name: "master_gtid_wait_time", table: "mysql" },
	{ name: "master_gtid_wait_timeouts", table: "mysql" },
	{ name: "max_statement_time_exceeded", table: "mysql" },
	{ name: "max_used_connections", table: "mysql" },
	{ name: "memory_used", table: "mysql" },
	{ name: "memory_used_initial", table: "mysql" },
	{ name: "not_flushed_delayed_rows", table: "mysql" },
	{ name: "open_files", table: "mysql" },
	{ name: "open_streams", table: "mysql" },
	{ name: "open_table_definitions", table: "mysql" },
	{ name: "open_tables", table: "mysql" },
	{ name: "opened_files", table: "mysql" },
	{ name: "opened_plugin_libraries", table: "mysql" },
	{ name: "opened_table_definitions", table: "mysql" },
	{ name: "opened_tables", table: "mysql" },
	{ name: "opened_views", table: "mysql" },
	{ name: "performance_schema_accounts_lost", table: "mysql" },
	{ name: "performance_schema_cond_classes_lost", table: "mysql" },
	{ name: "performance_schema_cond_instances_lost", table: "mysql" },
	{ name: "performance_schema_digest_lost", table: "mysql" },
	{ name: "performance_schema_file_classes_lost", table: "mysql" },
	{ name: "performance_schema_file_handles_lost", table: "mysql" },
	{ name: "performance_schema_file_instances_lost", table: "mysql" },
	{ name: "performance_schema_hosts_lost", table: "mysql" },
	{ name: "performance_schema_locker_lost", table: "mysql" },
	{ name: "performance_schema_mutex_classes_lost", table: "mysql" },
	{ name: "performance_schema_mutex_instances_lost", table: "mysql" },
	{ name: "performance_schema_rwlock_classes_lost", table: "mysql" },
	{ name: "performance_schema_rwlock_instances_lost", table: "mysql" },
	{ name: "performance_schema_session_connect_attrs_lost", table: "mysql" },
	{ name: "performance_schema_socket_classes_lost", table: "mysql" },
	{ name: "performance_schema_socket_instances_lost", table: "mysql" },
	{ name: "performance_schema_stage_classes_lost", table: "mysql" },
	{ name: "performance_schema_statement_classes_lost", table: "mysql" },
	{ name: "performance_schema_table_handles_lost", table: "mysql" },
	{ name: "performance_schema_table_instances_lost", table: "mysql" },
	{ name: "performance_schema_thread_classes_lost", table: "mysql" },
	{ name: "performance_schema_thread_instances_lost", table: "mysql" },
	{ name: "performance_schema_users_lost", table: "mysql" },
	{ name: "prepared_stmt_count", table: "mysql" },
	{ name: "qcache_free_blocks", table: "mysql" },
	{ name: "qcache_free_memory", table: "mysql" },
	{ name: "qcache_hits", table: "mysql" },
	{ name: "qcache_inserts", table: "mysql" },
	{ name: "qcache_lowmem_prunes", table: "mysql" },
	{ name: "qcache_not_cached", table: "mysql" },
	{ name: "qcache_queries_in_cache", table: "mysql" },
	{ name: "qcache_total_blocks", table: "mysql" },
	{ name: "queries", table: "mysql" },
	{ name: "questions", table: "mysql" },
	{ name: "rows_read", table: "mysql" },
	{ name: "rows_sent", table: "mysql" },
	{ name: "rows_tmp_read", table: "mysql" },
	{ name: "rpl_semi_sync_master_clients", table: "mysql" },
	{ name: "rpl_semi_sync_master_get_ack", table: "mysql" },
	{ name: "rpl_semi_sync_master_net_avg_wait_time", table: "mysql" },
	{ name: "rpl_semi_sync_master_net_wait_time", table: "mysql" },
	{ name: "rpl_semi_sync_master_net_waits", table: "mysql" },
	{ name: "rpl_semi_sync_master_no_times", table: "mysql" },
	{ name: "rpl_semi_sync_master_no_tx", table: "mysql" },
	{ name: "rpl_semi_sync_master_request_ack", table: "mysql" },
	{ name: "rpl_semi_sync_master_status", table: "mysql" },
	{ name: "rpl_semi_sync_master_timefunc_failures", table: "mysql" },
	{ name: "rpl_semi_sync_master_tx_avg_wait_time", table: "mysql" },
	{ name: "rpl_semi_sync_master_tx_wait_time", table: "mysql" },
	{ name: "rpl_semi_sync_master_tx_waits", table: "mysql" },
	{ name: "rpl_semi_sync_master_wait_pos_backtraverse", table: "mysql" },
	{ name: "rpl_semi_sync_master_wait_sessions", table: "mysql" },
	{ name: "rpl_semi_sync_master_yes_tx", table: "mysql" },
	{ name: "rpl_semi_sync_slave_send_ack", table: "mysql" },
	{ name: "rpl_semi_sync_slave_status", table: "mysql" },
	{ name: "rpl_status", table: "mysql" },
	{ name: "rpl_transactions_multi_engine", table: "mysql" },
	{ name: "select_full_join", table: "mysql" },
	{ name: "select_full_range_join", table: "mysql" },
	{ name: "select_range", table: "mysql" },
	{ name: "select_range_check", table: "mysql" },
	{ name: "select_scan", table: "mysql" },
	{ name: "slave_connections", table: "mysql" },
	{ name: "slave_heartbeat_period", table: "mysql" },
	{ name: "slave_open_temp_tables", table: "mysql" },
	{ name: "slave_received_heartbeats", table: "mysql" },
	{ name: "slave_retried_transactions", table: "mysql" },
	{ name: "slave_running", table: "mysql" },
	{ name: "slave_skipped_errors", table: "mysql" },
	{ name: "slaves_connected", table: "mysql" },
	{ name: "slaves_running", table: "mysql" },
	{ name: "slow_launch_threads", table: "mysql" },
	{ name: "slow_queries", table: "mysql" },
	{ name: "sort_merge_passes", table: "mysql" },
	{ name: "sort_priority_queue_sorts", table: "mysql" },
	{ name: "sort_range", table: "mysql" },
	{ name: "sort_rows", table: "mysql" },
	{ name: "sort_scan", table: "mysql" },
	{ name: "ssl_accept_renegotiates", table: "mysql" },
	{ name: "ssl_accepts", table: "mysql" },
	{ name: "ssl_callback_cache_hits", table: "mysql" },
	{ name: "ssl_client_connects", table: "mysql" },
	{ name: "ssl_connect_renegotiates", table: "mysql" },
	{ name: "ssl_ctx_verify_depth", table: "mysql" },
	{ name: "ssl_ctx_verify_mode", table: "mysql" },
	{ name: "ssl_default_timeout", table: "mysql" },
	{ name: "ssl_finished_accepts", table: "mysql" },
	{ name: "ssl_finished_connects", table: "mysql" },
	{ name: "ssl_session_cache_hits", table: "mysql" },
	{ name: "ssl_session_cache_misses", table: "mysql" },
	{ name: "ssl_session_cache_mode", table: "mysql" },
	{ name: "ssl_session_cache_overflows", table: "mysql" },
	{ name: "ssl_session_cache_size", table: "mysql" },
	{ name: "ssl_session_cache_timeouts", table: "mysql" },
	{ name: "ssl_sessions_reused", table: "mysql" },
	{ name: "ssl_used_session_cache_entries", table: "mysql" },
	{ name: "ssl_verify_depth", table: "mysql" },
	{ name: "ssl_verify_mode", table: "mysql" },
	{ name: "subquery_cache_hit", table: "mysql" },
	{ name: "subquery_cache_miss", table: "mysql" },
	{ name: "syncs", table: "mysql" },
	{ name: "table_locks_immediate", table: "mysql" },
	{ name: "table_locks_waited", table: "mysql" },
	{ name: "table_open_cache_active_instances", table: "mysql" },
	{ name: "table_open_cache_hits", table: "mysql" },
	{ name: "table_open_cache_misses", table: "mysql" },
	{ name: "table_open_cache_overflows", table: "mysql" },
	{ name: "tc_log_max_pages_used", table: "mysql" },
	{ name: "tc_log_page_size", table: "mysql" },
	{ name: "tc_log_page_waits", table: "mysql" },
	{ name: "threadpool_idle_threads", table: "mysql" },
	{ name: "threadpool_threads", table: "mysql" },
	{ name: "threads_cached", table: "mysql" },
	{ name: "threads_connected", table: "mysql" },
	{ name: "threads_created", table: "mysql" },
	{ name: "threads_running", table: "mysql" },
	{ name: "transactions_gtid_foreign_engine", table: "mysql" },
	{ name: "transactions_multi_engine", table: "mysql" },
	{ name: "update_scan", table: "mysql" },
	{ name: "uptime", table: "mysql" },
	{ name: "uptime_since_flush_status", table: "mysql" },
	{ name: "wsrep_applier_thread_count", table: "mysql" },
	{ name: "wsrep_apply_oooe", table: "mysql" },
	{ name: "wsrep_apply_oool", table: "mysql" },
	{ name: "wsrep_apply_window", table: "mysql" },
	{ name: "wsrep_causal_reads", table: "mysql" },
	{ name: "wsrep_cert_deps_distance", table: "mysql" },
	{ name: "wsrep_cert_index_size", table: "mysql" },
	{ name: "wsrep_cert_interval", table: "mysql" },
	{ name: "wsrep_cluster_conf_id", table: "mysql" },
	{ name: "wsrep_cluster_size", table: "mysql" },
	{ name: "wsrep_cluster_state_uuid", table: "mysql" },
	{ name: "wsrep_cluster_status", table: "mysql" },
	{ name: "wsrep_cluster_weight", table: "mysql" },
	{ name: "wsrep_commit_oooe", table: "mysql" },
	{ name: "wsrep_commit_oool", table: "mysql" },
	{ name: "wsrep_commit_window", table: "mysql" },
	{ name: "wsrep_connected", table: "mysql" },
	{ name: "wsrep_desync_count", table: "mysql" },
	{ name: "wsrep_evs_repl_latency", table: "mysql" },
	{ name: "wsrep_evs_state", table: "mysql" },
	{ name: "wsrep_flow_control_paused", table: "mysql" },
	{ name: "wsrep_flow_control_paused_ns", table: "mysql" },
	{ name: "wsrep_flow_control_recv", table: "mysql" },
	{ name: "wsrep_flow_control_sent", table: "mysql" },
	{ name: "wsrep_gcomm_uuid", table: "mysql" },
	{ name: "wsrep_incoming_addresses", table: "mysql" },
	{ name: "wsrep_last_committed", table: "mysql" },
	{ name: "wsrep_local_bf_aborts", table: "mysql" },
	{ name: "wsrep_local_cached_downto", table: "mysql" },
	{ name: "wsrep_local_cached_downto", table: "mysql" },
	{ name: "wsrep_local_cert_failures", table: "mysql" },
	{ name: "wsrep_local_commits", table: "mysql" },
	{ name: "wsrep_local_index", table: "mysql" },
	{ name: "wsrep_local_recv_queue", table: "mysql" },
	{ name: "wsrep_local_recv_queue_avg", table: "mysql" },
	{ name: "wsrep_local_recv_queue_max", table: "mysql" },
	{ name: "wsrep_local_recv_queue_min", table: "mysql" },
	{ name: "wsrep_local_replays", table: "mysql" },
	{ name: "wsrep_local_send_queue", table: "mysql" },
	{ name: "wsrep_local_send_queue_avg", table: "mysql" },
	{ name: "wsrep_local_send_queue_max", table: "mysql" },
	{ name: "wsrep_local_send_queue_min", table: "mysql" },
	{ name: "wsrep_local_state", table: "mysql" },
	{ name: "wsrep_local_state_comment", table: "mysql" },
	{ name: "wsrep_local_state_uuid", table: "mysql" },
	{ name: "wsrep_open_connections", table: "mysql" },
	{ name: "wsrep_open_transactions", table: "mysql" },
	{ name: "wsrep_protocol_version", table: "mysql" },
	{ name: "wsrep_provider_name", table: "mysql" },
	{ name: "wsrep_provider_vendor", table: "mysql" },
	{ name: "wsrep_provider_version", table: "mysql" },
	{ name: "wsrep_ready", table: "mysql" },
	{ name: "wsrep_received", table: "mysql" },
	{ name: "wsrep_received_bytes", table: "mysql" },
	{ name: "wsrep_repl_data_bytes", table: "mysql" },
	{ name: "wsrep_repl_keys", table: "mysql" },
	{ name: "wsrep_repl_keys_bytes", table: "mysql" },
	{ name: "wsrep_repl_other_bytes", table: "mysql" },
	{ name: "wsrep_replicated", table: "mysql" },
	{ name: "wsrep_replicated_bytes", table: "mysql" },
	{ name: "wsrep_rollbacker_thread_count", table: "mysql" },
	{ name: "wsrep_thread_count", table: "mysql" },
	// HOST metrics
	{ name: "usage_guest", table: "cpu" },
	{ name: "usage_guest_nice", table: "cpu" },
	{ name: "usage_idle", table: "cpu" },
	{ name: "usage_iowait", table: "cpu" },
	{ name: "usage_irq", table: "cpu" },
	{ name: "usage_nice", table: "cpu" },
	{ name: "usage_softirq", table: "cpu" },
	{ name: "usage_steal", table: "cpu" },
	{ name: "usage_system", table: "cpu" },
	{ name: "usage_user", table: "cpu" },
	{ name: "child_major_faults", table: "procstat" },
	{ name: "child_minor_faults", table: "procstat" },
	{ name: "cpu_time_guest", table: "procstat" },
	{ name: "cpu_time_guest_nice", table: "procstat" },
	{ name: "cpu_time_idle", table: "procstat" },
	{ name: "cpu_time_iowait", table: "procstat" },
	{ name: "cpu_time_irq", table: "procstat" },
	{ name: "cpu_time_nice", table: "procstat" },
	{ name: "cpu_time_soft_irq", table: "procstat" },
	{ name: "cpu_time_steal", table: "procstat" },
	{ name: "cpu_time_system", table: "procstat" },
	{ name: "cpu_time_user", table: "procstat" },
	{ name: "cpu_usage", table: "procstat" },
	{ name: "created_at", table: "procstat" },
	{ name: "involuntary_context_switches", table: "procstat" },
	{ name: "major_faults", table: "procstat" },
	{ name: "memory_data", table: "procstat" },
	{ name: "memory_locked", table: "procstat" },
	{ name: "memory_rss", table: "procstat" },
	{ name: "memory_stack", table: "procstat" },
	{ name: "memory_swap", table: "procstat" },
	{ name: "memory_usage", table: "procstat" },
	{ name: "memory_vms", table: "procstat" },
	{ name: "minor_faults", table: "procstat" },
	{ name: "num_threads", table: "procstat" },
	{ name: "pid", table: "procstat" },
	{ name: "voluntary_context_switches", table: "procstat" },
	{ name: "free", table: "disk" },
	{ name: "inodes_free", table: "disk" },
	{ name: "inodes_total", table: "disk" },
	{ name: "inodes_used", table: "disk" },
	{ name: "total", table: "disk" },
	{ name: "used", table: "disk" },
	{ name: "used_percent", table: "disk" }
];
