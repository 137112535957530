import axios from "axios";
import jwt from "jsonwebtoken";
import { Credentials } from "modules/userSession/types";
import AppEnvironment from "modules/appEnvironment";
import { JwtTokenData } from "modules/auth/types";
import Config from "modules/config/Config";
import { deattribute, serialise } from "kitsu-core";
import { User } from "components/users/types";
import APIUtils from "modules/api/utils";

const ENDPOINT = "login";
const TYPE = "login";

class Auth {
	static verifyJwtToken(token: string): JwtTokenData {
		return jwt.verify(
			token,
			Config.getInstance().jtw_public_token
		) as JwtTokenData;
	}

	static async login(credentials: Credentials): Promise<User> {
		try {
			const data = serialise(TYPE, credentials, "POST");
			const response = await axios.post(APIUtils.getUrl(ENDPOINT), data);
			return deattribute({ ...response.data.data }) as User;
		} catch (e) {
			throw new Error(APIUtils.APIErrorResponseHandler(e));
		}
	}

	static async logout() {
		try {
			await axios.post(APIUtils.getUrl("logout"));
		} catch (e) {
			throw new Error(APIUtils.APIErrorResponseHandler(e));
		}
	}

	static async clearJWTTokens(): Promise<void> {
		setTimeout(() => {
			localStorage.removeItem("wsAccessToken");
			return;
		});
	}

	static async refreshWsAccessToken(): Promise<JwtTokenData> {
		try {
			const response = await axios.post(
				`${AppEnvironment.getApiAddress()}/${AppEnvironment.getApiVersion()}/jwt/access`
			);

			const wsAccessToken = response.data.access.value;
			const wsAccessTokenData = this.verifyJwtToken(wsAccessToken);
			this.setWsAccessToken(wsAccessToken);
			return wsAccessTokenData;
		} catch (errorResponse) {
			let errorMessage = "";
			if (errorResponse.response) {
				errorMessage = `Error ${errorResponse.response.status}: ${errorResponse.response.statusText}`;
			} else {
				errorMessage = `${errorResponse.name}: ${errorResponse.message}`;
			}

			throw Error(errorMessage);
		}
	}

	private static setWsAccessToken(token: string) {
		localStorage.setItem("wsAccessToken", token);
	}

	static getWsAccessToken(): string {
		return localStorage.getItem("wsAccessToken") || "";
	}
}

export default Auth;
