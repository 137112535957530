import React from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Tooltip
} from "@material-ui/core";
import { GMDialogService } from "components/sharedComponents/dialog/DialogService";
import {
	AlertDialog,
	ConfirmDialog
} from "components/sharedComponents/dialog/types";
import { withStyles, WithStyles, WithTheme } from "@material-ui/core/styles";
import { styles } from "./styles";
import LongPress from "modules/LongPress";

interface State {
	isOpen: boolean;
	dialogType: "alert" | "confirm";
	content?: AlertDialog | ConfirmDialog;
}

interface LocalProps {
	// add message handler or observable or some other way of receiving messages
	// it should display message immediately if dialog is closed and queue is empty, else put message into queue
}

type Props = LocalProps & WithStyles<typeof styles> & WithTheme;

class GMDialog extends React.Component<Props, State> {
	// messages queue
	messages: any[] = [];
	longPress: LongPress = new LongPress(2000);

	constructor(props: Props) {
		super(props);

		this.state = {
			isOpen: false,
			dialogType: "alert"
		};
	}

	componentDidMount(): void {
		GMDialogService.alertDialog.subscribe((dialog: AlertDialog) => {
			console.log("new message", dialog);
			this.setState({ isOpen: true, dialogType: "alert", content: dialog });
		});

		GMDialogService.confirmDialog.subscribe((dialog: ConfirmDialog) => {
			console.log("new message", dialog);
			this.setState({ isOpen: true, dialogType: "confirm", content: dialog });
		});
	}

	show = () => {
		this.setState({ isOpen: true });
	};

	close = (confirm: boolean) => {
		if (confirm) {
			this.state.content?.resolve();
		} else {
			if (this.state.dialogType === "confirm") {
				const confirmDialogContent = this.state.content as ConfirmDialog;
				confirmDialogContent?.reject();
			}
		}

		this.setState({ isOpen: false });

		// but also check if there are any messages in queue and show them right after the dialog was closed
	};

	render() {
		const { isOpen, dialogType, content } = this.state;
		const { classes } = this.props;

		let confirmDialogContent;
		if (dialogType === "confirm") {
			confirmDialogContent = content as ConfirmDialog;
		}

		return (
			<Dialog
				onMouseUp={() => {
					this.longPress.mouseUpTriggered();
				}}
				data-testid="dialog-root"
				open={isOpen}
				onClose={this.close}
			>
				{content?.title && (
					<DialogTitle data-testid="dialog-title" id="dialog-title">
						{content?.title}
					</DialogTitle>
				)}
				<DialogContent>
					<DialogContentText data-testid="dialog-content" id="dialog-content">
						{content?.message}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					{dialogType === "confirm" ? (
						<>
							<Button
								onClick={() => {
									this.close(false);
								}}
							>
								{confirmDialogContent?.declineText || "CANCEL"}
							</Button>
							{confirmDialogContent?.destructiveConfirm ? (
								<Tooltip title={"Hold and release to confirm"}>
									<Button
										className={classes.holdToConfirmButton}
										data-cy="dialog-confirm"
										// onClick={() => {
										// 	this.close(true);
										// }}
										onMouseDown={() => {
											// console.log("mouseDown");
											this.longPress.mouseDownTriggered();
										}}
										onMouseUp={(e: React.MouseEvent<HTMLButtonElement>) => {
											e.stopPropagation();
											// console.log("mouseUp");
											if (this.longPress.mouseUpTriggered(true)) {
												this.close(true);
											}
										}}
										onMouseEnter={(e: React.MouseEvent<HTMLButtonElement>) => {
											// console.log("mouseEnter");
											this.longPress.mouseEnterTriggered();
										}}
										onMouseLeave={() => {
											// console.log("mouseLeave");
											this.longPress.mouseLeaveTriggered();
										}}
										color={"primary"}
										autoFocus
									>
										{confirmDialogContent?.confirmText || "OK"}
									</Button>
								</Tooltip>
							) : (
								<Button
									data-cy="dialog-confirm"
									onClick={() => {
										this.close(true);
									}}
									color={"primary"}
									autoFocus
								>
									{confirmDialogContent?.confirmText || "OK"}
								</Button>
							)}
						</>
					) : (
						<Button
							onClick={() => {
								this.close(true);
							}}
							color="primary"
							autoFocus
						>
							OK
						</Button>
					)}
				</DialogActions>
			</Dialog>
		);
	}
}

export default withStyles(styles, { withTheme: true })(GMDialog);
