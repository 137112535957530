import moment from "moment";
import { AGGREGATION } from "components/monitoring/dashboard/types";

class ChartUtils {
	static getDateRangeFromPeriod(period: string): any[] {
		const unit = period[period.length - 1];
		const amount = period.substring(0, period.length - 1);

		return [
			moment()
				.subtract(amount, unit as any)
				.toDate(),
			moment().toDate()
		];
	}

	static getTimestampRangeFromPeriod(
		period: string
	): { from: number; to: number } {
		const unit = period[period.length - 1];
		const amount = period.substring(0, period.length - 1);

		return {
			from: moment()
				.utc()
				.subtract(amount, unit as any)
				.unix(),
			to: moment().utc().unix()
		};
	}

	static buildPromQLQuery(
		metricName: string,
		period: string,
		aggregation: AGGREGATION,
		clusterName: string,
		hostName?: string
	): string {
		const filter = `{${clusterName && 'gmd_cluster="' + clusterName + '"'}${
			(hostName && ',gmd_host="' + hostName + '"}') || "}"
		}`;

		const duration = `[${period}]`;

		const query = `${metricName}${filter}${duration}`;

		if (aggregation === AGGREGATION.DIFFERENTIAL) return `irate(${query})`;
		else return query;
	}
}

export default ChartUtils;
