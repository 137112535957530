export default class LongPress {
	private mouseDownTimestamp?: number;
	private isMouseDown: boolean = false;
	private mouseUpTimestamp?: number;
	private mouseEnterTimestamp?: number;
	private isMouseOn: boolean = false;
	private mouseLeaveTimestamp?: number;
	private readonly triggerDelay: number;

	private _activeTime: number = 0;

	constructor(triggerDelay: number) {
		this.triggerDelay = triggerDelay;
	}

	public mouseDownTriggered(): void {
		this.isMouseDown = true;
		this.isMouseOn = true;
		this.mouseDownTimestamp = new Date().getTime();

		const upTime = Math.min(
			this.mouseDownTimestamp - (this.mouseUpTimestamp || 0),
			this.mouseDownTimestamp - (this.mouseEnterTimestamp || 0)
		);

		this.activeTime -= upTime;
		// console.log(`down -${upTime} = ${this.activeTime}`);
	}

	public mouseUpTriggered(onTarget: boolean = false): boolean {
		this.isMouseDown = false;
		this.mouseUpTimestamp = new Date().getTime();

		const downTime = Math.min(
			(this.mouseUpTimestamp || 0) - (this.mouseDownTimestamp || 0),
			(this.mouseUpTimestamp || 0) - (this.mouseLeaveTimestamp || 0)
		);

		if (this.isMouseOn) {
			this.activeTime += downTime;
			// console.log(`up +${downTime} = ${this.activeTime}`);
		} else {
			this.activeTime -= downTime;
			// console.log(`up -${downTime} = ${this.activeTime}`);
		}

		// if mouse up event was on target, only then give possibility to return true
		if (onTarget) {
			return this.activeTime >= this.triggerDelay;
		} else {
			return false;
		}
	}

	public mouseEnterTriggered(): void {
		this.isMouseOn = true;
		// if (this.isMouseDown) {
		this.mouseEnterTimestamp = new Date().getTime();

		const offTime = Math.min(
			this.mouseEnterTimestamp - (this.mouseLeaveTimestamp || 0),
			this.mouseEnterTimestamp - (this.mouseUpTimestamp || 0)
		);

		this.activeTime -= offTime;
		// console.log(`enter -${offTime} = ${this.activeTime}`);
	}

	public mouseLeaveTriggered(): void {
		this.isMouseOn = false;
		this.mouseLeaveTimestamp = new Date().getTime();

		if (this.isMouseDown) {
			const onTime = Math.min(
				this.mouseLeaveTimestamp - (this.mouseEnterTimestamp || 0),
				this.mouseLeaveTimestamp - (this.mouseDownTimestamp || 0)
			);

			this.activeTime += onTime;
			// console.log(`leave +${onTime} = ${this.activeTime}`);
		}
	}

	// getters and setters
	get activeTime(): number {
		return this._activeTime;
	}

	set activeTime(value: number) {
		if (value < 0) {
			this._activeTime = 0;
		} else if (value > this.triggerDelay) {
			this._activeTime = this.triggerDelay;
		} else {
			this._activeTime = value;
		}
	}
}
