import { createStyles, InputBase, Theme, withStyles } from "@material-ui/core";

export const ResolutionInput = withStyles((theme: Theme) =>
	createStyles({
		root: {
			"label + &": {
				marginTop: theme.spacing(3)
			}
		},
		input: {
			position: "relative",
			border: "none",
			fontSize: 12,
			padding: "0 23px 0 0"
		}
	})
)(InputBase);
