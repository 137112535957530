import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
	createStyles({
		card: {
			width: "100%"
			// [theme.breakpoints.up("sm")]: {
			// 	width: 700
			// }
			// height: "100%"
		},
		tabs: {
			minHeight: 42
		},
		tab: {
			minHeight: 42
		},
		noPaddingCardContent: {
			padding: 0,
			"&:last-child": {
				padding: 0
			}
		},

		// deployment logs dialog
		logContent: {
			backgroundColor: "black",
			color: "white"
		},
		logsTypography: {
			fontSize: "0.9em",
			fontFamily: "monospace"
		},
		galeraManagerLogMessage: {
			color: theme.palette.primary.main
		},
		hostStderrMessage: {
			color: theme.palette.error.main
		},
		closeButton: {
			position: "absolute",
			right: theme.spacing(2),
			top: theme.spacing(2),
			color: theme.palette.grey[500]
		}
	});
